<template>
  <div class="benthic-page">
    <el-dialog title="水质优劣程度" :visible.sync="flag" width="70%" :before-close="close" :modal="false" top="100px"
      :close-on-click-modal="false">
      <el-dialog v-if="innerVisible" width="80%" title="水质优劣程度" :visible.sync="innerVisible" append-to-body top="2%">
        <IndicatorMap :type="'waterQuality'" @saveWaterQualityObj="saveWaterQualityObj"
          @cancelWaterQualityObj="cancelWaterQualityObj"></IndicatorMap>
      </el-dialog>

      <div slot="title" class="dialog-header-title">
        <span class="title-name">
          水质优劣程度
          <i class="el-icon-question" @click="openTip('szylcd')"></i>
        </span>
        <span class="title-score">
          最终得分:
          <span v-if="finalScore">{{ finalScore.toFixed(1) }}</span>
          <span v-else>--</span>
        </span>
      </div>
      <Standrad :anchor="anchor" :show-flag="indicatorDefinition"
        @changFlag="() => (indicatorDefinition = !indicatorDefinition)" />
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="计算过程" name="first">
          <div class="data-container">
            <div v-for="(domain, index) in data" :key="index">
              <el-row>
                <el-table :summary-method="getAvg" :data="domain.tableData"
                  style="width: 100%; font-size: 12px; text-align: center" header-cell-class-name="table-header-cls"
                  :border="true">
                  <el-table-column prop="site" label="监测点" align="center" header-align="center" fixed width="130">
                  </el-table-column>
                  <el-table-column prop="samplingDate" label="采样日期" align="center" header-align="center" width="180"
                    fixed>
                    <template slot-scope="scope">
                      <div v-if="scope.row.samplingDate == '-'">-</div>
                      <div v-else>
                        <el-date-picker v-model="scope.row.samplingDate" type="date" placeholder="选择日期"
                          format="yyyy-MM-dd" value-format="yyyy-MM-dd">
                        </el-date-picker>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="shuiWen" label="水温（℃）" align="center" header-align="center" width="100"
                    @blur="count(scope.row, scope, domain)">
                    <template slot-scope="scope">
                      <div v-if="scope.row.shuiWen == '-'">-</div>
                      <div v-else>
                        <el-input v-model="scope.row.shuiWen" type="number"></el-input>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="rongJieYang" label="溶解氧(mg/L)" align="center" header-align="center">
                    <template slot-scope="scope">
                      <div class="table-tow-row-container">
                        <div>
                          <el-input v-model="scope.row.rongJieYang" type="number"
                            @blur="count(scope.row, scope, domain)"></el-input>
                        </div>
                        <div>
                          <span v-if="scope.row.DOGrade">{{ scope.row.DOGrade }}</span>
                          <span v-else>--</span>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="gaoMengSuanYan" label="高锰酸盐指数(mg/L)" align="center" header-align="center">
                    <template slot-scope="scope">
                      <div>
                        <el-input v-model="scope.row.gaoMengSuanYan" type="number"
                          @blur="count(scope.row, scope, domain)"></el-input>
                      </div>
                      <div>
                        <span v-if="scope.row.permanganateGrade">{{ scope.row.permanganateGrade }}</span>
                        <span v-else>--</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="huaXueXuYangLiang" label="化学需氧量(mg/L)" align="center" header-align="center">
                    <template slot-scope="scope">
                      <div>
                        <el-input v-model="scope.row.huaXueXuYangLiang" type="number"
                          @blur="count(scope.row, scope, domain)"></el-input>
                      </div>
                      <div>
                        <span v-if="scope.row.CODGrade">{{ scope.row.CODGrade }}</span>
                        <span v-else>--</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="anDan" label="氨氮(mg/L)" align="center" header-align="center">
                    <template slot-scope="scope">
                      <div>
                        <el-input v-model="scope.row.anDan" type="number"
                          @blur="count(scope.row, scope, domain)"></el-input>
                      </div>
                      <div>
                        <span v-if="scope.row.ANGrade">{{ scope.row.ANGrade }}</span>
                        <span v-else>--</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="zongLin" label="总磷(mg/L)" align="center" header-align="center">
                    <template slot-scope="scope">
                      <div>
                        <el-input v-model="scope.row.zongLin" type="number"
                          @blur="count(scope.row, scope, domain)"></el-input>
                      </div>
                      <div>
                        <span v-if="scope.row.totalPGrade">{{ scope.row.totalPGrade }}</span>
                        <span v-else>--</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="zongDan" label="总氮(mg/L)" align="center" header-align="center">
                    <template slot-scope="scope">
                      <div>
                        <el-input v-model="scope.row.zongDan" type="number"
                          @blur="count(scope.row, scope, domain)"></el-input>
                      </div>
                      <div>
                        <span v-if="scope.row.totalNGrade">{{ scope.row.totalNGrade }}</span>
                        <span v-else>--</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="ph" label="PH值" align="center" header-align="center">
                    <template slot-scope="scope">
                      <div>
                        <el-input v-model="scope.row.ph" type="number" @blur="count(scope.row, scope, domain)"></el-input>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="synthesis" label="综合评价类型" align="center" header-align="center"
                    fixed="right"></el-table-column>
                </el-table>
              </el-row>
            </div>
            <el-row type="flex">
              <el-col :span="2" class="row-lable-name">附件:</el-col>
              <el-col :span="16">
                <MyUploadFile ref="uploadfile" :query-ext="queryExt" :attachs="attachs" />
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane label="其他站点" name="other">
          <div class="data-container">
            <el-row>
              <el-table :data="otherTableData" style="width: 100%; font-size: 12px; text-align: center"
                header-cell-class-name="table-header-cls" :border="true">
                <el-table-column prop="site" label="监测点" align="center" header-align="center" fixed width="150">
                  <template slot-scope="scope">
                    <el-select ref="select" v-model="scope.row.siteId" filterable>
                      <el-option v-for="item in szSiteList" :key="item.id" :label="item.name" :value="item.id">
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="samplingDate" label="采样日期" align="center" header-align="center" width="180" fixed>
                  <template slot-scope="scope">
                    <div v-if="scope.row.samplingDate == '-'">-</div>
                    <div v-else>
                      <el-date-picker v-model="scope.row.samplingDate" type="date" placeholder="选择日期" format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd">
                      </el-date-picker>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="shuiWen" label="水温（℃）" align="center" header-align="center" width="100"
                  @blur="count(scope.row, scope, otherTableData)">
                  <template slot-scope="scope">
                    <div v-if="scope.row.shuiWen == '-'">-</div>
                    <div v-else>
                      <el-input v-model="scope.row.shuiWen" type="number"></el-input>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="rongJieYang" label="溶解氧(mg/L)" align="center" header-align="center">
                  <template slot-scope="scope">
                    <div class="table-tow-row-container">
                      <div>
                        <el-input v-model="scope.row.rongJieYang" type="number"
                          @blur="count(scope.row, scope, otherTableData)"></el-input>
                      </div>
                      <div>
                        <span v-if="scope.row.DOGrade">{{ scope.row.DOGrade }}</span>
                        <span v-else>--</span>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="gaoMengSuanYan" label="高锰酸盐指数(mg/L)" align="center" header-align="center">
                  <template slot-scope="scope">
                    <div>
                      <el-input v-model="scope.row.gaoMengSuanYan" type="number"
                        @blur="count(scope.row, scope, otherTableData)"></el-input>
                    </div>
                    <div>
                      <span v-if="scope.row.permanganateGrade">{{ scope.row.permanganateGrade }}</span>
                      <span v-else>--</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="huaXueXuYangLiang" label="化学需氧量(mg/L)" align="center" header-align="center">
                  <template slot-scope="scope">
                    <div>
                      <el-input v-model="scope.row.huaXueXuYangLiang" type="number"
                        @blur="count(scope.row, scope, otherTableData)"></el-input>
                    </div>
                    <div>
                      <span v-if="scope.row.CODGrade">{{ scope.row.CODGrade }}</span>
                      <span v-else>--</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="anDan" label="氨氮(mg/L)" align="center" header-align="center">
                  <template slot-scope="scope">
                    <div>
                      <el-input v-model="scope.row.anDan" type="number"
                        @blur="count(scope.row, scope, otherTableData)"></el-input>
                    </div>
                    <div>
                      <span v-if="scope.row.ANGrade">{{ scope.row.ANGrade }}</span>
                      <span v-else>--</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="zongLin" label="总磷(mg/L)" align="center" header-align="center">
                  <template slot-scope="scope">
                    <div>
                      <el-input v-model="scope.row.zongLin" type="number"
                        @blur="count(scope.row, scope, otherTableData)"></el-input>
                    </div>
                    <div>
                      <span v-if="scope.row.totalPGrade">{{ scope.row.totalPGrade }}</span>
                      <span v-else>--</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="zongDan" label="总氮(mg/L)" align="center" header-align="center">
                  <template slot-scope="scope">
                    <div>
                      <el-input v-model="scope.row.zongDan" type="number"
                        @blur="count(scope.row, scope, otherTableData)"></el-input>
                    </div>
                    <div>
                      <span v-if="scope.row.totalNGrade">{{ scope.row.totalNGrade }}</span>
                      <span v-else>--</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="ph" label="PH值" align="center" header-align="center">
                  <template slot-scope="scope">
                    <div>
                      <el-input v-model="scope.row.ph" type="number"
                        @blur="count(scope.row, scope, otherTableData)"></el-input>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="synthesis" label="综合评价类型" align="center" header-align="center"
                  fixed="right"></el-table-column>
                <el-table-column fixed="right" label="操作" width="90" align="center">
                  <template slot-scope="scope">
                    <el-button type="text" @click.native.prevent="deleteRow(scope.$index, otherTableData)">移除</el-button>
                    <!-- <el-button v-if="id" type="text" style="color: red" @click.native.prevent="deleteDe(scope.row)">
                      删除
                    </el-button> -->
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
          </div>
          <!-- <el-row type="flex">
              <el-col :span="2" class="row-lable-name">附件:</el-col>
              <el-col :span="16">
                <MyUploadFile ref="uploadfile" :query-ext="queryExt" :attachs="attachs" />
              </el-col>
            </el-row> -->
          <div class="add-box add-data" @click="addNewData">
            <span><el-icon class="el-icon-plus" style="margin-right: 8px"></el-icon>添加水质监测点数据</span>
          </div>
          <div class="add-box" @click="addNew">
            <span><el-icon class="el-icon-plus" style="margin-right: 8px"></el-icon>前往地图添加水质监测点</span>
          </div>
        </el-tab-pane>
        <el-tab-pane label="统计结果" name="second">
          <el-row>
            <!-- <el-col>最终得分:{{ finalScore }}</el-col> -->
          </el-row>
          <el-row>
            <el-table :data="totalTaleData" style="width: 100%; font-size: 12px; text-align: center"
              header-cell-class-name="table-header-cls" :border="true">
              <el-table-column prop="mon" label="日期" align="center" header-align="center"> </el-table-column>
              <el-table-column prop="shuiWen" label="水温（℃）" align="center" header-align="center" width="100">
              </el-table-column>
              <el-table-column prop="rongJieYang" label="溶解氧(mg/L)" align="center" header-align="center">
                <template slot-scope="scope">
                  <div class="table-tow-row-container">
                    <div>
                      <span>{{ scope.row.rongJieYang }}</span>
                    </div>
                    <div>
                      <span>{{ scope.row.DOGrade }}</span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="gaoMengSuanYan" label="高锰酸盐指数(mg/L)" align="center" header-align="center">
                <template slot-scope="scope">
                  <div>
                    <span>{{ scope.row.gaoMengSuanYan }}</span>
                  </div>
                  <div>
                    <span>{{ scope.row.permanganateGrade }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="huaXueXuYangLiang" label="化学需氧量(mg/L)" align="center" header-align="center">
                <template slot-scope="scope">
                  <div>
                    <span>{{ scope.row.huaXueXuYangLiang }}</span>
                  </div>
                  <div>
                    <span>{{ scope.row.CODGrade }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="anDan" label="氨氮(mg/L)" align="center" header-align="center">
                <template slot-scope="scope">
                  <div>
                    <span>{{ scope.row.anDan }}</span>
                  </div>
                  <div>
                    <span>{{ scope.row.ANGrade }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="zongLin" label="总磷(mg/L)" align="center" header-align="center">
                <template slot-scope="scope">
                  <div>
                    <span>{{ scope.row.zongLin }}</span>
                  </div>
                  <div>
                    <span>{{ scope.row.totalPGrade }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="zongDan" label="总氮(mg/L)" align="center" header-align="center">
                <template slot-scope="scope">
                  <div>
                    <span>{{ scope.row.zongDan }}</span>
                  </div>
                  <div>
                    <span>{{ scope.row.totalNGrade }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="ph" label="PH值" align="center" header-align="center">
                <template slot-scope="scope">
                  <div>
                    <span>{{ scope.row.ph }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="synthesis" label="综合评价类型" align="center" header-align="center"
                fixed="right"></el-table-column>
            </el-table>
          </el-row>
        </el-tab-pane>
      </el-tabs>

      <el-row> </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button class="btn-info" @click="close">取 消</el-button>
        <el-button class="btn-submit" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { WaterQuality } from '@/api/target/riverTarget.js'
import { Site, Scheme, Rlobject } from '@/api/scheme/index'
import IndicatorMap from '../indicatorMap.vue'
export default {
  // 水质优劣程度
  name: 'WaterQuality',
  components: { IndicatorMap },
  props: {
    schemeId: {
      type: Number,
      default: 1
    },
    targetData: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      flag: true,

      finalScore: null,
      activeName: 'first',
      // 计算过程中表格数据
      // 计算过程单次采样数据（总）
      data: [],

      totalTaleData: [],

      otherTableData: [], // 其他水质监测点数据
      szSiteList: [], // 水质站点数据列表

      siteInfo: [],
      siteList: [],
      len: 0, // 采样次数

      id: null,
      queryExt: {},
      attachs: '',

      indicatorDefinition: false,
      anchor: '',

      innerVisible: false,

      rlType: null,// 河湖类型
    }
  },
  computed: {},
  watch: {
    schemeId: {
      immediate: true,
      handler(val, oldval) {
        this.getType(val)
      }
    },
    targetData: {
      immediate: true,
      handler(val, oldval) {
        if (JSON.stringify(val) !== '{}') {
          this.createData()
          this.loadData(val)
        } else {
          this.createData()
          this.initData()
        }
      }
    }
  },
  created() { },
  mounted() { },
  methods: {
    /** 
    * @description  获取河湖类型
    * @param {*} 
    * 
    */
    getType(val) {
      Scheme.getInfoById(val).then((res) => {
        Rlobject.getInfo(res.data.data.objId).then((res) => {
          this.rlType = res.data.data.rlType
        })
      })
    },
    /**
     * @description 指标描述展示
     * @author hu_ty
     * @since
     * @param {*}
     *
     */
    openTip(val) {
      this.anchor = val
      this.indicatorDefinition = true
    },
    /**
     * @description 数据初始化加载
     * @author hu_ty
     * @since
     * @param {object} data 有数据的指标信息内容
     *
     */
    loadData(data) {
      // console.log(data)
      // debugger
      let oldarr = data.detailList
      let arr = []
      // 1.找出采样的站点信息进行数据分类第一步准备
      let site = []
      oldarr.forEach((item) => {
        site.push(item.siteId)
        const i = this.siteInfo.find((i) => {
          return i.id == item.siteId
        })
      })
      let newSite = [...new Set(site)]
      // 过滤采样站点数据和水质监测点数据
      newSite.forEach((si) => {
        const i = this.siteInfo.findIndex((i) => {
          return si == i.id
        })
        if (i == -1) {
          // arr = oldarr.filter((i) => i.siteId != si)
          newSite = newSite.filter((i) => i != si)
          this.otherTableData = oldarr.filter((i) => i.siteId == si)
        } else {
          let info = oldarr.filter((i) => i.siteId == si)
          arr = arr.concat(info)
        }
      })
      // 2.依照采样站点信息将数据分组
      this.data = []
      newSite.forEach((site) => {
        let tableData = []
        arr.forEach((item) => {
          if (item.siteId === site) {
            tableData.push(item)
          }
        })
        let newArr = []
        this.siteList.forEach((si) => {
          if (si.tableData[0].siteId == site) {
            for (let ii = 0; ii < this.len; ii++) {
              newArr[ii] = Object.assign(si.tableData[ii], tableData[ii])
            }
            newArr[this.len] = si.tableData[this.len]
          }
        })
        let param = {
          tableData: newArr
        }
        this.data.push(param)
      })
      // 3.新增的站点添加数据
      this.siteList.forEach((site) => {
        const i = newSite.findIndex((i) => {
          return i == site.tableData[0].siteId
        })
        if (i == -1) {
          this.data.push(site)
        }
      })
      console.log(this.data)

      this.finalScore = data.scoreVal
      this.attachs = data.attachs
      this.queryExt = data.queryExt
      this.id = data.id
    },
    createData() {
      this.siteInfo = JSON.parse(sessionStorage.siteInfo)
      this.siteList = []
      this.siteInfo.forEach((item) => {
        this.len = item.szylTimes
        let arr = []
        for (let i = 1; i <= this.len; i++) {
          let timeParam = {
            site: item.name + '第' + i + '次采样',
            siteId: item.id,
            anDan: NaN,
            ANGrade: null,
            huaXueXuYangLiang: NaN,
            CODGrade: null,
            rongJieYang: NaN,
            DOGrade: null,
            gaoMengSuanYan: NaN,
            permanganateGrade: null,
            zongDan: NaN,
            totalNGrade: null,
            zongLin: NaN,
            totalPGrade: null,
            synthesis: null,
            ph: null
          }
          arr.push(timeParam)
        }
        arr.push({
          site: '平均值',
          samplingDate: '-',
          shuiWen: '-',
          anDan: NaN,
          ANGrade: null,
          huaXueXuYangLiang: NaN,
          CODGrade: null,
          rongJieYang: NaN,
          DOGrade: null,
          gaoMengSuanYan: NaN,
          permanganateGrade: null,
          zongDan: NaN,
          totalNGrade: null,
          zongLin: NaN,
          totalPGrade: null,
          synthesis: null,
          ph: null
        })
        let param = {
          tableData: JSON.parse(JSON.stringify(arr))
        }
        this.siteList.push(param)
      })
    },
    initData() {
      this.data = JSON.parse(JSON.stringify(this.siteList))
    },
    /**
     * @description  关闭组件并改变父级 targetFlag 值
     * @author hu_ty
     * @since 22-06-24
     * @param {*}
     *
     */
    close() {
      this.$emit('changeFlag')
    },
    /**
     * @description 表格数据内容依据GB3838 评定水质类别
     * @author hu_ty
     * @since
     * @param {object} data 所在行数据（单次采样化验数据）
     *
     */
    countLine(data) {
      if (data.anDan && data.anDan != 'NaN') {
        let an = parseFloat(data.anDan)
        let grade
        switch (true) {
          case an <= 0.15:
            grade = 'I'
            break
          case an <= 0.5:
            grade = 'Ⅱ'
            break
          case an <= 1.0:
            grade = 'Ⅲ'
            break
          case an <= 1.5:
            grade = 'Ⅳ'
            break
          case an <= 2.0:
            grade = 'Ⅴ'
            break
          default:
            grade = '劣Ⅴ'
        }
        data.ANGrade = grade
      }
      if (data.huaXueXuYangLiang && data.huaXueXuYangLiang != 'NaN') {
        let huaXueXuYangLiang = parseFloat(data.huaXueXuYangLiang)
        let grade
        switch (true) {
          case huaXueXuYangLiang <= 15:
            grade = 'Ⅱ'
            break
          case huaXueXuYangLiang <= 20:
            grade = 'Ⅲ'
            break
          case huaXueXuYangLiang <= 30:
            grade = 'Ⅳ'
            break
          case huaXueXuYangLiang <= 40:
            grade = 'Ⅴ'
            break
          default:
            grade = '劣Ⅴ'
        }
        data.CODGrade = grade
      }
      if (data.rongJieYang && data.rongJieYang != 'NaN') {
        let rongJieYang = parseFloat(data.rongJieYang)
        let grade
        switch (true) {
          case rongJieYang >= 7.5:
            grade = 'Ⅰ'
            break
          case rongJieYang >= 6:
            grade = 'Ⅱ'
            break
          case rongJieYang >= 5:
            grade = 'Ⅲ'
            break
          case rongJieYang >= 3:
            grade = 'Ⅳ'
            break
          case rongJieYang >= 2:
            grade = 'Ⅴ'
            break
          default:
            grade = '劣Ⅴ'
        }
        data.DOGrade = grade
      }
      if (data.gaoMengSuanYan && data.gaoMengSuanYan != 'NaN') {
        let gaoMengSuanYan = parseFloat(data.gaoMengSuanYan)
        let grade
        console.log('===', this.targetData);
        switch (true) {
          case gaoMengSuanYan <= 2:
            grade = 'Ⅰ'
            break
          case gaoMengSuanYan <= 4:
            grade = 'Ⅱ'
            break
          case gaoMengSuanYan <= 6:
            grade = 'Ⅲ'
            break
          case gaoMengSuanYan <= 10:
            grade = 'Ⅳ'
            break
          case gaoMengSuanYan <= 15:
            grade = 'Ⅴ'
            break
          default:
            grade = '劣Ⅴ'
        }
        data.permanganateGrade = grade
      }
      if (data.zongLin && data.zongLin != 'NaN') {
        let zongLin = parseFloat(data.zongLin)
        let grade
        if (this.rlType === 1) {
          switch (true) {
            case zongLin <= 0.02:
              grade = 'Ⅰ'
              break
            case zongLin <= 0.1:
              grade = 'Ⅱ'
              break
            case zongLin <= 0.2:
              grade = 'Ⅲ'
              break
            case zongLin <= 0.3:
              grade = 'Ⅳ'
              break
            case zongLin <= 0.4:
              grade = 'Ⅴ'
              break
            default:
              grade = '劣Ⅴ'
          }
        } else {
          switch (true) {
            case zongLin <= 0.01:
              grade = 'Ⅰ'
              break
            case zongLin <= 0.025:
              grade = 'Ⅱ'
              break
            case zongLin <= 0.05:
              grade = 'Ⅲ'
              break
            case zongLin <= 0.1:
              grade = 'Ⅳ'
              break
            case zongLin <= 0.2:
              grade = 'Ⅴ'
              break
            default:
              grade = '劣Ⅴ'
          }
        }

        data.totalPGrade = grade
      }
      if (data.zongDan && data.zongDan != 'NaN') {
        let zongDan = parseFloat(data.zongDan)
        let grade
        switch (true) {
          case zongDan <= 0.15:
            grade = 'Ⅰ'
            break
          case zongDan <= 0.5:
            grade = 'Ⅱ'
            break
          case zongDan <= 1:
            grade = 'Ⅲ'
            break
          case zongDan <= 1.5:
            grade = 'Ⅳ'
            break
          case zongDan <= 2:
            grade = 'Ⅴ'
            break
          default:
            grade = '劣Ⅴ'
        }
        data.totalNGrade = grade
      }
      let gradelist = [
        data.ANGrade,
        data.CODGrade,
        data.DOGrade,
        data.permanganateGrade,
        data.totalPGrade,
        data.totalNGrade
      ]
      if (gradelist.includes('劣Ⅴ')) {
        data.synthesis = '劣Ⅴ'
      } else if (gradelist.includes('Ⅴ')) {
        data.synthesis = 'Ⅴ'
      } else if (gradelist.includes('Ⅳ')) {
        data.synthesis = 'Ⅳ'
      } else if (gradelist.includes('Ⅲ')) {
        data.synthesis = 'Ⅲ'
      } else if (gradelist.includes('Ⅱ')) {
        data.synthesis = 'Ⅱ'
      } else if (gradelist.includes('Ⅰ')) {
        data.synthesis = 'Ⅰ'
      } else {
        data.synthesis = null
      }

      return data
    },
    count(data, scope, domain) {
      data = this.countLine(data)
      const newline = this.getAvg(scope.store.table)
      if (domain.tableData) {
        const i = domain.tableData.findIndex((i) => i.site === '平均值')
        domain.tableData.splice(i, 1)
        domain.tableData.push(newline)
      }
    },
    /**
     * @description  水质采样平均值计算
     * @author hu_ty
     * @since
     * @param {*} param 表格对象
     *
     */
    getAvg(param) {
      const { columns, data } = param
      let newArray = JSON.parse(JSON.stringify(data))
      newArray = newArray.slice(0, newArray.length - 1)
      const sums = []
      let newLine = {}
      columns.forEach((column, index) => {
        if (index === 0) {
          newLine[column.property] = '平均值'
          return
        }
        if (index === 1) {
          newLine[column.property] = '-'
          return
        }
        if (index === 2) {
          newLine[column.property] = '-'
          return
        }
        if (column.property === '综合评价类型') {
          return
        }
        const values = newArray.map((item) => Number(item[column.property]))
        if (!values.every((value) => isNaN(value))) {
          let value
          let Dlength = 0
          sums[index] = values.reduce((prev, curr) => {
            value = Number(curr)
            if (!isNaN(value)) {
              if (curr != 0) {
                Dlength += 1
              }

              return prev + curr
            } else {
              return prev
            }
          }, 0)
          if (Dlength > 0) {
            newLine[column.property] = (sums[index] / Dlength).toFixed(2)
          } else {
            newLine[column.property] = NaN
          }
        } else {
          newLine[column.property] = null
        }
      })
      return this.countLine(newLine)
    },
    /**
     * @description tab 页签切换功能和回调函数
     * @author hu_ty
     * @since
     * @param {*}
     *
     */
    handleClick(tab, event) {
      if (tab.label === '统计结果') {
        this.alculateResults()
      } else if (tab.name === 'other') {
        this.getSiteList(this.schemeId)
      }
    },
    /**
     * @description  统计相同月份，年度平均结果
     * @author hu_ty
     * @since 22-06-30
     * @param {*}
     *
     */
    alculateResults() {
      let arr = []
      let monthList = [] // 采样月份列表（年月）
      this.data.forEach((item) => {
        item.tableData.forEach((da) => {
          if (da.samplingDate && da.samplingDate !== '-') {
            arr.push(da)
            monthList.push({ month: da.samplingDate.split('-')[0] + '-' + da.samplingDate.split('-')[1] })
          }
        })
      })
      // debugger
      // 去除 monthList 中的重复对象
      var hash = {}
      monthList = monthList.reduce(function (item, next) {
        hash[next.month] ? '' : (hash[next.month] = true && item.push(next))
        return item
      }, [])

      // 对应采样月份中插入相应的采样化验数据
      monthList.forEach((item) => {
        item.data = []
        arr.forEach((data) => {
          if (data.samplingDate.includes(item.month)) {
            item.data.push(data)
          }
        })
      })

      // 获取水质采样属性列表
      let propertyName = []
      monthList.forEach((item) => {
        item.data.forEach((da) => {
          for (let key in da) {
            propertyName.push(key)
          }
        })
      })

      // 计算同月份的平均数据
      let array = []
      monthList.forEach((data) => {
        let newdata = {}
        newdata.mon = data.month
        propertyName.forEach((pro) => {
          let i = 0
          let len = 0
          data.data.forEach((sim) => {
            if (sim[pro] != 'NaN') {
              i += parseFloat(sim[pro])
              len += 1
            }
          })
          newdata[pro] = (i / len).toFixed(2)
        })
        array.push(newdata)
      })

      // 计算年平均数据
      let totalLine = {}
      propertyName.forEach((pro) => {
        totalLine.mon = '年均'
        let i = 0
        let len = 0
        array.forEach((data) => {
          if (data[pro] != 'NaN') {
            i += parseFloat(data[pro])
            len += 1
          }
        })
        totalLine[pro] = (i / len).toFixed(2)
      })
      array.push(totalLine)

      // 计算各指标的评定类别并返回table 数据
      this.totalTaleData = []
      array.forEach((tol) => {
        const data = this.countLine(tol)
        this.totalTaleData.push(data)
      })
    },
    /**
     * @description  数据提交
     * @author hu_ty
     * @since
     * @param {*}
     *
     */
    submit() {
      this.attachs = this.$refs.uploadfile.getAttachs()
      let arr = []
      this.data.forEach((item) => {
        item.tableData.forEach((i) => {
          if (i.site != '平均值') return arr.push(i)
        })
      })
      if (this.otherTableData.length > 0) {
        this.otherTableData.forEach((siteInfo) => {
          arr.push(siteInfo)
        })
      }

      let param = {
        id: this.id,
        attachs: this.attachs,
        schemeId: this.schemeId,
        detailList: arr
      }
      WaterQuality.saveInfo(param).then((res) => {
        this.close()
      })
    },

    //==========add waterQuality
    addNew() {
      this.innerVisible = true
    },
    //todo
    saveWaterQualityObj(data) {
      //todo 已获取地图添加的点数据  后续处理：
      //...
      let param = data
      param.schemeId = this.schemeId
      Site.szSiteSave(param).then((res) => {
        this.innerVisible = false

        this.getSiteList(this.schemeId)
      })
    },
    cancelWaterQualityObj() {
      this.innerVisible = false
    },
    /**
     * @description  获取对象站点信息
     * @author hu_ty
     * @since
     * @param {number} val 对象id
     *
     */
    getSiteList(val) {
      let param = {
        schemeId: val,
        pageSize: 100
      }
      Site.getList(param).then((res) => {
        let siteInfo = res.data.data.list
        this.szSiteList = siteInfo.filter((site) => site.siteType === 'WaterQuality')
      })
    },

    /**
     * @description 水质监测站点数据动态新增
     * @author hu_ty
     * @since
     * @param {*}
     *
     */
    addNewData() {
      let newLine = {
        // siteId: item.id,
        site: null,
        anDan: NaN,
        ANGrade: null,
        huaXueXuYangLiang: NaN,
        CODGrade: null,
        rongJieYang: NaN,
        DOGrade: null,
        gaoMengSuanYan: NaN,
        permanganateGrade: null,
        zongDan: NaN,
        totalNGrade: null,
        zongLin: NaN,
        totalPGrade: null,
        synthesis: null,
        ph: null,
        key: Date.now()
      }
      this.otherTableData.push(newLine) // 移到第一行
    },
    /**
     * @description  删除行
     * @author hu_ty
     * @since
     * @param {*}
     *
     */
    deleteRow(index, rows) {
      if (!rows[index].id) {
        rows.splice(index, 1)
      }
      // rows.splice(index, 1)
    }
  }
}
</script>
<style lang="less" scoped>
.benthic-page {
  .el-row {
    margin: 16px;
  }
}

.data-container {
  max-height: 600px;
  overflow: auto;
  color: rgb(0, 0, 0) 80%;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

.table-tow-row-container {
  display: flex;
  flex-direction: column;

  div {
    flex: 1;
  }
}

/deep/ input {
  border: 0;
  color: rgb(0, 0, 0) 80%;
  text-align: center;
}

.add-data {
  margin-bottom: 8px;
}
</style>
