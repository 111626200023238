<template>
  <div>
    <el-dialog
      title="岸坡稳定性"
      :visible.sync="flag"
      width="568px"
      top="100px"
      :before-close="close"
      :modal="false"
      :close-on-click-modal="false"
    >
      <el-dialog
        v-if="innerVisible"
        width="80%"
        title="岸坡稳定性"
        :visible.sync="innerVisible"
        append-to-body
        top="2%"
      >
        <IndicatorMap :type="'AnPoWenDingXing'" @saveAddObj="saveAddObj" @cancelAddObj="cancelAddObj"></IndicatorMap>
      </el-dialog>

      <div slot="title" class="dialog-header-title">
        <span class="title-name">
          岸坡稳定性
          <i class="el-icon-question" @click="openTip('apwdx')"></i>
        </span>
        <span class="title-score">
          最终得分:
          <span v-if="finalScore">{{ finalScore.toFixed(1) }}</span>
          <span v-else>--</span>
        </span>
      </div>
      <Standrad
        :anchor="anchor"
        :show-flag="indicatorDefinition"
        @changFlag="() => (indicatorDefinition = !indicatorDefinition)"
      />

      <div v-for="(data, index) in detailList" v-cloak :key="index" style="margin-bottom: 10px">
        <div class="container">
          <!-- 河段信息： -->
          <div class="item-content">
            <el-row>
              <el-col class="row-lable-name" :span="8">河段信息:</el-col>
              <el-col :span="16">
                <el-col :span="4">起始点:</el-col>
                <el-col :span="12">
                  <el-input v-model="data.desDesc" type="text"></el-input>

                  <!-- {{ data.desDesc }} -->
                </el-col>
              </el-col>
              <el-col :span="16" :offset="8">
                <el-col :span="4">结束点:</el-col>
                <el-col :span="12">
                  <el-input v-model="data.startDesc" type="text"></el-input>

                  <!-- {{ data.startDesc }} -->
                </el-col>
              </el-col>
            </el-row>
          </div>

          <!-- 岸坡类型 -->
          <div v-if="data.anPoType" class="item-content">
            <el-row>
              <el-col class="row-lable-name" :span="8">岸坡类型:</el-col>
              <el-col :span="10">
                <el-input v-model="data.anPoType" type="text"></el-input>
                <!-- {{ data.anPoType }} -->
              </el-col>
              <el-col :span="10" :offset="8">
                <div v-if="data.anPoPngCodeList && data.anPoPngCodeList.length > 0" class="png">
                  <el-image
                    style="width: 100%"
                    fit="scale-down"
                    :src="data.anPoPngCodeList[0]"
                    :preview-src-list="data.anPoPngCodeList"
                  >
                  </el-image>
                </div>
              </el-col>
            </el-row>
            <el-row v-if="data.anPoDesc">
              <el-col class="row-lable-name" :span="8">描述:</el-col>
              <el-col :span="10">
                <el-input v-model="data.anPoDesc" type="textarea" :rows="2"> </el-input>
              </el-col>
            </el-row>
          </div>

          <!-- 岸坡周边 -->
          <div v-if="data.anPoZhouBianType" class="item-content">
            <el-row>
              <el-col class="row-lable-name" :span="8">岸坡周边:</el-col>
              <el-col :span="10">
                <el-input v-model="data.anPoZhouBianType" type="text"></el-input>
                <!-- {{ data.anPoZhouBianType }} -->
              </el-col>
              <el-col :span="10" :offset="8">
                <div v-if="data.anPoZhouBianPngCodeList && data.anPoZhouBianPngCodeList.length > 0" class="png">
                  <el-image
                    style="width: 100%"
                    fit="scale-down"
                    :src="data.anPoZhouBianPngCodeList[0]"
                    :preview-src-list="data.anPoZhouBianPngCodeList"
                  >
                  </el-image>
                </div>
              </el-col>
            </el-row>
            <el-row v-if="data.anPoZhouBianDesc">
              <el-col class="row-lable-name" :span="8">描述:</el-col>
              <el-col :span="10">
                <el-input v-model="data.anPoZhouBianDesc" type="textarea" :rows="2"> </el-input>
              </el-col>
            </el-row>
          </div>

          <!-- 稳定性 -->
          <div v-if="data.wenDingXingFlag !== null && data.wenDingXingFlag !== undefined" class="item-content">
            <el-row>
              <el-col class="row-lable-name" :span="8">稳定性:</el-col>
              <el-col :span="10"> {{ data.wenDingXingFlag ? '稳定' : '不稳定' }}</el-col>
              <el-col :span="10" :offset="8">
                <div v-if="data.wenDingXingPngCodeList && data.wenDingXingPngCodeList.length > 0" class="png">
                  <el-image
                    style="width: 100%"
                    fit="scale-down"
                    :src="data.wenDingXingPngCodeList[0]"
                    :preview-src-list="data.wenDingXingPngCodeList"
                  >
                  </el-image>
                </div>
              </el-col>
            </el-row>
            <el-row v-if="data.wenDingXingDesc">
              <el-col class="row-lable-name" :span="8">描述:</el-col>
              <el-col :span="10">
                <el-input v-model="data.wenDingXingDesc" type="textarea" :rows="2"> </el-input>
              </el-col>
            </el-row>
          </div>

          <!-- 岸坡水土流失 -->
          <div v-if="data.anPoShuiTuLiuShiLevel" class="item-content">
            <el-row>
              <el-col class="row-lable-name" :span="8">岸坡水土流失:</el-col>
              <el-col :span="10">
                <el-select ref="select" v-model="data.anPoShuiTuLiuShiLevel">
                  <el-option v-for="item in shuitiOptions" :key="item.name" :label="item.name" :value="item.scoreStr">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="10" :offset="8">
                <div v-if="data.anPoShuiTuLiuShiPngCodeList && data.anPoShuiTuLiuShiPngCodeList.length > 0" class="png">
                  <el-image
                    style="width: 100%"
                    fit="scale-down"
                    :src="data.anPoShuiTuLiuShiPngCodeList[0]"
                    :preview-src-list="data.anPoShuiTuLiuShiPngCodeList"
                  >
                  </el-image>
                </div>
              </el-col>
            </el-row>
            <el-row v-if="data.anPoShuiTuLiuShiDesc">
              <el-col class="row-lable-name" :span="8">描述:</el-col>
              <el-col :span="10">
                <el-input v-model="data.anPoShuiTuLiuShiDesc" type="textarea" :rows="2"> </el-input>
              </el-col>
            </el-row>
            <el-row v-if="data.anPoShuiTuLiuShiLevel">
              <el-col class="row-lable-name" :span="8">扣分值:</el-col>
              <el-col :span="10">{{ getIndicatorScore(data.anPoShuiTuLiuShiLevel) }}</el-col>
            </el-row>
            <el-row v-if="data.anPoShuiTuLiuShiLevel">
              <el-col class="row-lable-name" :span="8">参与此次评价:</el-col>
              <el-col :span="10">
                <div>
                  <el-radio v-model="data.anPoShuiTuLiuShiJoinFlag" :label="true">是</el-radio>
                  <el-radio v-model="data.anPoShuiTuLiuShiJoinFlag" :label="false">否</el-radio>
                </div>
              </el-col>
            </el-row>
          </div>

          <!-- 滨岸冲刷、侵蚀 -->
          <div v-if="data.binAnChongShuaLevel" class="item-content">
            <el-row>
              <el-col class="row-lable-name" :span="8">滨岸冲刷、侵蚀:</el-col>
              <el-col :span="10">
                <el-select ref="select" v-model="data.binAnChongShuaLevel">
                  <el-option
                    v-for="item in chongshuaOptions"
                    :key="item.name"
                    :label="item.name"
                    :value="item.scoreStr"
                  >
                  </el-option> </el-select
              ></el-col>
              <el-col :span="10" :offset="8">
                <div v-if="data.binAnChongShuaPngCodeList && data.binAnChongShuaPngCodeList.length > 0" class="png">
                  <el-image
                    style="width: 100%"
                    fit="scale-down"
                    :src="data.binAnChongShuaPngCodeList[0]"
                    :preview-src-list="data.binAnChongShuaPngCodeList"
                  >
                  </el-image>
                </div>
              </el-col>
            </el-row>
            <el-row v-if="data.binAnChongShuaDesc">
              <el-col class="row-lable-name" :span="8">描述:</el-col>
              <el-col :span="10">
                <el-input v-model="data.binAnChongShuaDesc" type="textarea" :rows="2"> </el-input>
              </el-col>
            </el-row>
            <el-row v-if="data.binAnChongShuaLevel">
              <el-col class="row-lable-name" :span="8">扣分值:</el-col>
              <el-col :span="10">{{ getIndicatorScore(data.binAnChongShuaLevel) }}</el-col>
            </el-row>
            <el-row v-if="data.binAnChongShuaLevel">
              <el-col class="row-lable-name" :span="8">参与此次评价:</el-col>
              <el-col :span="10">
                <div>
                  <el-radio v-model="data.binAnChongShuaJoinFlag" :label="true">是</el-radio>
                  <el-radio v-model="data.binAnChongShuaJoinFlag" :label="false">否</el-radio>
                </div>
              </el-col>
            </el-row>
          </div>

          <!-- 塌岸 -->
          <div v-if="data.anTaLevel" class="item-content">
            <el-row>
              <el-col class="row-lable-name" :span="8">塌岸:</el-col>
              <el-col :span="10">
                <!-- {{ getIndicatorName(data.anTaLevel) }} -->
                <el-select ref="select" v-model="data.anTaLevel">
                  <el-option v-for="item in taanOptions" :key="item.name" :label="item.name" :value="item.scoreStr">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="10" :offset="8">
                <div v-if="data.anTaPngCodeList && data.anTaPngCodeList.length > 0" class="png">
                  <el-image
                    style="width: 100%"
                    fit="scale-down"
                    :src="data.anTaPngCodeList[0]"
                    :preview-src-list="data.anTaPngCodeList"
                  >
                  </el-image>
                </div>
              </el-col>
            </el-row>
            <el-row v-if="data.anTaDesc">
              <el-col class="row-lable-name" :span="8">描述:</el-col>
              <el-col :span="10">
                <el-input v-model="data.anTaDesc" type="textarea" :rows="2"> </el-input>
              </el-col>
            </el-row>
            <el-row v-if="data.anTaLevel">
              <el-col class="row-lable-name" :span="8">扣分值:</el-col>
              <el-col :span="10">{{ getIndicatorScore(data.anTaLevel) }}</el-col>
            </el-row>
            <el-row v-if="data.anTaLevel">
              <el-col class="row-lable-name" :span="8">参与此次评价:</el-col>
              <el-col :span="10">
                <div>
                  <el-radio v-model="data.anTaJoinFlag" :label="true">是</el-radio>
                  <el-radio v-model="data.anTaJoinFlag" :label="false">否</el-radio>
                </div>
              </el-col>
            </el-row>
          </div>

          <!-- 预评价 -->
          <div v-if="data.preEvaluate" class="item-content">
            <el-row>
              <el-col class="row-lable-name" :span="8">预评价:</el-col>
              <el-col :span="10">{{ data.preEvaluate }}</el-col>
              <el-col :span="10" :offset="8">
                <div v-if="data.apreEvaluatePngCodeList && data.apreEvaluatePngCodeList.length > 0" class="png">
                  <el-image
                    style="width: 100%"
                    fit="scale-down"
                    :src="data.apreEvaluatePngCodeList[0]"
                    :preview-src-list="data.apreEvaluatePngCodeList"
                  >
                  </el-image>
                </div>
              </el-col>
            </el-row>
            <el-row v-if="data.preEvaluateDesc">
              <el-col class="row-lable-name" :span="8">描述:</el-col>
              <el-col :span="10">
                <el-input v-model="data.preEvaluateDesc" type="textarea" :rows="2"> </el-input>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>

      <el-row>
        <div class="add-box" @click="addNew">
          <span><el-icon class="el-icon-plus" style="margin-right: 8px"></el-icon>添加新数据</span>
        </div>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button class="btn-info" @click="close">取 消</el-button>
        <el-button class="btn-submit" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { AnPoWenDingXing } from '@/api/target/riverTarget.js'
  import { File } from '@/api/file/index.js'
  import IndicatorMap from '../indicatorMap.vue'
  import { getIndicator } from '../indicatorScore'

  export default {
    // 岸坡稳定性
    name: 'Landscape',
    components: { IndicatorMap },
    props: {
      schemeId: {
        type: Number,
        default: 1
      },
      targetData: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        innerVisible: false,

        flag: true,
        detailList: [],
        finalScore: null,
        id: null,

        indicatorDefinition: false,
        anchor: '',
        shuitiOptions: [
          {
            name: '轻度',
            scoreStr: 'shuiTuLiuShi_small',
            score: -1
          },
          {
            name: '中等',
            scoreStr: 'shuiTuLiuShi_middle',
            score: -3
          },
          {
            name: '严重',
            scoreStr: 'shuiTuLiuShi_weight',
            score: -5
          }
        ],
        chongshuaOptions: [
          {
            name: '轻度',
            scoreStr: 'chongShuaQinShi_small',
            score: -1
          },
          {
            name: '中等',
            scoreStr: 'chongShuaQinShi_middle',
            score: -3
          },
          {
            name: '严重',
            scoreStr: 'chongShuaQinShi_weight',
            score: -5
          }
        ],
        taanOptions: [
          {
            name: '轻度',
            scoreStr: 'taAn_small',
            score: -5
          },
          {
            name: '中等',
            scoreStr: 'taAn_middle',
            score: -10
          },
          {
            name: '严重',
            scoreStr: 'taAn_weight',
            score: -20
          }
        ]
      }
    },
    computed: {},
    watch: {
      targetData: {
        immediate: true,
        handler(val, oldval) {
          if (JSON.stringify(val) !== '{}') {
            this.loadData(val)
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      getIndicatorName(str) {
        const res = getIndicator(str)
        return res.name
      },
      getIndicatorScore(str) {
        const res = getIndicator(str)
        return res.score
      },
      /**
       * @description 指标描述展示
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      openTip(val) {
        this.anchor = val
        this.indicatorDefinition = true
      },
      // /数据初始化加载
      loadData(data) {
        console.log('loadData', data)
        this.formmater(data.detailList)
        this.finalScore = data.scoreVal
        this.id = data.id
      },
      // 获取图片列表
      async formmater(data) {
        this.detailList = []
        data.forEach(async (item) => {
          // 岸坡类型照片
          if (item.anPoPngCode && !item.anPoPngCode.includes('tmp')) {
            item.anPoPngCodeList = []
            let arr = item.anPoPngCode.split(',')
            let promise = []
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.anPoPngCodeList.push(res.data.data)
              })
              promise.push(a)
            })
            await Promise.all(promise)
          }
          // 岸坡周边照片
          if (item.anPoZhouBianPngCode && !item.anPoZhouBianPngCode.includes('tmp')) {
            item.anPoZhouBianPngCodeList = []
            let arr = item.anPoZhouBianPngCode.split(',')
            let promise = []
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.anPoZhouBianPngCodeList.push(res.data.data)
              })
              promise.push(a)
            })
            await Promise.all(promise)
          }
          // 岸坡稳定性照片
          if (item.wenDingXingPngCode && !item.wenDingXingPngCode.includes('tmp')) {
            item.wenDingXingPngCodeList = []
            let arr = item.wenDingXingPngCode.split(',')
            let promise = []
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.wenDingXingPngCodeList.push(res.data.data)
              })
              promise.push(a)
            })
            await Promise.all(promise)
          }
          // 岸坡水土流失
          if (item.anPoShuiTuLiuShiPngCode && !item.anPoShuiTuLiuShiPngCode.includes('tmp')) {
            item.anPoShuiTuLiuShiPngCodeList = []
            let arr = item.anPoShuiTuLiuShiPngCode.split(',')
            let promise = []
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.anPoShuiTuLiuShiPngCodeList.push(res.data.data)
              })
              promise.push(a)
            })
            await Promise.all(promise)
          }
          // 滨岸
          if (item.binAnChongShuaPngCode && !item.binAnChongShuaPngCode.includes('tmp')) {
            item.binAnChongShuaPngCodeList = []
            let arr = item.binAnChongShuaPngCode.split(',')
            let promise = []
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.binAnChongShuaPngCodeList.push(res.data.data)
              })
              promise.push(a)
            })
            await Promise.all(promise)
          }
          // 岸塌
          if (item.anTaPngCode && !item.anTaPngCode.includes('tmp')) {
            item.anTaPngCodeList = []
            let arr = item.anTaPngCode.split(',')
            let promise = []
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.anTaPngCodeList.push(res.data.data)
              })
              promise.push(a)
            })
            await Promise.all(promise)
          }
          // 预评价
          if (item.preEvaluatePngCode && !item.preEvaluatePngCode.includes('tmp')) {
            item.apreEvaluatePngCodeList = []
            let arr = item.preEvaluatePngCode.split(',')
            let promise = []
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.apreEvaluatePngCodeList.push(res.data.data)
              })
              promise.push(a)
            })
            await Promise.all(promise)
          }
          this.detailList.push(item)
        })
      },
      //关闭组件并改变父级 targetFlag 值
      close() {
        this.$emit('changeFlag')
      },

      addNew() {
        this.innerVisible = true
      },
      // 保存监测点
      saveAddObj(data) {
        console.log('data', data)
        let param = {
          schemeId: this.schemeId,
          detailList: [data]
        }

        AnPoWenDingXing.saveInfo(param).then((res) => {
          AnPoWenDingXing.find(res.data.data).then((res) => {
            this.loadData(res.data.data)
          })
        })

        this.innerVisible = false
      },
      // 取消保存监测点
      cancelAddObj() {
        this.innerVisible = false
      },
      //数据保存
      submit() {
        console.log('submit', this.detailList)
        let param = {
          id: this.id,
          detailList: this.detailList
        }
        AnPoWenDingXing.saveInfo(param).then((res) => {
          this.close()
          this.$emit('clearPopup')
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .container {
    // height: 500px;

    display: flex;
    flex-direction: column;
    // align-items: center;

    // max-height: 500px;
    overflow-y: scroll;
    border-bottom: 1px solid rgba(186, 186, 186, 0.821);
  }

  .item-content {
    border-bottom: 0.5px solid rgba(186, 186, 186, 0.221);
    padding: 10px 0;
  }

  .row-lable-name {
    font-weight: 600;
    font-size: 14px;
  }

  .el-row {
    margin-bottom: 12px;
    line-height: 22px;
  }

  ::v-deep .el-dialog__body {
    height: 700px;
    overflow: auto;
  }

  .png {
    width: 128px;
    height: 128px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  // /deep/.el-input__inner {
  //   border: 0;
  //   // border-bottom-color: #000;
  //   // border-bottom-width: 1px;
  //   // border-bottom-style: solid;
  //   border-radius: 0;
  // }
</style>
