<template>
  <div>
    <el-dialog title="生态岸坡比例" :visible.sync="flag" width="50%" :before-close="close" :modal="false"
      :close-on-click-modal="false">
      <el-dialog v-if="innerVisible" width="80%" title="生态岸坡比例" :visible.sync="innerVisible" append-to-body top="2%">
        <IndicatorMap :ecological-bank="EcologicalBank" :type="'ecologicalBank'"
          @saveEcologicalBank="saveEcologicalBank" @cancelEcologicalBank="cancelEcologicalBank"></IndicatorMap>
      </el-dialog>

      <div slot="title" class="dialog-header-title">
        <span class="title-name">
          生态岸坡比例
          <i class="el-icon-question" @click="openTip('stapbl')"></i>
        </span>
        <span class="title-score">
          最终得分:
          <span v-if="finalScore">{{ finalScore.toFixed(1) }}</span>
          <span v-else>--</span>
        </span>
      </div>
      <Standrad :anchor="anchor" :show-flag="indicatorDefinition"
        @changFlag="() => (indicatorDefinition = !indicatorDefinition)" />

      <el-table :data="ecoBankTableData" style="width: 100%; font-size: 12px; text-align: center"
        header-cell-class-name="table-header-cls">
        <el-table-column prop="name" label="岸线名称" align="center" header-align="center" width="90">
          <template slot-scope="scope">
            <el-input v-model="scope.row.name" type="text"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="estimatedLength" label="预计长度(m)" align="center" header-align="center" width="120">
          <template slot-scope="scope">
            <el-input v-model="scope.row.estimatedLength" disabled type="text" style="width: 70%"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="length" label="岸线长度(m)" align="center" header-align="center" width="120">
          <template slot-scope="scope">
            <el-input v-model.number="scope.row.length" type="number"></el-input>
          </template>
        </el-table-column>

        <el-table-column label="经纬度" align="center" header-align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.lon }} , {{ scope.row.lat }}</div>
          </template>
        </el-table-column>

        <el-table-column prop="anPoDesc" label="现场描述" align="center" header-align="center"> </el-table-column>

        <el-table-column prop="pics" label="现场图片" align="center" header-align="center" width="120">
          <template slot-scope="scope">
            <div v-if="scope.row.urlList && scope.row.urlList.length > 0">
              <el-image style="width: 100px; height: 100px" :src="scope.row.urlList[0]"
                :preview-src-list="scope.row.urlList">
              </el-image>
            </div>
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="90" align="center">
          <template slot-scope="scope">
              <el-button v-if="!scope.row.id" type="text" @click.native.prevent="deleteRow(scope.$index, ecoBankTableData)"
                  >移除</el-button
                >
                <el-button
                  v-if="scope.row.id"
                  type="text"
                  style="color: red"
                  @click.native.prevent="trueDeleteRow(scope.row, detailList)"
                  >删除</el-button
                >

            <el-button type="text" @click.native.prevent="draw(scope.row)">绘制</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="add-box" style="margin-top:20px" @click="addNew">
        <span>
          <el-icon class="el-icon-plus" style="margin-right: 8px"></el-icon>添加
        </span>
      </div>


      <div class="file-container">
        <el-row type="flex" align="middle">
          <el-col :span="8" class="row-lable-name">岸线总长度(两岸):</el-col>
          <el-col :span="6">
            <el-input v-model.number="totalLength" type="number" :style="{ width: '80%' }"></el-input>&nbsp;m
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="8" class="row-lable-name">计算分析说明:</el-col>
          <el-col :span="12">
            <el-input v-model="analysisDesc" type="textarea" placeholder="请输入计算说明(数据来源、得出此数据的简要说明)"
              :autosize="{ minRows: 2, maxRows: 2 }"></el-input>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="8" class="row-lable-name">附件:</el-col>
          <el-col :span="16">
            <MyUploadFile ref="uploadfile" :query-ext="queryExt" :attachs="attachs" />
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button class="btn-info" @click="close">取 消</el-button>
        <el-button class="btn-submit" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { EcologicalBank } from '@/api/target/riverTarget.js'
import { File } from '@/api/file/index.js'
import IndicatorMap from '../indicatorMap.vue'
export default {
  // 生态岸坡比例
  name: 'EcologicalBank',
  components: { IndicatorMap },
  props: {
    schemeId: {
      type: Number,
      default: 1
    },
    targetData: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      flag: true,
      ecoBankTableData: [],
      finalScore: null,

      totalLength: null,
      analysisDesc: '',

      id: null,
      attachs: '',
      queryExt: {},

      innerVisible: false,
      EcologicalBank: {},

      indicatorDefinition: false,
      anchor: ''
    }
  },
  computed: {},
  watch: {
    targetData: {
      immediate: true,
      handler(val, oldval) {
        console.log('xxx', val)
        if (JSON.stringify(val) !== '{}') {
          this.loadData(val)
        }
      }
    }
  },
  created() { },
  mounted() { },
  methods: {
    /**
     * @description 指标描述展示
     * @author hu_ty
     * @since
     * @param {*}
     *
     */
    openTip(val) {
      this.anchor = val
      this.indicatorDefinition = true
    },
    /**
     * @description
     * @author hu_ty
     * @since
     * @param {object} data 指标数据内容
     *
     */
    async loadData(data) {
      console.log('loadData', data)

      this.id = data.id
      this.attachs = data.attachs
      this.queryExt = data.queryExt
      this.totalLength = data.totalLength
      this.analysisDesc = data.analysisDesc
      this.finalScore = data.scoreVal

      let promise = []

      data.detailList.forEach((item) => {
        if (item.anPoPngCode && !item.anPoPngCode.includes('tmp')) {
          item.urlList = []
          let arr = item.anPoPngCode.split(',')
          arr.forEach((i) => {
            let a = File.findUrl(i).then((res) => {
              item.urlList.push(res.data.data)
            })
            promise.push(a)
          })
        }
      })
      await Promise.all(promise)
      this.ecoBankTableData = data.detailList
      console.log('<== this.ecoBankTableData ==>', this.ecoBankTableData)
    },
    /**
     * @description  获取点位图片信息
     * @author hu_ty
     * @since
     * @param {string} code 图片的filecode
     *
     */
    getImage(code) {
      File.findUrl(code).then((res) => {
        console.log(res)
      })
    },
    /**
     * @description  关闭组件并改变父级 targetFlag 值
     * @author hu_ty
     * @since 22-06-24
     * @param {*}
     *
     */
    close() {
      this.$emit('changeFlag')
    },

    
    deleteRow(index, rows) {
      rows.splice(index, 1)
    },
    trueDeleteRow(row) {
      console.log(row)
      this.$confirm('确定删除该数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          EcologicalBank.deleteDetailById(row.id).then((res) => {
            EcologicalBank.find(row.parentId).then((res) => {
              this.loadData(res.data.data)
            })
          })
        })
        .catch(() => {
          console.log('取消删除')
        })
    },

    /**
     * @description 绘制生态岸坡
     * @author hu_ty
     * @since
     * @param {*}
     *
     */
    draw(data) {
      this.EcologicalBank = data
      this.innerVisible = true
    },

    addNew() {
      this.EcologicalBank = {}
      this.ecoBankTableData.push(this.EcologicalBank)
      // this.innerVisible = true
    },

    saveEcologicalBank(data) {
      console.log('saveEcologicalBank', data)
      this.innerVisible = false
    },
    cancelEcologicalBank() {
      this.innerVisible = false
    },
    //数据保存
    submit() {
      this.attachs = this.$refs.uploadfile.getAttachs()
      let param = {
        attachs: this.attachs,
        detailList: this.ecoBankTableData,
        schemeId: this.schemeId,
        totalLength: this.totalLength,
        analysisDesc: this.analysisDesc
      }
      console.log('param', param)
      EcologicalBank.saveInfo(param).then((res) => {
        this.close()
      })
    },

    /**
     * @description  最终得分计算
     * @author hu_ty
     * @since
     * @param {*}
     *
     */
    countFinalScore() {
      // let score
      // let per
      // let length = this.outletTableData.length
      // if (length > 0) {
      //   let count = 0
      //   this.outletTableData.forEach((item) => {
      //     if (item.conform) {
      //       count = count + 1
      //     }
      //   })
      //   per = (count / length) * 100
      // } else {
      //   per = 100
      // }
      // switch (true) {
      //   case per == 100:
      //     score = 100
      //     break
      //   case per >= 90:
      //     score = 80 + ((per - 90) / 10) * 10
      //     break
      //   case per >= 80:
      //     score = 60 + ((per - 80) / 10) * 20
      //     break
      //   case per >= 60:
      //     score = 40 + ((per - 60) / 20) * 20
      //     break
      //   case per >= 0:
      //     score = (per / 60) * 40
      //     break
      //   default:
      // }
      // this.finalScore = score
    }
  }
}
</script>
<style lang="less" scoped>
.file-container {
  margin-top: 16px;
}

.el-row {
  margin-bottom: 16px;
}

/deep/ .el-table__row .cell>.el-input>input {
  border: 0;
}

/deep/ .el-table__row .el-input__inner {
  padding: 0;
  text-align: center;
}
</style>
