<template>
  <el-dialog
    title="公众满意度"
    :visible.sync="flag"
    width="60%"
    :before-close="close"
    top="64px"
    :modal="false"
    :close-on-click-modal="false"
  >
    <div slot="title" class="dialog-header-title">
      <span class="title-name">
        公众满意度
        <i class="el-icon-question" @click="openTip('gzmyd')"></i>
      </span>
      <span class="title-score">
        最终得分:
        <span v-if="finalScore">{{ finalScore.toFixed(1) }}</span>
        <span v-else>--</span>
      </span>
    </div>
    <Standrad
      :anchor="anchor"
      :show-flag="indicatorDefinition"
      @changFlag="() => (indicatorDefinition = !indicatorDefinition)"
    />
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="问卷展示" name="first">
        <div class="iframe-container">
          <iframe :src="showurl" frameborder="0"></iframe>
        </div>
      </el-tab-pane>
      <el-tab-pane label="问卷二维码" name="second">
        <div class="qrcode-container">
          <div id="qrcodeId" ref="qrcode" style="margin-left: 10px" @click="download"></div>
          <span style="padding: 10px; color: coral">
            提示：可以点击二维码进行二维码的下载功能，将下载的二维码图片转发给公众即可扫码填报问卷
          </span>
        </div>
      </el-tab-pane>
      <el-tab-pane label="研判" name="yanpan">
        <div class="count-container">
          <QuesDetail :scheme-id="schemeId" />
        </div>
      </el-tab-pane>
      <el-tab-pane label="统计" name="third">
        <div class="count-container">
          <QuesCount :scheme-id="schemeId" />
        </div>
      </el-tab-pane>
    </el-tabs>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="close">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
  import QRCode from 'qrcodejs2'
  import QuesCount from '../questionnaire/quesCount.vue'
  import QuesDetail from '../questionnaire/questionDetail.vue'

  export default {
    // 公众满意度
    name: 'PublicSatisfaction',
    components: {
      QuesCount,
      QuesDetail
    },
    props: {
      schemeId: {
        type: Number,
        default: 1
      },
      targetData: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        flag: true,
        finalScore: null,
        activeName: 'third',

        questionnaireToken: this.schemeId,
        showurl: null,

        indicatorDefinition: false,
        anchor: ''
      }
    },
    computed: {},
    watch: {
      targetData: {
        immediate: true,
        handler(val, oldval) {
          if (JSON.stringify(val) !== '{}') {
            this.loadData(val)
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description 指标描述展示
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      openTip(val) {
        this.anchor = val
        this.indicatorDefinition = true
      },
      /**
       * @description 数据初始化加载
       * @author hu_ty
       * @since
       * @param {object} data 有数据的指标信息内容
       *
       */
      loadData(data) {
        this.finalScore = data.scoreVal
        this.id = data.id
      },
      /**
       * @description  关闭组件并改变父级 targetFlag 值
       * @author hu_ty
       * @since 22-06-24
       * @param {*}
       *
       */
      close() {
        this.$emit('changeFlag')
      },
      /**
       * @description tab 页签切换功能和回调函数
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      handleClick(tab, event) {
        // console.log(tab, event)
        if (tab.label === '问卷展示') {
          this.showQues()
        } else if (tab.label === '问卷二维码') {
          this.qrCode()
        } else if (tab.label === '统计') {
          this.statistical()
        } else if (tab.label === '研判') {
          // this.statistical()
        }
      },
      /**
       * @description 问卷统计
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      statistical() {},
      /**
       * @description 预览问卷
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      showQues() {
        let url =
          window.location.origin +
          window.location.pathname +
          '#/questionnaire?questionnaireToken=' +
          this.questionnaireToken
        // window.open(url, '_blank')
        this.showurl = url
      },
      /**
       * @description  调查问卷二维码动态生成
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      qrCode() {
        setTimeout(() => {
          let url =
            window.location.origin +
            window.location.pathname +
            '/#/questionnaire?questionnaireToken=' +
            this.questionnaireToken
          document.getElementById('qrcodeId').innerHTML = ''
          let qrcode = new QRCode('qrcodeId', {
            // 第一个参数是组件的id值
            text: url, // 生成二维码的文本
            width: 300,
            height: 300,
            colorDark: '#000000', // 二维码色
            colorLight: '#ffffff', // 二维码背景色
            correctLevel: QRCode.CorrectLevel.H // 容错等级
          })
        }, 0)
      },
      /**
       * @description 二维码下载
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      download() {
        let canvasData = this.$refs.qrcode.getElementsByTagName('canvas')
        let a = document.createElement('a')
        let event = new MouseEvent('click') // 创建一个单击事件
        a.href = canvasData[0].toDataURL('image/png')
        a.download = '公众满意度调查问卷二维码'
        a.dispatchEvent(event) // 触发a的单击事件
      }
    }
  }
</script>
<style lang="less" scoped>
  .qrcode-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
  }
  .iframe-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    height: 500px;
    iframe {
      width: 400px;
      height: 100%;
    }
  }
  .count-container {
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 600px;
    overflow: auto;
  }
  //   .qrcodeId {
  //   }
  /*滚动条bai整体*/
  ::-webkit-scrollbar {
    width: 14px; /*滚动条宽度*/
  }
</style>
