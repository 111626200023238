<template>
  <div>
    <el-dialog title="水功能区达标率" :visible.sync="flag" width="468px" :before-close="close" :modal="false"
      :close-on-click-modal="false">
      <div slot="title" class="dialog-header-title">
        <span class="title-name">
          水功能区达标率
          <i class="el-icon-question" @click="openTip('sgnqdbl')"></i>
        </span>
        <span class="title-score">
          最终得分:
          <span v-if="finalScore">{{ finalScore.toFixed(0) }}</span>
          <span v-else>--</span>
        </span>
      </div>
      <Standrad :anchor="anchor" :show-flag="indicatorDefinition"
        @changFlag="() => (indicatorDefinition = !indicatorDefinition)" />
      <div class="body-content">
        <!-- <el-row type="flex" align="middle">
          <el-col :span="4" class="row-lable-name"><div>标准级别:</div> </el-col>
          <el-col :span="16">
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item" :label="item" :value="item"> </el-option>
            </el-select>
          </el-col>
        </el-row> -->
        <el-row>
          <el-table :data="tableData" border style="width: 100%" header-cell-class-name="table-header-cls">
            <el-table-column prop="standardLevel" label="标准级别">
              <template slot-scope="scope">
                <el-select v-model="scope.row.standardLevel" placeholder="请选择">
                  <el-option v-for="item in options" :key="item" :label="item" :value="item"> </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column prop="anDan" label="氨氮">
              <template slot-scope="scope">
                <el-input v-model.number="scope.row.anDan" type="number"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="gaoMengSuanYan" label="高锰酸盐">
              <template slot-scope="scope">
                <el-input v-model.number="scope.row.gaoMengSuanYan" type="number"></el-input>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <el-button type="text" style="color:red"
                  @click="del(scope.$index, tableData, scope.row.id)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row>
          <div class="add-box" @click="addNew">
            <span><el-icon class="el-icon-plus" style="margin-right: 8px"></el-icon>添加新数据</span>
          </div>
        </el-row>
        <el-row type="flex">
          <el-col :span="8" class="row-lable-name">附件:</el-col>
          <el-col :span="16">
            <MyUploadFile ref="uploadfile" :query-ext="queryExt" :attachs="attachs" />
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button class="btn-info" @click="close">取 消</el-button>
        <el-button class="btn-submit" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { WaterFunArea } from '@/api/target/riverTarget'
export default {
  // 水功能区达标率
  name: 'WaterFunctionalArea',
  components: {},
  props: {
    schemeId: {
      type: Number,
      default: 1
    },
    targetData: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      flag: true,
      value: '',
      options: ['Ⅰ', 'Ⅱ', 'Ⅲ', 'Ⅳ', 'Ⅴ', '劣Ⅴ'],
      finalScore: 0,
      tableData: [],

      queryExt: {},
      attachs: '',
      id: null,

      indicatorDefinition: false,
      anchor: ''
    }
  },
  computed: {},
  watch: {
    targetData: {
      immediate: true,
      handler(val, oldval) {
        if (JSON.stringify(val) !== '{}') {
          this.loadData(val)
        }
      }
    }
  },
  created() { },
  mounted() { },
  methods: {
    /**
     * @description 指标描述展示
     * @author hu_ty
     * @since
     * @param {*}
     *
     */
    openTip(val) {
      this.anchor = val
      this.indicatorDefinition = true
    },
    /**
     * @description 数据初始化加载
     * @author hu_ty
     * @since
     * @param {object} data 有数据的指标信息内容
     *
     */
    loadData(data) {
      console.log('<== 111 ==>', data)
      this.tableData = data.detailList || []
      this.finalScore = data.scoreVal
      this.attachs = data.attachs
      this.queryExt = data.queryExt
      this.id = data.id
    },
    /**
     * @description  关闭组件并改变父级 targetFlag 值
     * @author hu_ty
     * @since 22-06-24
     * @param {*}
     *
     */
    close() {
      this.$emit('changeFlag')
    },

    addNew() {
      let newData = {
        standardLevel: null,
        anDan: null,
        gaoMengSuanYan: null,
        key: new Date()
      }
      this.tableData.push(newData)
    },
    /**
     * @description  提交数据
     * @author hu_ty
     * @since
     * @param {*}
     *
     */
    submit() {
      this.attachs = this.$refs.uploadfile.getAttachs()

      let param = {
        id: this.id,
        attachs: this.attachs,
        detailList: this.tableData,
        schemeId: this.schemeId
      }
      WaterFunArea.saveInfo(param).then((res) => {
        this.close()
      })
    },
    del(index, rows, id) {
      rows.splice(index, 1)
      WaterFunArea.delDetail(id).then((res) => {

      })
    }
  }
}
</script>
<style lang="less" scoped>
.body-content {
  .el-row {
    margin-bottom: 16px;

    .el-select {
      display: inline;
    }
  }
}
</style>
