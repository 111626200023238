<template>
  <div>
    <el-dialog
      title="河流流动性"
      :visible.sync="flag"
      width="30%"
      :before-close="close"
      :modal="false"
      :close-on-click-modal="false"
    >
      <div slot="title" class="dialog-header-title">
        <span class="title-name">
          河流流动性
          <i class="el-icon-question" @click="openTip('hlldx')"></i>
        </span>
        <span class="title-score">
          最终得分:
          <span v-if="scoreVal">{{ scoreVal.toFixed(1) }}</span>
          <span v-else>--</span>
          分
        </span>
      </div>
      <Standrad
        :anchor="anchor"
        :show-flag="indicatorDefinition"
        @changFlag="() => (indicatorDefinition = !indicatorDefinition)"
      />
      <div class="body-content">
        <el-row type="flex" align="middle">
          <el-col :span="8" class="row-lable-name"><div>断面流速:</div> </el-col>
          <el-col :span="16">
            <el-input v-model="speed" type="number" style="width: 40%" @blur="count"></el-input>&nbsp;m/s
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="8" class="row-lable-name">得分:</el-col>
          <el-col :span="16">
            <div v-if="funVal >= 0" class="score">{{ funVal }}</div>
            <div v-else>暂未评分</div>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="8" class="row-lable-name">评价等级:</el-col>
          <el-col :span="16">{{ scoreDesc }}</el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="8" class="row-lable-name">计算分析说明:</el-col>
          <el-col :span="16">
            <el-input
              v-model="analysisDesc"
              type="textarea"
              placeholder="请输入计算说明(数据来源、得出此数据的简要说明)"
              :autosize="{ minRows: 2, maxRows: 2 }"
            ></el-input>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="8" class="row-lable-name">附件:</el-col>
          <el-col :span="16">
            <MyUploadFile ref="uploadfile" :query-ext="queryExt" :attachs="attachs" />
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button class="btn-info" @click="close">取 消</el-button>
        <el-button class="btn-submit" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { FluvialFluidity } from '@/api/target/riverTarget.js'
  export default {
    // 、、河流流动性
    name: 'FluvialFluidity',
    components: {},
    props: {
      schemeId: {
        type: Number,
        default: 1
      },
      targetData: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        flag: true,

        id: null,
        speed: 0,
        funVal: 0,
        scoreVal: 0,
        scoreDesc: '',
        analysisDesc: '',
        attachs: '',
        queryExt: {},

        indicatorDefinition: false,
        anchor: ''
      }
    },
    computed: {},
    watch: {
      targetData: {
        immediate: true,
        handler(val, oldval) {
          if (val != {}) {
            this.loadData(val)
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description 指标描述展示
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      openTip(val) {
        this.anchor = val
        this.indicatorDefinition = true
      },
      /**
       * @description 数据初始化加载
       * @author hu_ty
       * @since
       * @param {object} data 有数据的指标信息内容
       *
       */
      loadData(data) {
        // console.log('<== loadData ==>', data)
        this.speed = data.speed
        this.funVal = data.funVal
        this.scoreVal = data.scoreVal
        this.scoreDesc = data.scoreDesc
        this.analysisDesc = data.analysisDesc
        this.attachs = data.attachs
        this.queryExt = data.queryExt
        this.id = data.id
      },
      /**
       * @description 河流流动性根据输入值计算分值和评价等级
       * @author hu_ty
       * @since 22-06-24
       * @param {*}
       *
       */
      count() {
        let scoreIt
        switch (this.speed >= 0) {
          case this.speed >= 0.3:
            scoreIt = 100
            this.scoreDesc = '非常健康'
            break
          case this.speed >= 0.2:
            scoreIt = 80 + ((this.speed - 0.2) / 0.1) * 20
            this.scoreDesc = '健康'
            break
          case this.speed >= 0.1:
            scoreIt = 60 + ((this.speed - 0.1) / 0.1) * 20
            this.scoreDesc = '亚健康'
            break
          case this.speed >= 0.05:
            scoreIt = 40 + ((this.speed - 0.05) / 0.05) * 20
            this.scoreDesc = '不健康'
            break
          case this.speed >= 0:
            scoreIt = 0 + (this.speed / 0.05) * 40
            this.scoreDesc = '病态'
            break
          default:
            scoreIt = -1
        }
        this.funVal = scoreIt
        switch (scoreIt >= 0) {
          case scoreIt == 100:
            this.scoreVal = 100
            break
          case scoreIt >= 80:
            this.scoreVal = 80 + ((scoreIt - 80) / 20) * 10
            break
          case scoreIt >= 60:
            this.scoreVal = scoreIt
            break
          case scoreIt >= 40:
            this.scoreVal = scoreIt
            break
          case scoreIt >= 0:
            this.scoreVal = scoreIt
            break
          default:
            this.scoreVal = -1
        }
      },
      /**
       * @description  关闭组件并改变父级 targetFlag 值
       * @author hu_ty
       * @since 22-06-24
       * @param {*}
       *
       */
      close() {
        this.$emit('changeFlag')
      },
      /**
       * @description 数据提交
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      submit() {
        // 获取文件列表信息
        this.attachs = this.$refs.uploadfile.getAttachs()

        let param = {
          id: this.id,
          schemeId: this.schemeId,
          speed: this.speed,
          funVal: this.funVal,
          scoreVal: this.scoreVal,
          scoreDesc: this.scoreDesc,
          analysisDesc: this.analysisDesc,
          attachs: this.attachs
        }
        try {
          const res = FluvialFluidity.saveInfo(param)
          console.log('<==  ==>', res)
          this.close()
        } catch (error) {
          console.log('<==  ==>', error)
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  .body-content {
    // margin: 0 48px;
    .el-row {
      margin-bottom: 16px;
    }
  }

  .score {
    color: rgba(64, 158, 255, 1);
    font-family: 'PingFang SC';
    font-weight: 500;
    font-size: 18px;
    text-transform: capitalize;
  }
</style>
