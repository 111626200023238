<template>
  <div class="container">
    <div>
      <el-button type="text" @click="doPrint">打印</el-button>
    </div>
    <table ref="table" class="table" border="1" cellspacing="0" cellpadding="10">
      <caption>
        <h3>河湖健康评价公众调查表-统计表</h3>
      </caption>
      <tr>
        <td colspan="9">河湖对个人生活的重要程度</td>
        <td colspan="9">与河湖的关系</td>
      </tr>
      <tr>
        <td colspan="4">很重要</td>
        <td colspan="5">
          <div v-if="arrayList[8].propList[0]['total']">{{ arrayList[8].propList[0].total }}</div>
          <div v-else>-</div>
        </td>
        <td colspan="4">河湖周边居民</td>
        <td colspan="5">
          <div v-if="arrayList[9].propList[0]['total']">{{ arrayList[9].propList[0].total }}</div>
          <div v-else>-</div>
        </td>
      </tr>
      <tr>
        <td colspan="4">一般</td>
        <td colspan="5">
          <div v-if="arrayList[8].propList[1]['total']">{{ arrayList[8].propList[1].total }}</div>
          <div v-else>-</div>
        </td>
        <td colspan="4">河湖管理相关从业者</td>
        <td colspan="5">
          <div v-if="arrayList[9].propList[1]['total']">{{ arrayList[9].propList[1].total }}</div>
          <div v-else>-</div>
        </td>
      </tr>
      <tr>
        <td colspan="4">不重要</td>
        <td colspan="5">
          <div v-if="arrayList[8].propList[2]['total']">{{ arrayList[8].propList[2].total }}</div>
          <div v-else>-</div>
        </td>
        <td colspan="4">一般公众</td>
        <td colspan="5">
          <div v-if="arrayList[9].propList[2]['total']">{{ arrayList[9].propList[2].total }}</div>
          <div v-else>-</div>
        </td>
      </tr>
      <tr>
        <td colspan="9">水量及水质状况</td>
        <td colspan="9">河湖岸带状况</td>
      </tr>
      <tr>
        <td colspan="3" rowspan="3">满溢现象</td>
        <td colspan="1">经常</td>
        <td colspan="5">
          <div v-if="arrayList[10].propList[0]['total']">{{ arrayList[10].propList[0].total }}</div>
          <div v-else>-</div>
        </td>
        <td colspan="3" rowspan="3">植被状况</td>
        <td colspan="3">绿化程度高</td>
        <td colspan="3">
          <div v-if="arrayList[13].propList[0]['total']">{{ arrayList[13].propList[0].total }}</div>
          <div v-else>-</div>
        </td>
      </tr>
      <tr>
        <td colspan="1">偶尔</td>
        <td colspan="5">
          <div v-if="arrayList[10].propList[1]['total']">{{ arrayList[10].propList[1].total }}</div>
          <div v-else>-</div>
        </td>
        <td colspan="3">绿化程度一般</td>
        <td colspan="3">
          <div v-if="arrayList[13].propList[1]['total']">{{ arrayList[13].propList[1].total }}</div>
          <div v-else>-</div>
        </td>
      </tr>
      <tr>
        <td colspan="1">不存在</td>
        <td colspan="5">
          <div v-if="arrayList[10].propList[2]['total']">{{ arrayList[10].propList[2].total }}</div>
          <div v-else>-</div>
        </td>
        <td colspan="3">绿化程度差</td>
        <td colspan="3">
          <div v-if="arrayList[13].propList[2]['total']">{{ arrayList[13].propList[2].total }}</div>
          <div v-else>-</div>
        </td>
      </tr>

      <tr>
        <td colspan="3" rowspan="3">水质</td>
        <td colspan="1">清洁</td>
        <td colspan="5">
          <div v-if="arrayList[11].propList[0]['total']">{{ arrayList[11].propList[0].total }}</div>
          <div v-else>-</div>
        </td>
        <td colspan="3" rowspan="3">垃圾堆放</td>
        <td colspan="3">无垃圾堆放</td>
        <td colspan="3">
          <div v-if="arrayList[14].propList[0]['total']">{{ arrayList[14].propList[0].total }}</div>
          <div v-else>-</div>
        </td>
      </tr>
      <tr>
        <td colspan="1">一般</td>
        <td colspan="5">
          <div v-if="arrayList[11].propList[1]['total']">{{ arrayList[11].propList[1].total }}</div>
          <div v-else>-</div>
        </td>
        <td colspan="3">有部分垃圾堆放</td>
        <td colspan="3">
          <div v-if="arrayList[14].propList[1]['total']">{{ arrayList[14].propList[1].total }}</div>
          <div v-else>-</div>
        </td>
      </tr>
      <tr>
        <td colspan="1">较脏</td>
        <td colspan="5">
          <div v-if="arrayList[11].propList[2]['total']">{{ arrayList[11].propList[2].total }}</div>
          <div v-else>-</div>
        </td>
        <td colspan="3">有较多垃圾堆放</td>
        <td colspan="3">
          <div v-if="arrayList[14].propList[2]['total']">{{ arrayList[14].propList[2].total }}</div>
          <div v-else>-</div>
        </td>
      </tr>

      <tr>
        <td colspan="3" rowspan="3">水景观</td>
        <td colspan="1">优美</td>
        <td colspan="5">
          <div v-if="arrayList[12].propList[0]['total']">{{ arrayList[12].propList[0].total }}</div>
          <div v-else>-</div>
        </td>
        <td colspan="3" rowspan="3">散步等游乐休闲活动</td>
        <td colspan="3">适宜</td>
        <td colspan="3">
          <div v-if="arrayList[15].propList[0]['total']">{{ arrayList[15].propList[0].total }}</div>
          <div v-else>-</div>
        </td>
      </tr>
      <tr>
        <td colspan="1">一般</td>
        <td colspan="5">
          <div v-if="arrayList[12].propList[1]['total']">{{ arrayList[12].propList[1].total }}</div>
          <div v-else>-</div>
        </td>
        <td colspan="3">一般</td>
        <td colspan="3">
          <div v-if="arrayList[15].propList[1]['total']">{{ arrayList[15].propList[1].total }}</div>
          <div v-else>-</div>
        </td>
      </tr>
      <tr>
        <td colspan="1">较差</td>
        <td colspan="5">
          <div v-if="arrayList[12].propList[2]['total']">{{ arrayList[12].propList[2].total }}</div>
          <div v-else>-</div>
        </td>
        <td colspan="3">不适宜</td>
        <td colspan="3">
          <div v-if="arrayList[15].propList[2]['total']">{{ arrayList[15].propList[2].total }}</div>
          <div v-else>-</div>
        </td>
      </tr>

      <tr>
        <td colspan="18">河湖管理状况</td>
      </tr>
      <tr>
        <td colspan="4" rowspan="2">河湖治理成效</td>
        <td colspan="1">明显</td>
        <td colspan="1">
          <div v-if="arrayList[16].propList[0]['total']">{{ arrayList[16].propList[0].total }}</div>
          <div v-else>-</div>
        </td>
        <td colspan="3" rowspan="6">与呵护相关的历史及文化保护程度</td>
        <td colspan="4" rowspan="3">是否有历史古迹或文化名胜</td>
        <td colspan="4" rowspan="2">有</td>
        <td colspan="1" rowspan="2">
          <div v-if="arrayList[19].propList[0]['total']">{{ arrayList[19].propList[0].total }}</div>
          <div v-else>-</div>
        </td>
      </tr>
      <tr>
        <!-- <td colspan="4" rowspan="2">河湖治理成效</td> -->
        <td colspan="1">不明显</td>
        <td colspan="1">
          <div v-if="arrayList[16].propList[1]['total']">{{ arrayList[16].propList[1].total }}</div>
          <div v-else>-</div>
        </td>
        <!-- <td colspan="3" rowspan="6">与呵护相关的历史及文化保护程度</td> -->
        <!-- <td colspan="4" rowspan="3">是否有历史古迹或文化名胜</td> -->
        <!-- <td colspan="4" rowspan="1">无</td>
        <td colspan="1"></td> -->
      </tr>
      <tr>
        <td colspan="4" rowspan="2">水质恶化事件</td>
        <td colspan="1">有</td>
        <td colspan="1">
          <div v-if="arrayList[17].propList[0]['total']">{{ arrayList[17].propList[0].total }}</div>
          <div v-else>-</div>
        </td>
        <!-- <td colspan="3" rowspan="6">与呵护相关的历史及文化保护程度</td> -->
        <!-- <td colspan="4" rowspan="3">是否有历史古迹或文化名胜</td> -->
        <td colspan="4" rowspan="1">无</td>
        <td colspan="1">
          <div v-if="arrayList[19].propList[1]['total']">{{ arrayList[19].propList[1].total }}</div>
          <div v-else>-</div>
        </td>
      </tr>
      <tr>
        <!-- <td colspan="4" rowspan="2">水质恶化事件</td> -->
        <td colspan="1">无</td>
        <td colspan="1">
          <div v-if="arrayList[17].propList[1]['total']">{{ arrayList[17].propList[1].total }}</div>
          <div v-else>-</div>
        </td>
        <!-- <td colspan="3" rowspan="6">与呵护相关的历史及文化保护程度</td> -->
        <td colspan="4" rowspan="3">历史古迹或文化名胜保护与开发情况</td>
        <td colspan="4" rowspan="1">没有保护</td>
        <td colspan="1">
          <div v-if="arrayList[20].propList[0]['total']">{{ arrayList[20].propList[0].total }}</div>
          <div v-else>-</div>
        </td>
      </tr>
      <tr>
        <td colspan="4" rowspan="2">河湖日常环境维护</td>
        <td colspan="1">经常</td>
        <td colspan="1">
          <div v-if="arrayList[18].propList[0]['total']">{{ arrayList[18].propList[0].total }}</div>
          <div v-else>-</div>
        </td>
        <!-- <td colspan="3" rowspan="6">与呵护相关的历史及文化保护程度</td> -->
        <!-- <td colspan="4" rowspan="3">历史古迹或文化名胜保护与开发情况</td> -->
        <td colspan="4" rowspan="1">有保护，不对外开放</td>
        <td colspan="1">
          <div v-if="arrayList[20].propList[1]['total']">{{ arrayList[20].propList[1].total }}</div>
          <div v-else>-</div>
        </td>
      </tr>
      <tr>
        <!-- <td colspan="4" rowspan="2">河湖日常环境维护</td> -->
        <td colspan="1">很少见</td>
        <td colspan="1">
          <div v-if="arrayList[18].propList[1]['total']">{{ arrayList[18].propList[1].total }}</div>
          <div v-else>-</div>
        </td>
        <!-- <td colspan="3" rowspan="6">与呵护相关的历史及文化保护程度</td> -->
        <!-- <td colspan="4" rowspan="3">历史古迹或文化名胜保护与开发情况</td> -->
        <td colspan="4" rowspan="1">有保护，也对外开放</td>
        <td colspan="1">
          <div v-if="arrayList[20].propList[2]['total']">{{ arrayList[20].propList[2].total }}</div>
          <div v-else>-</div>
        </td>
      </tr>

      <tr>
        <td colspan="18">对河湖的满意程度调查</td>
      </tr>
      <tr>
        <td colspan="6">总体满意度（评分）</td>
        <td colspan="6">不满意的原因是什么？</td>
        <td colspan="6">希望状况是什么样的？</td>
      </tr>
      <tr>
        <td colspan="4">很满意(90~100)</td>
        <td colspan="2">
          <div v-if="arrayList[21].propList[0]['total']">{{ arrayList[21].propList[0].total }}</div>
          <div v-else>-</div>
        </td>
        <td colspan="6" rowspan="4">-</td>
        <td colspan="6" rowspan="4">-</td>
      </tr>
      <tr>
        <td colspan="4">满意(80~90)</td>
        <td colspan="2">
          <div v-if="arrayList[21].propList[1]['total']">{{ arrayList[21].propList[1].total }}</div>
          <div v-else>-</div>
        </td>
      </tr>

      <tr>
        <td colspan="4">基本满意(60~80)</td>
        <td colspan="2">
          <div v-if="arrayList[21].propList[2]['total']">{{ arrayList[21].propList[2].total }}</div>
          <div v-else>-</div>
        </td>
      </tr>
      <tr>
        <td colspan="4">不满意(0~60)</td>
        <td colspan="2">
          <div v-if="arrayList[21].propList[3]['total']">{{ arrayList[21].propList[3].total }}</div>
          <div v-else>-</div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
  import { Questionnaire } from '@/api/target/riverTarget'
  export default {
    // 调查问卷统计页面
    name: 'QuesCount',
    props: {
      schemeId: {
        type: Number,
        default: 1
      }
    },
    data() {
      return {
        countData: null,
        arrayList: []
      }
    },
    mounted() {
      this.loadData()
    },
    methods: {
      /**
       * @description 统计数据获取
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      loadData() {
        Questionnaire.getCountData(this.schemeId).then((res) => {
          this.countData = res.data.data.complete
          this.getBlockList(this.countData)

          this.arrayList = this.arrayList.sort(this.compare('orderNum'))
          console.log('<== this.arrayList ==>', this.arrayList)
          this.arrayList.splice(0, 1)
          console.log('<== this.arrayList ==>', this.arrayList)
        })
      },
      /**
       * @description  数据结果处理成页面需要展示的数据内容
       * @author hu_ty
       * @since
       * @param {object} info 统计的调查文件xml解析数据
       *
       */
      getBlockList(info) {
        if (info.nodeList) {
          info.nodeList.forEach((item) => {
            this.arrayList.push(item)
          })
        }
        if (info.blockList) {
          for (let i = 0; i < info.blockList.length; i++) {
            this.getBlockList(info.blockList[i])
          }
          return
        }
      },
      compare(property) {
        return function (a, b) {
          var value1 = a[property]
          var value2 = b[property]
          return value1 - value2
        }
      },

      /**
       * @description  导出table
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      doPrint() {
        this.$print(this.$refs.table)
      }
    }
  }
</script>
<style lang="less" scoped>
  .container {
    display: flex;
    // align-items: center;
    justify-content: center;
    // height: 100vh;
    overflow: hidden;
    text-align: center;
  }
  .table {
    width: 1200px;
    border: 1px solid #eee;
    table-layout: fixed;
    margin: 20px auto;
  }
  caption {
    margin-bottom: 20px;
  }

  td div {
    font-size: 16px;
    font-weight: blod;
    color: #000;
  }
</style>
