<template>
  <div>
    <el-dialog
      title="景观舒适度"
      :visible.sync="flag"
      width="40%"
      :before-close="close"
      :modal="false"
      :close-on-click-modal="false"
    >
      <el-dialog
        v-if="innerVisible"
        width="80%"
        title="景观舒适度"
        :visible.sync="innerVisible"
        append-to-body
        top="2%"
      >
        <IndicatorMap :type="'Landscape'" @saveAddObj="saveAddObj" @cancelAddObj="cancelAddObj"></IndicatorMap>
      </el-dialog>

      <div slot="title" class="dialog-header-title">
        <span class="title-name">
          景观舒适度
          <i class="el-icon-question" @click="openTip('jgssd')"></i>
        </span>
        <span class="title-score">
          最终得分:
          <span v-if="finalScore">{{ finalScore.toFixed(1) }}</span>
          <span v-else>--</span>
        </span>
      </div>
      <Standrad
        :anchor="anchor"
        :show-flag="indicatorDefinition"
        @changFlag="() => (indicatorDefinition = !indicatorDefinition)"
      />
      <div v-for="(data, index) in detailList" v-cloak :key="index">
        <div class="container">
          <div class="item-content">
            <h3>观赏性</h3>
            <div>
              <p v-if="data.gsxLevel && data.gsxLevel != ''">
                <el-input v-model="data.gsxLevel" type="text"></el-input>
                <!-- {{ data.gsxLevel }} -->
              </p>
              <p v-else>--</p>
            </div>
            <div v-if="data.gsxDesc && data.gsxDesc != ''">
              <p>
                <!-- {{ data.gsxDesc }} -->
                <el-input v-model="data.gsxDesc" type="text"></el-input>
              </p>
            </div>
            <div v-if="data.gsxList && data.gsxList.length > 0" class="png">
              <el-image style="width: auto; height: 100%" :src="data.gsxList[0]" :preview-src-list="data.gsxList">
              </el-image>
            </div>
            <div>
              打分：
              <el-input v-model="data.gsxScore" type="number" style="width: 30%"></el-input>
            </div>
          </div>
          <div class="item-content">
            <h3>亲水便利性</h3>
            <div>
              <p v-if="data.qmdLevel && data.qmdLevel != ''">
                <!-- {{ data.qmdLevel }} -->
                <el-input v-model="data.qmdLevel" type="text"></el-input>
              </p>
              <p v-else>--</p>
            </div>
            <div v-if="data.qmdDesc && data.qmdDesc != ''">
              <p>
                <!-- {{ data.qmdDesc }} -->
                <el-input v-model="data.qmdDesc" type="text"></el-input>
              </p>
            </div>
            <div v-if="data.qmdList && data.qmdList.length > 0" class="png">
              <el-image style="width: auto; height: 100%" :src="data.qmdList[0]" :preview-src-list="data.qmdList">
              </el-image>
            </div>
            <div>
              打分：
              <el-input v-model="data.qmdScore" type="number" style="width: 30%"></el-input>
            </div>
          </div>
          <div class="item-content">
            <h3>水文化建设</h3>
            <div>
              <p v-if="data.swhLevel && data.swhLevel != ''">
                <!-- {{ data.swhLevel }} -->
                <el-input v-model="data.swhLevel" type="text"></el-input>
              </p>
              <p v-else>--</p>
            </div>
            <div v-if="data.swhDesc && data.swhDesc != ''">
              <p>
                <!-- {{ data.swhDesc }} -->
                <el-input v-model="data.swhDesc" type="text"></el-input>
              </p>
            </div>

            <div v-if="data.swhList && data.swhList.length > 0" class="png">
              <el-image style="width: auto; height: 100%" :src="data.swhList[0]" :preview-src-list="data.swhList">
              </el-image>
            </div>

            <div>
              打分：
              <el-input v-model="data.swhScore" type="number" style="width: 30%"></el-input>
            </div>
          </div>
        </div>
      </div>

      <el-row>
        <div class="add-box" @click="addNew">
          <span><el-icon class="el-icon-plus" style="margin-right: 8px"></el-icon>添加新数据</span>
        </div>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { Landscape } from '@/api/target/riverTarget.js'
  import { File } from '@/api/file/index.js'
  import IndicatorMap from '../indicatorMap.vue'
  export default {
    // 入河排污口规范化建设率
    name: 'Landscape',
    components: { IndicatorMap },
    props: {
      schemeId: {
        type: Number,
        default: 1
      },
      targetData: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        innerVisible: false,

        flag: true,
        detailList: [],
        finalScore: null,
        id: null,

        indicatorDefinition: false,
        anchor: ''
      }
    },
    computed: {},
    watch: {
      targetData: {
        immediate: true,
        handler(val, oldval) {
          if (JSON.stringify(val) !== '{}') {
            this.loadData(val)
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description 指标描述展示
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      openTip(val) {
        this.anchor = val
        this.indicatorDefinition = true
      },
      /**
       * @description 数据初始化加载
       * @author hu_ty
       * @since
       * @param {object} data 有数据的指标信息内容
       *
       */
      loadData(data) {
        // console.log('<== loadData ==>', data)
        // this.detailList = data.detailList
        this.formmater(data.detailList)
        this.finalScore = data.scoreVal
        this.id = data.id
      },
      /**
       * @description  获取图片列表
       * @author hu_ty
       * @since
       * @param {string} code 数据内容
       *
       */
      async formmater(data) {
        this.detailList = []
        data.forEach(async (item) => {
          if (item.gsxPngCode && !item.gsxPngCode.includes('tmp')) {
            item.gsxList = []
            let arr = item.gsxPngCode.split(',')
            let promise = []
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.gsxList.push(res.data.data)
              })
              promise.push(a)
            })
            await Promise.all(promise)
          }
          if (item.qmdPngCode && !item.qmdPngCode.includes('tmp')) {
            item.qmdList = []
            let arr = item.qmdPngCode.split(',')
            let promise = []
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.qmdList.push(res.data.data)
              })
              promise.push(a)
            })
            await Promise.all(promise)
          }
          if (item.swhPngCode && !item.swhPngCode.includes('tmp')) {
            item.swhList = []
            let arr = item.swhPngCode.split(',')
            let promise = []
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.swhList.push(res.data.data)
              })
              promise.push(a)
            })
            await Promise.all(promise)
          }
          this.detailList.push(item)
        })
      },
      /**
       * @description  关闭组件并改变父级 targetFlag 值
       * @author hu_ty
       * @since 22-06-24
       * @param {*}
       *
       */
      close() {
        this.$emit('changeFlag')
      },

      addNew() {
        this.innerVisible = true
      },
      // 保存监测点
      saveAddObj(data) {
        let param = {
          schemeId: this.schemeId,
          detailList: [data]
        }
        console.log('param', param)
        Landscape.saveDeInfo(param).then((res) => {
          Landscape.find(res.data.data).then((res) => {
            this.loadData(res.data.data)
          })
        })
        this.innerVisible = false
      },
      // 取消保存监测点
      cancelAddObj() {
        this.innerVisible = false
      },

      /**
       * @description 数据保存
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      submit() {
        console.log('<==  ==>', this.detailList)
        let param = {
          id: this.id,
          detailList: this.detailList
        }
        Landscape.saveDeInfo(param).then((res) => {
          this.close()
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .file-container {
    margin: 16px;
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    border-bottom: 1px solid #999;
    margin: 10px;
    padding-bottom: 10px;
    max-height: 400px;
  }
  .item-content {
    width: 33%;
    h3 {
      margin: 10px 0;
      color: #000;
    }
    div {
      //   line-height: 1.5rem;
      height: 40px;
    }
    .png {
      height: 100px;
      width: auto;
    }
    /deep/.el-input__inner {
      border: 0;
      // border-bottom-color: #000;
      // border-bottom-width: 1px;
      // border-bottom-style: solid;
      border-radius: 0;
    }
  }
</style>
