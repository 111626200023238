<template>
  <div>
    <el-dialog
      title="岸线开发利用率"
      :visible.sync="flag"
      width="50%"
      :before-close="close"
      :modal="false"
      :close-on-click-modal="false"
    >
      <el-dialog
        v-if="innerVisible"
        width="80%"
        title="岸线开发利用率"
        :visible.sync="innerVisible"
        append-to-body
        top="2%"
      >
        <IndicatorMap
          :an-xian-kai-fa-li-yong-lv="anXianKaiFaLiYongLv"
          :type="'anXianKaiFaLiYongLv'"
          @cancelanXianKaiFaLiYongLv="cancelanXianKaiFaLiYongLv"
          @saveanXianKaiFaLiYongLv="saveanXianKaiFaLiYongLv"
        >
        </IndicatorMap>
      </el-dialog>

      <div slot="title" class="dialog-header-title">
        <span class="title-name">
          岸线开发利用率
          <i class="el-icon-question" @click="openTip('axkflyl')"></i>
        </span>
        <span class="title-score">
          最终得分:
          <span v-if="finalScore">{{ finalScore.toFixed(1) }}</span>
          <span v-else>--</span>
        </span>
      </div>

      <Standrad
        :anchor="anchor"
        :show-flag="indicatorDefinition"
        @changFlag="() => (indicatorDefinition = !indicatorDefinition)"
      />
      <div class="AnXianKaiFaLiYongLv">
        <!-- <el-row> 线下采样点： </el-row> -->

        <el-row>
          <el-table
            :data="detailList"
            style="width: 100%; font-size: 12px; text-align: center"
            header-cell-class-name="table-header-cls"
          >
            <el-table-column prop="length" label="岸线长度(m)" align="center" header-align="center" width="110">
              <template slot-scope="scope">
                <el-input v-model.number="scope.row.length" type="number"></el-input>
              </template>
            </el-table-column>

            <el-table-column prop="usedLength" label="开发长度(m)" align="center" header-align="center" width="110">
              <template slot-scope="scope">
                <el-input v-model.number="scope.row.usedLength" type="number"></el-input>
              </template>
            </el-table-column>

            <el-table-column prop="anXianExist" label="有无开发利用" align="center" header-align="center" width="110">
              <template slot-scope="scope">
                <el-input v-model="scope.row.anXianExist" type="text"></el-input>
              </template>
            </el-table-column>

            <el-table-column prop="length" label="现场图片" align="center" header-align="center" width="100">
              <template slot-scope="scope">
                <div v-if="scope.row.anXianPngCodeList && scope.row.anXianPngCodeList.length > 0" class="png">
                  <el-image
                    style="width: 90px; height: 90px"
                    fit="scale-down"
                    :src="scope.row.anXianPngCodeList[0]"
                    :preview-src-list="scope.row.anXianPngCodeList"
                  >
                  </el-image>
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="anXianDesc" label="现场描述" align="center" header-align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.anXianDesc" type="text"></el-input>
              </template>
            </el-table-column>

            <el-table-column fixed="right" label="操作" width="120" align="center">
              <template slot-scope="scope">
                <el-button v-if="!scope.row.id" type="text" @click.native.prevent="deleteRow(scope.$index, detailList)"
                  >移除</el-button
                >
                <el-button
                  v-if="scope.row.id"
                  type="text"
                  style="color: red"
                  @click.native.prevent="trueDeleteRow(scope.row, detailList)"
                  >删除</el-button
                >
                <el-button type="text" @click.native.prevent="draw(scope.row)">绘制</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>

        <el-row>
          <div class="add-box" @click="addNew">
            <span> <el-icon class="el-icon-plus" style="margin-right: 8px"></el-icon>添加 </span>
          </div>
        </el-row>

        <el-row type="flex" align="middle">
          <el-col :span="8" class="row-lable-name">岸线总长度:</el-col>
          <el-col :span="6">
            <el-input v-model.number="totalLength" type="number" :style="{ width: '80%' }"></el-input> m
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="8" class="row-lable-name">开发总长度:</el-col>
          <el-col :span="6">
            <el-input v-model.number="usedLength" type="number" :style="{ width: '80%' }"></el-input> m
          </el-col>
        </el-row>

        <el-row type="flex">
          <el-col :span="8" class="row-lable-name">附件:</el-col>
          <el-col :span="16">
            <MyUploadFile ref="uploadfile" :query-ext="queryExt" :attachs="attachs" />
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button class="btn-info" @click="close">取 消</el-button>
        <el-button class="btn-submit" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { AnXianKaiFaLiYongLv } from '@/api/target/riverTarget.js'
  import { File } from '@/api/file/index.js'
  import IndicatorMap from '../indicatorMap.vue'

  export default {
    // 岸线开发利用列表
    name: 'AnXianKaiFaLiYongLv',
    components: { IndicatorMap },
    props: {
      schemeId: {
        type: Number,
        default: 1
      },
      targetData: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        flag: true,
        finalScore: null,
        id: null,

        queryExt: {},
        attachs: '',
        innerVisible: false,
        detailList: [],
        totalLength: null,
        usedLength: null,

        indicatorDefinition: false,
        anchor: ''
      }
    },
    computed: {},
    watch: {
      targetData: {
        immediate: true,
        handler(val, oldval) {
          if (JSON.stringify(val) !== '{}') {
            this.loadData(val)
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description 指标描述展示
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      openTip(val) {
        this.anchor = val
        this.indicatorDefinition = true
      },
      // /数据初始化加载
      loadData(data) {
        console.log('<== loadData ==>', data)
        this.formmater(data.detailList)
        this.finalScore = data.scoreVal
        this.attachs = data.attachs //返回值没有attachs？？？
        this.queryExt = data.queryExt
        this.totalLength = data.totalLength
        this.usedLength = data.usedLength
        this.id = data.id
      },
      // 获取图片列表
      async formmater(data) {
        this.detailList = []
        data.forEach(async (item) => {
          if (item.anXianPngCode && !item.anXianPngCode.includes('tmp')) {
            item.anXianPngCodeList = []
            let arr = item.anXianPngCode.split(',')
            let promise = []
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.anXianPngCodeList.push(res.data.data)
              })
              promise.push(a)
            })
            await Promise.all(promise)
          }
          this.detailList.push(item)
        })
      },
      draw(data) {
        console.log('data', data)
        this.anXianKaiFaLiYongLv = data
        this.innerVisible = true
      },

      addNew() {
        this.anXianKaiFaLiYongLv = {} // length  usedLength geom
        this.detailList.push(this.anXianKaiFaLiYongLv)
        // this.innerVisible = true
      },

      deleteRow(index, rows) {
        rows.splice(index, 1)
      },

      trueDeleteRow(row) {
        console.log(row)
        this.$confirm('确定删除该数据？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            AnXianKaiFaLiYongLv.deleteDetailById(row.id).then((res) => {
              AnXianKaiFaLiYongLv.find(row.parentId).then((res) => {
                this.loadData(res.data.data)
              })
            })
          })
          .catch(() => {
            console.log('取消删除')
          })
      },

      cancelanXianKaiFaLiYongLv() {
        this.innerVisible = false
      },
      saveanXianKaiFaLiYongLv(data) {
        console.log('saveanXianKaiFaLiYongLv', data)

        let totalLength = 0
        let usedLength = 0
        this.detailList.forEach((i) => {
          if (i.length) {
            totalLength += i.length
          }
          if (i.usedLength) {
            usedLength += i.usedLength
          }
        })
        this.totalLength = totalLength
        this.usedLength = usedLength

        this.innerVisible = false
      },

      //关闭组件并改变父级 targetFlag 值
      close() {
        this.$emit('changeFlag')
      },

      //数据保存
      submit() {
        this.attachs = this.$refs.uploadfile.getAttachs()
        let param = {
          attachs: this.attachs,
          detailList: this.detailList,
          totalLength: this.totalLength,
          usedLength: this.usedLength,
          schemeId: this.schemeId
        }
        console.log('param', param)
        AnXianKaiFaLiYongLv.saveInfo(param).then((res) => {
          this.close()
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .AnXianKaiFaLiYongLv {
    .el-row {
      margin-bottom: 16px;
    }
  }
</style>
