import request from '@/utils/request'
const BASE_URL = '/wjhhjk'

/**
 * @description 主要入湖河流水质达标率
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class EnterLake {
  // 数据保存
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/zhuYaoRuHuHeLiuShuiZhiDaBiaoLv/save`,
      data: params
    })
  }
}

/**
 * @description 湖水交换能力类
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class WaterChange {
  // 数据保存
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/huShuiJiaoHuanNengLi/save`,
      data: params
    })
  }
}

/**
 * @description 水面开发利用率
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class WaterSurfaceDev {
  // 数据保存
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/shuiMianKaiFaLiYongLv/save`,
      data: params
    })
  }
}

/**
 * @description 蓝藻密度
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class Cyanobacteria {
  // 数据保存
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/lanZaoMiDu/save`,
      data: params
    })
  }
}

/**
 * @description 生态水位满足率
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class EcologicalWater {
  // 数据保存
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/shengTaiShuiWeiManZhuLv/save`,
      data: params
    })
  }
}

/**
 * @description 管理（保护）范围划定率
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class ManagementScope {
  // 数据保存
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/guanLiFanWeiHuaDingLv/save`,
      data: params
    })
  }
}

//口门畅通率
export class KouMenChangTongLv {
  // 数据保存
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/kouMenChangTongLv/save`,
      data: params
    })
  }

  static find = (id) => {
    return request({
      method: 'get',
      url: `${BASE_URL}/target/kouMenChangTongLv/findById/` + id
    })
  }
}

/**
 * @description 营养状态指数
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class Nutrition {
  // 数据保存
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/yingYangZhuangTaiZhiShu/save`,
      data: params
    })
  }
}

//湖滨带植被覆盖度
export class HuBinDaiZhiBeiFuGaiDu {
  // 数据保存
  static saveInfo = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/huBinDaiZhiBeiFuGaiDu/save`,
      data: params
    })
  }

  static find = (id) => {
    return request({
      method: 'get',
      url: `${BASE_URL}/target/huBinDaiZhiBeiFuGaiDu/findById/` + id
    })
  }

  static deleteDetailById = (id) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/target/huBinDaiZhiBeiFuGaiDu/deleteDetailById/` + id
    })
  }

}
