<template>
  <div class="benthic-page">
    <el-dialog
      v-dialogDrag
      title="底栖生物指数"
      :visible.sync="flag"
      width="948px"
      :before-close="close"
      top="10vh"
      :modal="false"
      :close-on-click-modal="false"
    >
      <div slot="title" class="dialog-header-title">
        <span class="title-name">
          底栖生物指数
          <i class="el-icon-question" @click="openTip('dxswzs')"></i>
        </span>
        <span class="title-score">
          最终得分:
          <span v-if="finalScore">{{ finalScore.toFixed(1) }}</span>
          <span v-else>--</span>
        </span>
      </div>
      <Standrad
        :anchor="anchor"
        :show-flag="indicatorDefinition"
        @changFlag="() => (indicatorDefinition = !indicatorDefinition)"
      />
      <el-tabs v-model="activeName" class="tabs" type="card" @tab-click="handleClick">
        <el-tab-pane label="计算过程" name="first">
          <div class="data-container">
            <div v-for="(domain, index) in data" :key="index">
              <el-row>
                <div>
                  <el-button v-if="index != 0 && !domain.id" type="text" @click="deleteRow(index, data)"
                    >删除{{ domain.id }}</el-button
                  >
                </div>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="8" class="row-lable-name"><div>采样时间:</div> </el-col>
                <el-col :span="10">
                  <el-date-picker
                    v-model="domain.takeDate"
                    type="date"
                    placeholder="选择日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="8" class="row-lable-name"><div>底栖生物 - SW多样性:</div> </el-col>
                <el-col :span="10">
                  <el-input v-model="domain.swValue" :style="{ width: '85%' }" type="number"></el-input>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="8" class="row-lable-name">计算分析说明:</el-col>
                <el-col :span="10">
                  <el-input
                    v-model="domain.swAnalysisDesc"
                    type="textarea"
                    placeholder="请输入计算说明(数据来源、得出此数据的简要说明)"
                    :autosize="{ minRows: 2, maxRows: 2 }"
                    :style="{ width: '85%' }"
                  ></el-input>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="8" class="row-lable-name"><div>寡毛类 - OI指数:</div> </el-col>
                <el-col :span="10">
                  <el-input v-model="domain.oiValue" :style="{ width: '85%' }" type="number"></el-input>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="8" class="row-lable-name">计算分析说明:</el-col>
                <el-col :span="10">
                  <el-input
                    v-model="domain.oiAnalysisDesc"
                    type="textarea"
                    placeholder="请输入计算说明(数据来源、得出此数据的简要说明)"
                    :autosize="{ minRows: 2, maxRows: 2 }"
                    :style="{ width: '85%' }"
                  ></el-input>
                </el-col>
              </el-row>
              <el-row>
                <el-table
                  :data="domain.detailList"
                  :border="true"
                  style="width: 100%; font-size: 12px; text-align: center"
                  header-cell-class-name="table-header-cls"
                >
                  <el-table-column prop="site" label="监测点" align="center" header-align="center">
                    <template v-slot="scope">
                      <div>{{ formmatter(scope.row.siteId) }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="xianChongNum" label="线虫动物(个/m³)" align="center" header-align="center">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.xianChongNum" type="number"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="haunJieNum" label="环节动物门(个/m³)" align="center" header-align="center">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.haunJieNum" type="number"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="ruanTiNum" label="软体动物门(个/m³)" align="center" header-align="center">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.ruanTiNum" type="number"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="jieZhiNum" label="节支动物门(个/m³)" align="center" header-align="center">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.jieZhiNum" type="number"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="biomassNum" label="生物量(个/m³)" align="center" header-align="center">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.biomassNum" type="number"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="biomassWeight" label="生物量(g/m³)" align="center" header-align="center">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.biomassWeight" type="number"></el-input>
                    </template>
                  </el-table-column>
                </el-table>
              </el-row>
            </div>
            <el-row>
              <div class="add-box" @click="addNew">
                <span><el-icon class="el-icon-plus" style="margin-right: 8px"></el-icon>添加</span>
              </div>
            </el-row>

            <el-row type="flex">
              <el-col :span="6" class="row-lable-name">附件:</el-col>
              <el-col :span="16">
                <MyUploadFile ref="uploadfile" :query-ext="queryExt" :attachs="attachs" />
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane label="统计结果" name="second">
          <el-row>
            <el-table
              :data="totalData"
              style="width: 100%; font-size: 12px; text-align: center"
              :border="true"
              header-cell-class-name="table-header-cls"
            >
              <el-table-column prop="takeDate" label="时间" align="center" header-align="center"> </el-table-column>
              <el-table-column
                prop="xianChongNum"
                label="线虫动物(个/m³)"
                align="center"
                header-align="center"
                width="80"
              >
              </el-table-column>
              <el-table-column
                prop="haunJieNum"
                label="环节动物门(个/m³)"
                align="center"
                header-align="center"
                width="80"
              >
              </el-table-column>
              <el-table-column
                prop="ruanTiNum"
                label="软体动物门(个/m³)"
                align="center"
                header-align="center"
                width="80"
              >
              </el-table-column>
              <el-table-column
                prop="jieZhiNum"
                label="节支动物门(个/m³)"
                align="center"
                header-align="center"
                width="80"
              >
              </el-table-column>
              <el-table-column prop="biomassNum" label="生物量(个/m³)" align="center" header-align="center" width="60">
              </el-table-column>
              <el-table-column
                prop="biomassWeight"
                label="生物量(g/m³)"
                align="center"
                header-align="center"
                width="60"
              >
              </el-table-column>
              <el-table-column prop="swValue" label="SW" align="center" header-align="center"> </el-table-column>
              <el-table-column prop="oiValue" label="OI" align="center" header-align="center"> </el-table-column>
              <el-table-column prop="benthic" label="Rz" align="center" header-align="center"> </el-table-column>
            </el-table>
          </el-row>
          <!-- <el-row>
            <el-col>最终得分:{{ finalScore }}</el-col>
          </el-row> -->
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button class="btn-info" @click="close">取 消</el-button>
        <el-button class="btn-submit" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { Benthic } from '@/api/target/riverTarget'
  export default {
    // 底栖生物指数
    name: 'Benthic',
    components: {},
    props: {
      schemeId: {
        type: Number,
        default: 1
      },
      targetData: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        flag: true,
        activeName: 'first',

        // 计算过程单次采样数据（总）
        data: [],
        // 统计结果数据
        totalData: [],
        // 年度平均得分
        finalScore: 0,

        // 站点表格数据
        siteList: [],

        id: null,
        queryExt: {},
        attachs: '',

        indicatorDefinition: false,
        anchor: ''
      }
    },
    computed: {},
    watch: {
      targetData: {
        immediate: true,
        handler(val, oldval) {
          if (JSON.stringify(val) !== '{}') {
            this.createData()
            this.loadData(val)
          } else {
            this.createData()
            this.initData()
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description 指标描述展示
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      openTip(val) {
        this.anchor = val
        this.indicatorDefinition = true
      },
      /**
       * @description 数据初始化加载
       * @author hu_ty
       * @since
       * @param {object} data 有数据的指标信息内容
       *
       */
      loadData(data) {
        // console.log('<== data ==>', data)
        data.detailList.forEach((detail) => {
          this.siteList.forEach((site) => {
            const i = detail.detailList.findIndex((i) => {
              return i.siteId == site.siteId
            })
            if (i == -1) {
              detail.detailList.push(site)
            }
          })
        })
        this.data = data.detailList

        this.finalScore = data.scoreVal
        this.attachs = data.attachs
        this.queryExt = data.queryExt
        this.id = data.id
      },
      /**
       * @description  创建新增的表单数据
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      createData() {
        this.siteInfo = JSON.parse(sessionStorage.siteInfo)
        console.log('111', this.siteInfo)
        this.siteList = []
        this.siteInfo.forEach((item) => {
          let param = {
            site: item.name,
            siteId: item.id,
            xianChongNum: null,
            haunJieNum: null,
            ruanTiNum: null,
            jieZhiNum: null,
            biomassNum: null,
            biomassWeight: null
          }
          this.siteList.push(param)
        })
      },
      initData() {
        let param = {
          takeDate: '',
          swValue: null,
          swAnalysisDesc: '',
          oiValue: null,
          oiAnalysisDesc: '',
          detailList: JSON.parse(JSON.stringify(this.siteList))
        }
        this.data.push(param)
        console.log('<== init ==>', this.initData, this.schemeId)
      },
      /**
       * @description 站点信息格式化
       * @author hu_ty
       * @since
       * @param {number} id 站点id
       *
       */
      formmatter(id) {
        const site = this.siteList.find((i) => {
          return i.siteId == id
        })
        return site?.site
      },
      /**
       * @description  关闭组件并改变父级 targetFlag 值
       * @author hu_ty
       * @since 22-06-24
       * @param {*}
       *
       */
      close() {
        this.$emit('changeFlag')
      },
      /**
       * @description tab 页签切换功能和回调函数
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      handleClick(tab, event) {
        // console.log(tab, event)
        if (tab.label === '统计结果') {
          this.alculateResults()
        }
      },
      /**
       * @description  新增计算过程数据
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      addNew() {
        let newData = {
          takeDate: '',
          swValue: null,
          swAnalysisDesc: '',
          oiValue: null,
          oiAnalysisDesc: '',
          detailList: JSON.parse(JSON.stringify(this.siteList)),
          key: new Date()
        }
        this.data.push(newData)
      },
      /**
       * @description  删除数据
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      deleteRow(index, data) {
        data.splice(index, 1)
      },
      /**
       * @description  统计结果计算
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      alculateResults() {
        this.totalData = []
        this.data.forEach((item) => {
          let xianChongNum = 0
          let haunJieNum = 0
          let ruanTiNum = 0
          let jieZhiNum = 0
          let biomassNum = 0
          let biomassWeight = 0
          let benthic = (0.7 * (item.swValue ? item.swValue : 0) + 0.3 * (item.oiValue ? item.oiValue : 0)).toFixed(1)
          let length = item.detailList.length
          item.detailList.forEach((site) => {
            xianChongNum += parseInt(site.xianChongNum ? site.xianChongNum : 0)
            haunJieNum += parseInt(site.haunJieNum ? site.haunJieNum : 0)
            ruanTiNum += parseInt(site.ruanTiNum ? site.ruanTiNum : 0)
            jieZhiNum += parseInt(site.jieZhiNum ? site.jieZhiNum : 0)
            biomassNum += parseInt(site.biomassNum ? site.biomassNum : 0)
            biomassWeight += parseInt(site.biomassWeight ? site.biomassWeight : 0)
          })
          let param = {
            xianChongNum: Math.round(xianChongNum / length),
            haunJieNum: Math.round(haunJieNum / length),
            ruanTiNum: Math.round(ruanTiNum / length),
            jieZhiNum: Math.round(jieZhiNum / length),
            biomassNum: Math.round(biomassNum / length),
            biomassWeight: Math.round(biomassWeight / length),
            takeDate: item.takeDate,
            swValue: item.swValue,
            oiValue: item.oiValue,
            benthic: benthic
          }

          const i = this.totalData.findIndex((i) => {
            return i.takeDate === item.takeDate
          })
          if (i > -1) {
            this.totalData.splice(i, 1)
          }
          this.totalData.push(param)
        })
        this.alculateScore()
      },
      /**
       * @description 计算全年得分
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      alculateScore() {
        let score = 0
        let length = this.totalData.length
        this.totalData.forEach((item) => {
          let swValue = item.swValue
          let oliochaeteTotal = item.oiValue
          let varietyScore
          let oliochaeteScore
          switch (true) {
            case swValue >= 4:
              varietyScore = 100
              break
            case swValue >= 3:
              varietyScore = 80 + ((swValue - 3) / 1) * 10
              break
            case swValue >= 2:
              varietyScore = 60 + ((swValue - 2) / 1) * 20
              break
            case swValue >= 1:
              varietyScore = 40 + ((swValue - 1) / 1) * 20
              break
            case swValue >= 0:
              varietyScore = (swValue / 1) * 40
              break
            default:
          }
          switch (true) {
            case oliochaeteTotal < 0.4:
              oliochaeteScore = 100 - (oliochaeteTotal / 0.4) * 10
              break
            case oliochaeteTotal < 0.6:
              oliochaeteScore = 90 - ((oliochaeteTotal - 0.4) / 0.2) * 10
              break
            case oliochaeteTotal < 0.7:
              oliochaeteScore = 80 - ((oliochaeteTotal - 0.6) / 0.1) * 20
              break
            case oliochaeteTotal < 0.8:
              oliochaeteScore = 60 - ((oliochaeteTotal - 0.7) / 0.1) * 20
              break
            case oliochaeteTotal <= 1:
              oliochaeteScore = 40 - ((oliochaeteTotal - 0.8) / 0.2) * 40
              break
            case oliochaeteTotal > 1:
              oliochaeteScore = 0
              break
            default:
          }
          console.log('<==  ==>', varietyScore, oliochaeteScore)
          score += varietyScore * 0.7 + oliochaeteScore * 0.3
        })
        this.finalScore = score / length

        // let varietyTotal = 0
        // let oliochaeteTotal = 0
        // let swValue = 0
        // let oiValue = 0
        // let length = this.totalData.length
        // this.totalData.forEach((item) => {
        //   varietyTotal += parseInt(item.swValue ? item.swValue : 0)
        //   oliochaeteTotal += parseInt(item.oiValue ? item.oiValue : 0)
        // })
        // swValue = Math.round(varietyTotal / length)
        // oliochaeteTotal = Math.round(oliochaeteTotal / length)
        // console.log('<==  ==>', swValue, oliochaeteTotal)
        // let varietyScore
        // let oliochaeteScore
        // switch (true) {
        //   case swValue >= 4:
        //     varietyScore = 100
        //     break
        //   case swValue >= 3:
        //     varietyScore = 80 + ((swValue - 3) / 1) * 10
        //     break
        //   case swValue >= 2:
        //     varietyScore = 60 + ((swValue - 2) / 1) * 20
        //     break
        //   case swValue >= 1:
        //     varietyScore = 40 + ((swValue - 1) / 1) * 20
        //     break
        //   case swValue >= 0:
        //     varietyScore = (swValue / 1) * 40
        //     break
        //   default:
        // }
        // switch (true) {
        //   case oliochaeteTotal < 0.4:
        //     oliochaeteScore = 100 - (oliochaeteTotal / 0.4) * 10
        //     break
        //   case oliochaeteTotal < 0.6:
        //     oliochaeteScore = 90 - ((oliochaeteTotal - 0.4) / 0.2) * 10
        //     break
        //   case oliochaeteTotal < 0.7:
        //     oliochaeteScore = 80 - ((oliochaeteTotal - 0.6) / 0.1) * 20
        //     break
        //   case oliochaeteTotal < 0.8:
        //     oliochaeteScore = 60 - ((oliochaeteTotal - 0.7) / 0.1) * 20
        //     break
        //   case oliochaeteTotal <= 1:
        //     oliochaeteScore = 40 - ((oliochaeteTotal - 0.8) / 0.2) * 40
        //     break
        //   case oliochaeteTotal > 1:
        //     oliochaeteScore = 0
        //     break
        //   default:
        // }
        // console.log('<==  ==>', varietyScore, oliochaeteScore)
        // this.finalScore = varietyScore * 0.7 + oliochaeteScore * 0.3
      },
      /**
       * @description  数据保存
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      submit() {
        this.attachs = this.$refs.uploadfile.getAttachs()
        let param = {
          id: this.id,
          attachs: this.attachs,
          schemeId: this.schemeId,
          detailList: this.data
        }

        Benthic.saveInfo(param).then((res) => {
          this.close()
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .benthic-page {
    .el-row {
      margin-bottom: 16px;
    }
  }
  .data-container {
    max-height: 600px;
    overflow: auto;
  }

  /deep/ .el-table__row input {
    border: 0;
  }
</style>
