<template>
  <div class="phytoplankton-page">
    <el-dialog
      title="浮游植物生物指数"
      :visible.sync="flag"
      width="468px"
      :before-close="close"
      :modal="false"
      :close-on-click-modal="false"
    >
      <div slot="title" class="dialog-header-title">
        <span class="title-name">
          浮游植物生物指数
          <i class="el-icon-question" @click="openTip('fyzwswzs')"></i>
        </span>
        <span class="title-score">
          最终得分:
          <span v-if="finalScore">{{ finalScore.toFixed(1) }}</span>
          <span v-else>--</span>
        </span>
      </div>
      <Standrad
        :anchor="anchor"
        :show-flag="indicatorDefinition"
        @changFlag="() => (indicatorDefinition = !indicatorDefinition)"
      />
      <div class="info-container">
        <div v-for="(domain, index) in data" :key="index">
          <el-row>
            <div>
              <el-button v-if="index != 0 && !domain.id" @click="deleteRow(index, data)">删除</el-button>
            </div>
          </el-row>
          <el-row type="flex" align="middle">
            <el-col :span="8" class="row-lable-name"><div>采样时间:</div> </el-col>
            <el-col :span="4">
              <el-date-picker
                v-model="domain.month"
                type="month"
                placeholder="选择日期"
                format="yyyy-MM"
                value-format="yyyy-MM"
              >
              </el-date-picker>
            </el-col>
          </el-row>
          <el-row>
            <el-table
              :data="domain.tableData"
              style="width: 100%; font-size: 12px; text-align: center"
              header-cell-class-name="table-header-cls"
              :border="true"
            >
              <el-table-column prop="site" label="监测点" align="center" header-align="center"> </el-table-column>
              <el-table-column prop="np" label="总细胞数" align="center" header-align="center">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.np" type="number"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="npDesc" label="分析说明" align="center" header-align="center">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.npDesc" type="text"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="sp" label="浮游植物总属数" align="center" header-align="center">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.sp" type="number"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="spDesc" label="分析说明" align="center" header-align="center">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.spDesc" type="text"></el-input>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </div>
        <el-row>
          <div class="add-box" @click="addNew">
            <span><el-icon class="el-icon-plus" style="margin-right: 8px"></el-icon>添加</span>
          </div>
        </el-row>

        <!-- <div v-for="(site, index) in siteList" :key="index">
          <el-row>
            <el-col :span="8" :offset="8">
              <div class="site-name">{{ site.name }}</div>
            </el-col>
          </el-row>

          <el-row type="flex" align="middle">
            <el-col :span="8" class="row-lable-name">
              <div>
                丰富度指数
                <el-tooltip content="生物量和多样性指数" placement="top">
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </div>
            </el-col>
            <el-col :span="4">
              <el-input v-model="site.richness" :style="{ width: '80%' }" type="number"></el-input>&nbsp;
            </el-col>
          </el-row>
          <el-row type="flex" align="middle"
            >计算分析说明
            <el-col :span="8" class="row-lable-name"></el-col>
            <el-col :span="12">
              <el-input
                v-model="site.richnessExplain"
                type="textarea"
                placeholder="请输入计算说明(数据来源、得出此数据的简要说明)"
                :autosize="{ minRows: 4, maxRows: 4 }"
              ></el-input>
            </el-col>
          </el-row>
          <el-row type="flex" align="middle">
            <el-col :span="8" class="row-lable-name"><div>浮游植物总属数</div> </el-col>
            <el-col :span="4">
              <el-input v-model="site.genera" :style="{ width: '80%' }" type="number"></el-input>&nbsp;
            </el-col>
          </el-row>
          <el-row type="flex" align="middle">
            <el-col :span="8" class="row-lable-name">计算分析说明</el-col>
            <el-col :span="12">
              <el-input
                v-model="site.generaExplain"
                type="textarea"
                placeholder="请输入计算说明(数据来源、得出此数据的简要说明)"
                :autosize="{ minRows: 4, maxRows: 4 }"
              ></el-input>
            </el-col>
          </el-row>
        </div> -->
        <!-- todo -->
        <el-row type="flex">
          <el-col :span="8" class="row-lable-name">附件:</el-col>
          <el-col :span="16">
            <MyUploadFile ref="uploadfile" :query-ext="queryExt" :attachs="attachs" />
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button class="btn-info" @click="close">取 消</el-button>
        <el-button class="btn-submit" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { Phytoplankton } from '@/api/target/riverTarget.js'

  export default {
    // 浮游植物生物指数
    name: 'Phytoplankton',
    components: {},
    props: {
      schemeId: {
        type: Number,
        default: 1
      },
      targetData: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        flag: true,

        finalScore: null,

        siteInfo: JSON.parse(sessionStorage.siteInfo),
        siteList: [],
        // 计算过程单次采样数据（总）
        data: [
          {
            month: '',
            tableData: []
          }
        ],

        queryExt: {},
        attachs: '',
        id: null,

        indicatorDefinition: false,
        anchor: ''
      }
    },
    computed: {},
    watch: {
      targetData: {
        immediate: true,
        handler(val, oldval) {
          console.log('<==  ==>', val)
          if (JSON.stringify(val) !== '{}') {
            this.createData()
            this.loadData(val)
          } else {
            this.createData()
            this.initData()
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description 指标描述展示
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      openTip(val) {
        this.anchor = val
        this.indicatorDefinition = true
      },
      /**
       * @description 数据初始化加载
       * @author hu_ty
       * @since
       * @param {object} data 有数据的指标信息内容
       *
       */
      loadData(data) {
        let arr = data.detailList
        // 1.找出采样的月份信息进行数据分类第一步准备
        let month = []
        arr.forEach((item) => {
          month.push(item.month)
          const i = this.siteInfo.find((i) => {
            return i.id == item.siteId
          })
          if (i) {
            item.site = i.name
          }
        })
        let newMonth = [...new Set(month)]

        // 2.依照采样月份信息将数据分组
        this.data = []
        newMonth.forEach((mon) => {
          let param = {
            month: mon,
            tableData: []
          }
          arr.forEach((item) => {
            if (item.month === mon) {
              param.tableData.push(item)
            }
          })
          this.data.push(param)
        })

        this.finalScore = data.scoreVal
        console.log('<== 111 ==>', this.finalScore)
        this.attachs = data.attachs
        this.queryExt = data.queryExt
        this.id = data.id
      },
      createData() {
        this.siteList = []
        if (this.siteInfo.length > 0) {
          this.siteInfo.forEach((item) => {
            let param = {
              site: item.name,
              siteId: item.id,
              np: null,
              npDesc: null,
              sp: null,
              spDesc: null,
              month: ''
            }
            this.siteList.push(param)
          })
        }
      },
      initData() {
        this.data[0].tableData = JSON.parse(JSON.stringify(this.siteList))
      },
      /**
       * @description  关闭组件并改变父级 targetFlag 值
       * @author hu_ty
       * @since 22-06-24
       * @param {*}
       *
       */
      close() {
        this.$emit('changeFlag')
      },
      /**
       * @description  新增计算过程数据
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      addNew() {
        let newData = {
          month: '',
          tableData: JSON.parse(JSON.stringify(this.siteList)),
          key: new Date()
        }
        this.data.push(newData)
      },
      /**
       * @description  删除数据
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      deleteRow(index, data) {
        data.splice(index, 1)
      },
      /**
       * @description
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      submit() {
        this.attachs = this.$refs.uploadfile.getAttachs()
        // console.log('<== submit ==>', this.data)
        let arr = []
        this.data.forEach((item) => {
          item.tableData.forEach((td) => {
            td.month = item.month
            arr.push(td)
          })
          // arr.push(item.tableData)
        })

        let param = {
          id: this.id,
          schemeId: this.schemeId,
          attachs: this.attachs,
          detailList: arr
        }
        console.log('<== param  ==>', param)
        Phytoplankton.saveInfo(param).then((res) => {
          console.log('<==  ==>', res)
          this.close()
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  // .phytoplankton-page {
  // }
  .info-container {
    max-height: 600px;
    overflow: auto;
    .el-row {
      margin-bottom: 16px;
      .site-name {
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
</style>
