<template>
  <div>
    <el-dialog
      title="生态用水满足程度"
      :visible.sync="flag"
      width="594px"
      :before-close="close"
      top="66px"
      :modal="false"
      :close-on-click-modal="false"
    >
      <div slot="title" class="dialog-header-title">
        <span class="title-name">
          生态用水满足程度
          <i class="el-icon-question" @click="openTip('stysmzcd')"></i>
        </span>
        <span class="title-score">
          最终得分:
          <span v-if="finalScore">{{ finalScore }}</span>
          <span v-else>--</span>分
        </span>
      </div>
      <Standrad
        :anchor="anchor"
        :show-flag="indicatorDefinition"
        @changFlag="() => (indicatorDefinition = !indicatorDefinition)"
      />
      <div class="traffic-box">
        <div class="title-box">
          <div class="name">
            <div class="point"></div>
            生态流量计算法
          </div>
          <div class="title-score">
            综合得分: <span v-if="trafficScore" class="score">{{ trafficScore.toFixed(1) }}</span>
            <span v-else class="score">--</span> 分
          </div>
        </div>
        <div class="traffic-info">
          <el-table
            :data="trafficTableData"
            stripe
            style="width: 100%; font-size: 12px; text-align: center"
            header-cell-class-name="table-header-cls"
          >
            <el-table-column prop="time" label="时段" align="center" header-align="center"> </el-table-column>
            <el-table-column prop="mini" label="最低值(m)" align="center" header-align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.mini" type="number" @blur="changeTraffic(scope.row)"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="average" label="平均值(m)" align="center" header-align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.average" type="number" @blur="changeTraffic(scope.row)"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="percent" label="占比" align="center" header-align="center"> </el-table-column>
            <el-table-column prop="score" label="得分" align="center" header-align="center"> </el-table-column>
          </el-table>
          <el-row type="flex" align="middle">
            <el-col :span="8" class="row-lable-name">计算分析说明:</el-col>
            <el-col :span="16">
              <el-input
                v-model="flowCalculateDesc"
                type="textarea"
                placeholder="请输入计算说明(数据来源、得出此数据的简要说明)"
                :autosize="{ minRows: 2, maxRows: 2 }"
              ></el-input>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="water-level">
        <div class="title-box">
          <div class="name">
            <div class="point"></div>
            生态水位计算法
          </div>
          <div class="title-score">
            综合得分: <span v-if="waterLevelScore" class="score">{{ waterLevelScore.toFixed(1) }}</span>
            <span v-else class="score">--</span> 分
          </div>
        </div>
        <el-row type="flex" align="middle">
          <el-col :span="8" class="row-lable-name"><div>生态水位:</div> </el-col>
          <el-col :span="10">
            <el-input v-model="waterLevel" :style="{ width: '40%' }" type="number"></el-input>&nbsp;m
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="8" class="row-lable-name">年逐日水位高于生态水位:</el-col>
          <el-col :span="10">
            <el-input
              v-model="waterLevelOkDays"
              type="number"
              :style="{ width: '40%' }"
              @blur="countWaterLevelScore"
            ></el-input
            >&nbsp;天
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="8" class="row-lable-name">计算分析说明:</el-col>
          <el-col :span="16">
            <el-input
              v-model="waterLevelCalculateDesc"
              type="textarea"
              placeholder="请输入计算说明(数据来源、得出此数据的简要说明)"
              :autosize="{ minRows: 2, maxRows: 2 }"
            ></el-input>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="8" class="row-lable-name">附件:</el-col>
          <el-col :span="16">
            <MyUploadFile ref="uploadfile" :query-ext="queryExt" :attachs="attachs" />
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { EcologicalWater } from '@/api/target/riverTarget.js'
  export default {
    // 生态用水满足程度
    name: 'EcologicalWater',
    props: {
      schemeId: {
        type: Number,
        default: 1
      },
      targetData: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        flag: true,

        id: null,
        trafficScore: 0,
        trafficTableData: [
          {
            time: '4月-9月',
            mini: null,
            average: null,
            percent: null,
            score: null
          },
          {
            time: '10月-次年3月',
            mini: null,
            average: null,
            percent: null,
            score: null
          }
        ],
        flowCalculateDesc: '',

        waterLevel: 0,
        waterLevelOkDays: 0,
        waterLevelScore: 0,
        rating: '',
        waterLevelCalculateDesc: '',
        attachs: '',
        queryExt: {},

        indicatorDefinition: false,
        anchor: ''
      }
    },
    computed: {
      finalScore() {
        let final
        let score
        if (this.waterLevelScore && this.trafficScore) {
          score =
            this.trafficScore - this.waterLevelScore <= 0
              ? this.trafficScore.toFixed(1)
              : this.waterLevelScore.toFixed(1)
        }
        switch (score >= 0) {
          case score >= 95:
            final = 90 + ((score - 95) / 5) * 10
            break
          case score >= 90:
            final = 80 + ((score - 90) / 5) * 10
            break
          case score >= 80:
            final = 60 + ((score - 80) / 10) * 20
            break
          case score >= 60:
            final = 40 + ((score - 60) / 20) * 20
            break
          case score >= 0:
            final = (score / 60) * 40
            break
        }
        return final.toFixed(1)
      }
    },
    watch: {
      targetData: {
        immediate: true,
        handler(val, oldval) {
          if (val != {}) {
            this.loadData(val)
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description 指标描述展示
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      openTip(val) {
        this.anchor = val
        this.indicatorDefinition = true
      },
      /**
       * @description
       * @author hu_ty
       * @since
       * @param {object} data 指标数据内容
       *
       */
      loadData(data) {
        this.id = data.id
        this.flowCalculateDesc = data.flowCalculateDesc
        this.waterLevel = data.waterLevel
        this.waterLevelOkDays = data.waterLevelOkDays
        this.waterLevelScore = data.waterLevelScore
        this.waterLevelCalculateDesc = data.waterLevelCalculateDesc
        this.attachs = data.attachs
        this.waterLevelScore = data.swScore
        this.queryExt = data.queryExt
        this.trafficScore =
          data.fourToNineScore - data.tenToThreeScore > 0 ? data.tenToThreeScore : data.fourToNineScore
        let param = [
          {
            time: '4月-9月',
            mini: data.fourToNineLow,
            average: data.fourToNineAvg,
            percent: ((data.fourToNineLow / data.fourToNineAvg) * 100).toFixed(1) + '%',
            score: data.fourToNineScore
          },
          {
            time: '10月-次年3月',
            mini: data.tenToThreeLow,
            average: data.tenToThreeAvg,
            percent: ((data.tenToThreeLow / data.tenToThreeAvg) * 100).toFixed(1) + '%',
            score: data.tenToThreeScore
          }
        ]
        this.trafficTableData = param
      },
      /**
       * @description  生态流量计算法数值填报之后内容计算
       * @author hu_ty
       * @since 22-06-24
       * @param {*}
       *
       */
      changeTraffic(data) {
        if (data.mini > 0 && data.average > 0) {
          let per = (data.mini / data.average).toFixed(1) * 100
          let score = 0
          data.percent = (data.mini / data.average).toFixed(1) * 100 + '%'
          if (data.time.includes('4')) {
            switch (true) {
              case per >= 50:
                score = 100
                break
              case per >= 40:
                score = 80 + ((per - 40) / 10) * 20
                break
              case per >= 30:
                score = 40 + ((per - 30) / 10) * 40
                break
              case per >= 10:
                score = 20 + ((per - 10) / 20) * 20
                break
              case per >= 0:
                score = (per / 10) * 10
                break
              default:
            }
            data.score = score
          } else {
            switch (true) {
              case per >= 30:
                score = 100
                break
              case per >= 20:
                score = 80 + ((per - 20) / 10) * 20
                break
              case per >= 10:
                score = 40 + ((per - 10) / 10) * 40
                break
              case per >= 0:
                score = (per / 10) * 40
                break
              default:
            }
            data.score = score
          }
        }
        this.trafficScore = 0
        this.trafficTableData.forEach((item) => {
          if (item.score) {
            if (this.trafficScore != 0) {
              this.trafficScore = this.trafficScore - item.score > 0 ? item.score : this.trafficScore
            } else {
              this.trafficScore = item.score
            }
          }
        })
      },
      /**
       * @description  生态水位计算法数值填报之后内容计算
       * @author hu_ty
       * @since 22-06-24
       * @param {*}
       *
       */
      countWaterLevelScore() {
        let score = (this.waterLevelOkDays / 365) * 100
        this.waterLevelScore = score
      },
      /**
       * @description  关闭组件并改变父级 targetFlag 值
       * @author hu_ty
       * @since 22-06-24
       * @param {*}
       *
       */
      close() {
        this.$emit('changeFlag')
      },
      /**
       * @description 数据保存
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      submit() {
        // 获取文件列表信息
        this.attachs = this.$refs.uploadfile.getAttachs()

        // 处理数据，将表格数据处理为单一对应字段数据
        const fourNine = this.trafficTableData.find((i) => {
          return i.time.includes('4')
        })
        const tenThree = this.trafficTableData.find((i) => {
          return i.time.includes('3')
        })
        let param = {
          id: this.id,
          schemeId: this.schemeId,
          fourToNineAvg: fourNine.average,
          fourToNineLow: fourNine.mini,
          tenToThreeAvg: tenThree.average,
          tenToThreeLow: tenThree.mini,
          waterLevel: this.waterLevel,
          waterLevelOkDays: this.waterLevelOkDays,
          attachs: this.attachs,
          scoreVal: this.finalScore,
          waterLevelCalculateDesc: this.waterLevelCalculateDesc,
          flowCalculateDesc: this.flowCalculateDesc
        }

        EcologicalWater.saveInfo(param)
          .then((res) => {
            console.log('<== res ==>', res)
            this.close()
          })
          .catch((err) => {
            console.log('<==  ==>', err)
          })
      }
    }
  }
</script>
<style lang="less" scoped>
  .water-level,
  .traffic-box {
    // margin: 0 48px;
    .title-box {
      display: flex;
      justify-content: space-between;
      background: linear-gradient(90deg, rgba(64, 158, 255, 0.1) 0%, rgba(64, 158, 255, 0) 91.85%);
      backdrop-filter: blur(33px);
      // margin: 16px 0;
      .name {
        font-family: 'PingFang SC';
        font-weight: 600;
        font-size: 16px;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .score {
        text-align: right;
        color: rgba(64, 158, 255, 1);
        font-family: 'PingFang SC';
        font-weight: 500;
        font-size: 18px;
      }
      .title-score {
        color: rgba(0, 0, 0, 0.6);
        font-family: 'PingFang SC';
        font-size: 12px;
      }
    }
    .el-row {
      margin: 16px 16px 0;
    }
  }
  .traffic-info {
    margin-bottom: 16px;
  }

  .point {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(64, 158, 255, 1) 30%, rgba(64, 158, 255, 0.5) 10%);
    margin-right: 6px;
  }
</style>
