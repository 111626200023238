<template>
  <div>
    <el-dialog
      title="着生藻类生物指数"
      :visible.sync="flag"
      width="30%"
      :before-close="close"
      :modal="false"
      :close-on-click-modal="false"
    >
      <div slot="title" class="dialog-header-title">
        <span class="title-name">
          着生藻类生物指数
          <i class="el-icon-question" @click="openTip('zszlswzs')"></i>
        </span>
        <span class="title-score">
          最终得分:
          <span v-if="finalScore">{{ finalScore.toFixed(1) }}</span>
          <span v-else>--</span>
        </span>
      </div>
      <Standrad
        :anchor="anchor"
        :show-flag="indicatorDefinition"
        @changFlag="() => (indicatorDefinition = !indicatorDefinition)"
      />
      <div class="body-content">
        <div v-for="(domain, index) in data" :key="index">
          <el-row>
            <div>
              <el-button v-if="index != 0 && !domain.id" type="text" @click="deleteRow(index, data)"
                >删除{{ domain.id }}</el-button
              >
            </div>
          </el-row>
          <el-row type="flex" align="middle">
            <el-col :span="8" class="row-lable-name"><div>多样性指数:</div> </el-col>
            <el-col :span="8">
              <el-input v-model="domain.diversityValue" type="number" style="width: 80%" @blur="count"></el-input>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="8" class="row-lable-name">计算分析说明:</el-col>
            <el-col :span="16">
              <el-input
                v-model="domain.analysisDesc"
                type="textarea"
                placeholder="请输入计算说明(数据来源、得出此数据的简要说明)"
                :autosize="{ minRows: 2, maxRows: 2 }"
              ></el-input>
            </el-col>
          </el-row>
        </div>
        <el-row>
          <div class="add-box" @click="addNew">
            <span><el-icon class="el-icon-plus" style="margin-right: 8px"></el-icon>添加</span>
          </div>
        </el-row>
        <el-row type="flex">
          <el-col :span="8" class="row-lable-name">附件:</el-col>
          <el-col :span="16">
            <MyUploadFile ref="uploadfile" :query-ext="queryExt" :attachs="attachs" />
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button class="btn-info" @click="close">取 消</el-button>
        <el-button class="btn-submit" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { BorneAlgae } from '@/api/target/riverTarget.js'
  export default {
    // 着生藻类生物指数
    name: 'BorneAlgae',
    components: {},
    props: {
      schemeId: {
        type: Number,
        default: 1
      },
      targetData: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        flag: true,

        finalScore: null,

        data: [
          {
            diversityValue: null,
            analysisDesc: ''
          }
        ],

        queryExt: {},
        attachs: '',
        id: null,

        indicatorDefinition: false,
        anchor: ''
      }
    },
    computed: {},
    watch: {
      targetData: {
        immediate: true,
        handler(val, oldval) {
          if (JSON.stringify(val) !== '{}') {
            this.loadData(val)
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description 指标描述展示
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      openTip(val) {
        this.anchor = val
        this.indicatorDefinition = true
      },
      /**
       * @description 数据初始化加载
       * @author hu_ty
       * @since
       * @param {object} data 有数据的指标信息内容
       *
       */
      loadData(data) {
        console.log('<== loadData ==>', data)
        this.data = data.detailList
        this.finalScore = data.scoreVal
        this.attachs = data.attachs
        this.queryExt = data.queryExt
        this.id = data.id
      },
      /**
       * @description  关闭组件并改变父级 targetFlag 值
       * @author hu_ty
       * @since 22-06-24
       * @param {*}
       *
       */
      close() {
        this.$emit('changeFlag')
      },
      /**
       * @description  新增计算过程数据
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      addNew() {
        let newData = {
          diversityValue: null,
          analysisDesc: '',
          key: new Date()
        }
        this.data.push(newData)
      },
      /**
       * @description  删除数据
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      deleteRow(index, data) {
        data.splice(index, 1)
      },
      /**
       * @description 计算得分
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      count() {
        let score
        if (this.diversityValue) {
          switch (true) {
            case this.diversityValue >= 4:
              score = 100
              break
            case this.diversityValue >= 3:
              score = 80 + ((this.diversityValue - 3) / 1) * 10
              break
            case this.diversityValue >= 2:
              score = 60 + ((this.diversityValue - 2) / 1) * 20
              break
            case this.diversityValue >= 1:
              score = 40 + ((this.diversityValue - 1) / 10) * 20
              break
            case this.diversityValue >= 0:
              score = (this.diversityValue / 1) * 40
              break
            default:
          }
          this.finalScore = score
        }
      },
      /**
       * @description  数据提交
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      submit() {
        this.attachs = this.$refs.uploadfile.getAttachs()

        let param = {
          schemeId: this.schemeId,
          detailList: this.data,
          scoreVal: this.finalScore,
          attachs: this.attachs,
          id: this.id
        }
        BorneAlgae.saveInfo(param).then((res) => {
          console.log('<==  ==>', res)
          this.close()
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .body-content {
    .el-row {
      margin-bottom: 16px;
    }
  }
</style>
