<template>
  <div>
    <el-dialog
      title="生态水位满足率"
      :visible.sync="flag"
      width="686px"
      :before-close="close"
      :modal="false"
      :close-on-click-modal="false"
    >
      <div slot="title" class="dialog-header-title">
        <span class="title-name">
          生态水位满足率
          <i class="el-icon-question" @click="openTip('stswmzl')"></i>
        </span>
        <span class="title-score">
          最终得分:
          <span v-if="finalScore">{{ finalScore }}</span>
          <span v-else>--</span>
        </span>
      </div>
      <Standrad
        :anchor="anchor"
        :show-flag="indicatorDefinition"
        @changFlag="() => (indicatorDefinition = !indicatorDefinition)"
      />
      <div class="water-level">
        <!-- <el-row type="flex" align="middle">
          <el-col :span="8" class="row-lable-name"><div>生态水位:</div> </el-col>
          <el-col :span="14">
            <el-input v-model="waterLevel" :style="{ width: '40%' }" type="number"></el-input>&nbsp;m
          </el-col>
        </el-row> -->
        <el-row type="flex" align="middle">
          <el-col :span="8" class="row-lable-name">年逐日水位满足目标生态水位:</el-col>
          <el-col :span="14">
            <el-input v-model="tp" type="number" :style="{ width: '40%' }" @blur="countWaterLevelScore"></el-input
            >&nbsp;天
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="8" class="row-lable-name">计算分析说明:</el-col>
          <el-col :span="14">
            <el-input
              v-model="analysisDesc"
              type="textarea"
              placeholder="请输入计算说明(数据来源、得出此数据的简要说明)"
              :autosize="{ minRows: 2, maxRows: 2 }"
            ></el-input>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="8" class="row-lable-name">附件:</el-col>
          <el-col :span="14">
            <MyUploadFile ref="uploadfile" :query-ext="queryExt" :attachs="attachs" />
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button class="btn-info" @click="close">取 消</el-button>
        <el-button class="btn-submit" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { EcologicalWater } from '@/api/target/lakeTarget.js'
  export default {
    // 生态水位满足率
    name: 'EcologicalWater',
    components: {},
    props: {
      schemeId: {
        type: Number,
        default: 1
      },
      targetData: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        flag: true,

        waterLevel: null,
        tp: null,
        waterLevelScore: 0,
        rating: '',
        analysisDesc: '',

        id: null,
        queryExt: {},
        attachs: '',

        indicatorDefinition: false,
        anchor: ''
      }
    },
    computed: {
      finalScore() {
        return this.waterLevelScore.toFixed(1)
      }
    },
    watch: {
      targetData: {
        immediate: true,
        handler(val, oldval) {
          if (JSON.stringify(val) !== '{}') {
            this.loadData(val)
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description 指标描述展示
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      openTip(val) {
        this.anchor = val
        this.indicatorDefinition = true
      },
      /**
       * @description
       * @author hu_ty
       * @since
       * @param {object} data 指标数据内容
       *
       */
      loadData(data) {
        this.id = data.id
        this.waterLevel = data.waterLevel
        this.tp = data.tp
        this.analysisDesc = data.analysisDesc
        this.attachs = data.attachs
        this.waterLevelScore = data.scoreVal
        this.queryExt = data.queryExt
      },
      /**
       * @description  生态水位计算法数值填报之后内容计算
       * @author hu_ty
       * @since 22-06-24
       * @param {*}
       *
       */
      countWaterLevelScore() {
        let score = (parseInt(this.tp) / 365) * 100
        switch (score >= 0) {
          case score > 100:
            this.waterLevelScore = 100
            break
          case score >= 95:
            this.waterLevelScore = 90 + ((score - 95) / 5) * 10
            break
          case score >= 90:
            this.waterLevelScore = 80 + ((score - 90) / 5) * 10
            break
          case score >= 80:
            this.waterLevelScore = 60 + ((score - 80) / 10) * 20
            break
          case score >= 60:
            this.waterLevelScore = 40 + ((score - 60) / 20) * 20
            break
          case score >= 0:
            this.waterLevelScore = (score / 60) * 40
            break
          default:
            this.waterLevelScore = -1
        }
      },
      /**
       * @description  关闭组件并改变父级 targetFlag 值
       * @author hu_ty
       * @since 22-06-24
       * @param {*}
       *
       */
      close() {
        this.$emit('changeFlag')
      },
      /**
       * @description 数据保存
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      submit() {
        this.attachs = this.$refs.uploadfile.getAttachs()

        let param = {
          id: this.id,
          schemeId: this.schemeId,
          attachs: this.attachs,
          waterLevel: this.waterLevel,
          tp: this.tp,
          analysisDesc: this.analysisDesc,
          scoreVal: this.finalScore
        }
        EcologicalWater.saveInfo(param).then((res) => {
          this.close()
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .water-level {
    .el-row {
      margin-bottom: 16px;
    }
  }
</style>
