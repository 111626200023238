<template>
  <div>
    <el-dialog
      title="口门畅通率"
      :visible.sync="flag"
      width="40%"
      :before-close="close"
      :modal="false"
      :close-on-click-modal="false"
    >
      <el-dialog
        v-if="innerVisible"
        width="80%"
        title="口门畅通率"
        :visible.sync="innerVisible"
        append-to-body
        top="2%"
      >
        <IndicatorMap :type="'kouMenChangTongLv'" @saveAddObj="saveAddObj" @cancelAddObj="cancelAddObj"></IndicatorMap>
      </el-dialog>

      <div slot="title" class="dialog-header-title">
        <span class="title-name">
          口门畅通率
          <i class="el-icon-question" @click="openTip('kmctl')"></i>
        </span>
        <span class="title-score">
          最终得分:
          <span v-if="finalScore">{{ finalScore.toFixed(1) }}</span>
          <span v-else>--</span>
        </span>
      </div>

      <el-table
        :data="detailList"
        style="width: 100%; font-size: 12px; text-align: center"
        header-cell-class-name="table-header-cls"
      >
        <el-table-column prop="lonlat" label="地理位置" align="center" header-align="center" width="150">
          <template slot-scope="scope">
            {{ scope.row.lon + ',' + scope.row.lat }}
          </template>
        </el-table-column>

        <el-table-column prop="name" label="名称" align="center" header-align="center" width="200">
          <template slot-scope="scope">
            <el-input v-model="scope.row.name" type="text"></el-input>
          </template>
        </el-table-column>

        <el-table-column prop="develop" label="是否畅通" align="center" header-align="center" width="100">
          <template slot-scope="scope">
            {{ scope.row.openFlag ? '是' : '否' }}
          </template>
        </el-table-column>

        <el-table-column prop="kouMenDesc" label="现场描述" align="center" header-align="center">
          <template slot-scope="scope">
            {{ scope.row.kouMenDesc }}
          </template>
        </el-table-column>

        <el-table-column prop="pics" label="图片" align="center" header-align="center" width="120">
          <template slot-scope="scope">
            <div v-if="scope.row.kouMenPngCodeList && scope.row.kouMenPngCodeList.length > 0">
              <el-image
                style="width: 90px; height: 90px"
                fit="scale-down"
                :src="scope.row.kouMenPngCodeList[0]"
                :preview-src-list="scope.row.kouMenPngCodeList"
              >
              </el-image>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!-- <div class="file-container">
        <el-row type="flex">
          <el-col :span="6" class="row-lable-name">附件:</el-col>
          <el-col :span="16">
            <MyUploadFile ref="uploadfile" :query-ext="queryExt" :attachs="attachs" />
          </el-col>
        </el-row>
      </div> -->

      <el-row>
        <div class="add-box" @click="addNew">
          <span><el-icon class="el-icon-plus" style="margin-right: 8px"></el-icon>添加新数据</span>
        </div>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button class="btn-info" @click="close">取 消</el-button>
        <el-button class="btn-submit" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { KouMenChangTongLv } from '@/api/target/lakeTarget.js'
import { File } from '@/api/file/index.js'
import IndicatorMap from '../indicatorMap.vue'
export default {
  // 水面开发利用率
  name: 'KouMenChangTongLv',
  components: { IndicatorMap },
  props: {
    schemeId: {
      type: Number,
      default: 1
    },
    targetData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      innerVisible: false,

      flag: true,
      detailList: [],
      finalScore: null,
      id: null,

      attachs: '',
      queryExt: {},

      indicatorDefinition: false,
      anchor: ''
    }
  },
  computed: {},
  watch: {
    targetData: {
      immediate: true,
      handler(val, oldval) {
        if (JSON.stringify(val) !== '{}') {
          this.loadData(val)
        }
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    /**
     * @description 指标描述展示
     * @author hu_ty
     * @since
     * @param {*}
     *
     */
    openTip(val) {
      this.anchor = val
      this.indicatorDefinition = true
    },
    /**
     * @description 数据初始化加载
     * @author hu_ty
     * @since
     * @param {object} data 有数据的指标信息内容
     *
     */
    loadData(data) {
      this.finalScore = data.scoreVal
      this.id = data.id

      this.attachs = data.attachs
      this.queryExt = data.queryExt
      this.formmater(data.detailList)
    },

    // 获取图片列表
    async formmater(data) {
      this.detailList = []
      data.forEach(async (item) => {
        item.kouMenPngCodeList = []
        if (item.kouMenPngCode && !item.kouMenPngCode.includes('tmp')) {
          let arr = item.kouMenPngCode.split(',')
          let promise = []
          arr.forEach((code) => {
            let a = File.findUrl(code).then((res) => {
              item.kouMenPngCodeList.push(res.data.data)
            })
            promise.push(a)
          })
          await Promise.all(promise)
        }

        this.detailList.push(item)
        console.log(' this.detailList', this.detailList)
      })
    },
    close() {
      this.$emit('changeFlag')
    },

    addNew() {
      this.innerVisible = true
    },
    // 保存监测点
    saveAddObj(data) {
      let param = {
        schemeId: this.schemeId,
        detailList: [data]
      }
      console.log('param', param)
      KouMenChangTongLv.saveInfo(param).then((res) => {
        KouMenChangTongLv.find(res.data.data).then((res) => {
          this.loadData(res.data.data)
        })
      })
      this.innerVisible = false
    },
    // 取消保存监测点
    cancelAddObj() {
      this.innerVisible = false
    },

    countFinalScore() {},
    /**
     * @description 数据保存
     * @author hu_ty
     * @since
     * @param {*}
     *
     */
    submit() {
      // this.attachs = this.$refs.uploadfile.getAttachs()

      let param = {
        id: this.id,
        schemeId: this.schemeId,
        // attachs: this.attachs,
        detailList: this.detailList
      }

      console.log('param', param)
      KouMenChangTongLv.saveInfo(param).then((res) => {
        this.close()
      })
    }
  }
}
</script>
<style lang="less" scoped>
.file-container {
  margin: 16px;
}
.el-row {
  margin: 16px;
}

/deep/ .el-table__row .cell > .el-input > input {
  border: 0;
}
/deep/ .el-table__row .el-input__inner {
  padding: 0;
  text-align: center;
}
</style>
