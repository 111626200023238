<template>
  <div id="mapIndicator">
    <!-- 地区河流搜索框 -->
    <div id="search">
      <el-autocomplete
        v-model="nameSearch"
        style="width: 300px"
        placeholder="请输入地区名称"
        :fetch-suggestions="querySearch"
        :trigger-on-focus="false"
        clearable
        @select="handleSelect"
      >
        <template slot-scope="{ item }">
          <div>
            <div class="search_title">{{ item.title }}</div>
            <span class="search_addr">{{ item.address }}</span>
          </div>
        </template>
      </el-autocomplete>
    </div>
    <!-- 工具箱 -->
    <div class="toolBox" @click="clickBox">
      <img
        class="toolBoxIcon"
        :src="
          checkedTool ? require('@/assets/icon/map/toolBox_selected.png') : require('@/assets/icon/map/toolBox.png')
        "
        alt
      />
      <div :style="checkedTool ? 'color:blue' : ''">工具箱</div>
    </div>
    <!-- 工具 -->
    <div v-show="checkedTool" class="tools">
      <div class="toolIcon" @click="measure('LineString')">
        <img class="toolBoxIcon" :src="require('@/assets/icon/map/measure_length_selected.png')" alt />
        <div>测距</div>
      </div>
      <div class="toolIcon" @click="measure('Polygon')">
        <img class="toolBoxIcon" :src="require('@/assets/icon/map/measure_area_selected.png')" alt />
        <div>测面</div>
      </div>
      <div class="toolIcon" @click="getLayer(0)">
        <img class="toolBoxIcon" src="@/assets/icon/map/layer.png" alt />
        <div :style="checkedLayer ? 'color:blue' : ''">图层</div>
      </div>
    </div>
    <!-- 图层 -->
    <div v-show="checkedLayer" class="layerTools">
      <div @click="getLayer(1)">矢量图</div>
      <div @click="getLayer(2)">卫星图</div>
    </div>
  </div>
</template>
<script>
import 'ol/ol.css'
import '@/assets/css/map.less'

import { Feature } from 'ol'
import WKT from 'ol/format/WKT'
import VectorLayer from 'ol/layer/Vector'
import Vector from 'ol/source/Vector'
import Point from 'ol/geom/Point'
import { Style, Circle, Fill, Stroke, Text } from 'ol/style'
import { Point as GeomPoint, LineString as GeoLineString, MultiPolygon as GeoMultiPolygon, Polygon } from 'ol/geom'
import { Draw, Modify, Select } from 'ol/interaction'
import { getArea, getLength } from 'ol/sphere'
import { unByKey } from 'ol/Observable'
//Map
import Map from '@/openlayers/map'
import Layers from '@/openlayers/Layers'
import Styles from '@/openlayers/Styles'

//api
import { Place_name_search } from '@/api/map.js'
export default {
  name: '',
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      //地区搜索
      nameSearch: '',
      //地图
      map: null,
      //经纬度
      x: 120.89371,
      y: 31.97958,

      showAddPointFrom: false, //展示新增坐标点
      newPoint: {}, //当前新增标记点对象
      newPoints: [], //新增标记点集合
      pointLayer: null, //新增标记点 图层
      pointsLayer: null, //标记点图层

      showAddLineFrom: false, //展示新增标记线
      newLine: {}, //当前新增标记线对象
      newLines: [], //新增标记线集合
      newLineLayer: null, //新增标记线 图层
      linesLayer: null, //标记线图层

      showAddAreaFrom: false, //展示新增坐标面
      newAreas: [], //新增标记点集合
      newAreaLayer: null, //新增标记面 图层
      areasLayer: null, //标记面图层

      //工具
      checkedTool: false, //工具箱
      checkedLength: false, //测距中
      checkedArea: false, //侧面中
      checkedLayer: false
    }
  },
  computed: {},
  watch: {
    showAddPointFrom: function (newData, oldData) {
      console.log('newData', newData)
      if (newData) {
        this.PointMsg = this.$message({
          message: '地图标记点操作中...',
          center: true,
          type: 'success',
          duration: 0
        })
      } else {
        this.PointMsg.close()
      }
    },
    showAddAreaFrom: function (newData, oldData) {
      if (newData) {
        this.AreaMsg = this.$message({
          message: '地图标记面操作中...',
          center: true,
          type: 'success',
          duration: 0
        })
      } else {
        this.AreaMsg.close()
      }
    },
    showAddLineFrom: function (newData, oldData) {
      if (newData) {
        this.LineMsg = this.$message({
          message: '地图标记线操作中...',
          center: true,
          type: 'success',
          duration: 0
        })
      } else {
        this.LineMsg.close()
      }
    }
  },
  created() {},
  mounted() {
    this.initalMap('mapIndicator')

    let obj = sessionStorage.getItem('currentObj')
    this.obj = JSON.parse(obj)
    //地图初始 展示面
    this.showMarkArea([this.obj.obj])
    //定位
    this.toNewView(this.obj.obj)
  },
  methods: {
    /**
     * 初始化地图、设置中心点坐标
     */
    initalMap(id) {
      //初始化数据
      Map.sendThis(this)
      //初始化图层 天地图-矢量底图和矢量标记图
      const layers = new Layers()
      this.layers = layers.getLayers()
      //创建地图
      Map.createBaseMap(id)
    },

    toNewView(row) {
      console.log('toNewView', row)
      let format = new WKT()
      let feature = format.readFeature(row.geom, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:4326'
      })
      console.log(feature)

      var geo = feature.getGeometry()
      console.log(geo)

      var extent = geo.getExtent()

      console.log('111', this.map.getView().getResolution())

      let resolution = this.map.getView().getResolutionForExtent(extent, this.map.getSize())
      this.map.getView().fit(extent, {
        // duration: 2000, //动画的持续时间,
        // maxZoom: 14
      })
      this.map.getView().setResolution(resolution)
    },

    /**
     * 地图地区搜索
     */
    querySearch(queryString, cb) {
      this.nameSearch = queryString
      Place_name_search(queryString, this.x, this.y).then((res) => {
        if (res.status === 200) {
          cb(res.data.data)
        }
      })
    },
    /**
     * 选择搜索地区，设置新的地图中心点
     */
    handleSelect(item) {
      this.map.getView().animate({
        // 只设置需要的属性即可
        center: [item.location.lng, item.location.lat], // 中心点
        zoom: 15, // 缩放级别
        rotation: undefined, // 缩放完成view视图旋转弧度
        duration: 1000 // 缩放持续时间，默认不需要设置
      })
    },
    /**
     * 测距、侧面
     */
    measure(type) {
      this.checkedTool = false
      if (type === 'LineString') {
        this.checkedArea = false
        this.checkedLength = !this.checkedLength
      } else {
        this.checkedLength = false
        this.checkedArea = !this.checkedArea
      }
      //type：测距、侧面
      //statusFlag：选择操作、取消操作
      Map.measure(type, true)
    },
    //图层
    getLayer(e) {
      if (e === 0) {
        this.checkedLayer = !this.checkedLayer
        return
      }

      this.checkedTool = false
      this.checkedLayer = false

      //矢量图
      if (e === 1) {
        this.layers[0].setVisible(true)
        this.layers[1].setVisible(false)
      }
      //卫星图
      if (e === 2) {
        this.layers[1].setVisible(true)
        this.layers[0].setVisible(false)
      }
    },
    clickBox() {
      this.checkedTool = !this.checkedTool
      if (!this.checkedTool) {
        this.checkedLayer = false
      }
    },

    // =============================下面是地图点、线、面操作方法=====================
    /*
     *
     *          ┌─┐       ┌─┐
     *       ┌──┘ ┴───────┘ ┴──┐
     *       │                 │
     *       │       ───       │
     *       │  ─┬┘       └┬─  │
     *       │                 │
     *       │       ─┴─       │
     *       │                 │
     *       └───┐         ┌───┘
     *           │         │
     *           │         │
     *           │         │
     *           │         └──────────────┐
     *           │                        │
     *           │                        ├─┐
     *           │                        ┌─┘
     *           │                        │
     *           └─┐  ┐  ┌───────┬──┐  ┌──┘
     *             │ ─┤ ─┤       │ ─┤ ─┤
     *             └──┴──┘       └──┴──┘
     *                  神兽
     */

    //点操作 Begin====================================================
    /**
     * 显示标记点
     * 经纬度
     */
    showMarkPoints(data) {
      this.map.removeLayer(this.pointsLayer)
      let pointList = []
      data.forEach((item) => {
        const lon = item.lon
        const lat = item.lat
        const feature = new Feature({
          geometry: new Point([lon, lat])
        })
        feature.setProperties(item)
        // let styleHh = new Style({
        //   image: new Icon({
        //     src: require('@/assets/icon/map/marker_icon.png'),
        //     anchor: [0.5, 1],
        //     fill: new Fill({
        //       color: '#f40'
        //     })
        //   }),
        //   fill: new Fill({
        //     color: '#eee'
        //   }),
        //   text: new Text({
        //     text: '监测点',
        //     font: '14px font-size',
        //     fill: new Fill({
        //       // 设置字体颜色
        //       color: '#000'
        //     }),
        //     offsetY: 10 // 设置文字偏移量
        //   })
        // })

        let styleHh = new Style({
          // 点样式
          image: new Circle({
            // 点半径
            radius: 10,
            // 点的边框，
            stroke: new Stroke({
              color: 'rgba(65, 97, 235,1)',
              width: 1
            }),
            // 缩放比
            scale: 1,
            // 填充色
            fill: new Fill({
              color: 'rgba(5, 97, 235,0.6)'
            })
          }),
          text: new Text({
            text: item.name,
            font: '14px font-size',
            fill: new Fill({
              // 设置字体颜色
              color: '#000'
            }),
            offsetY: 18 // 设置文字偏移量
          })
        })
        feature.setStyle(styleHh)
        pointList.push(feature)
      })
      this.pointsLayer = new VectorLayer({
        source: new Vector({
          features: [...pointList]
        })
      })
      this.pointsLayer.setZIndex(4)
      this.map.addLayer(this.pointsLayer)
    },
    /**
     * 添加标记点
     */
    addMarkPoint(type) {
      unByKey(this.clickEventEditFeature)
      // this.showAddPointFrom = true
      // 设置添加标记点图层
      this.pointLayer = new VectorLayer({
        source: new Vector()
      })
      this.map.addLayer(this.pointLayer)

      // 绑定事件
      this.clickEvent = this.map.on('singleclick', (event) => {
        // 创建feature
        const feature = new Feature({
          geometry: new Point(event.coordinate)
        })
        //设置 图表的样式
        feature.setStyle(Styles.point)
        this.pointLayer.getSource().clear()
        this.pointLayer.getSource().addFeatures([feature])

        console.log(event.coordinate)
        // const locationStr = event.coordinate.toString()
        // console.log('locationStr',locationStr);
        // this.$set(this.newPoint, 'location', locationStr)

        // 站点转换为WKT格式 POINT(120.884526116333 32.0249844342041)
        const wkt = new WKT().writeGeometry(new GeomPoint(event.coordinate), {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:4326'
        })
        if (type === 'prevenFlood') {
          //传递标记点信息数据
          this.$emit('getPrevenFloodWKT', { wkt, coordinate: event.coordinate })
        }
        if (type === 'AddMobileIndicator') {
          //传递标记点信息数据
          this.$emit('getMobileIndicatorWKT', { wkt, coordinate: event.coordinate })
        }
        if (type === 'saveWaterQualityObj') {
          //传递标记点信息数据
          this.$emit('getWaterQualityWKT', { wkt, coordinate: event.coordinate })
        }
      })
    },
    /**
     * 取消添加标记点地图操作
     */
    cancelAddPointFrom() {
      this.showAddPointFrom = false
      console.log('cancelAddPointFrom', this.showAddPointFrom)
      unByKey(this.clickEvent)
      this.map.removeLayer(this.pointLayer)
    },
    /*
     *
     *          ┌─┐       ┌─┐
     *       ┌──┘ ┴───────┘ ┴──┐
     *       │                 │
     *       │       ───       │
     *       │  ─┬┘       └┬─  │
     *       │                 │
     *       │       ─┴─       │
     *       │                 │
     *       └───┐         ┌───┘
     *           │         │
     *           │         │
     *           │         │
     *           │         └──────────────┐
     *           │                        │
     *           │                        ├─┐
     *           │                        ┌─┘
     *           │                        │
     *           └─┐  ┐  ┌───────┬──┐  ┌──┘
     *             │ ─┤ ─┤       │ ─┤ ─┤
     *             └──┴──┘       └──┴──┘
     *                  神兽
     */

    //面操作 Begin====================================================
    /**
     * 地图显示标记面
     */
    showMarkArea(data) {
      this.map.removeLayer(this.areasLayer)

      let areaList = []
      data
        .filter((i) => i.geom)
        .forEach((item) => {
          let wkt = item.geom
          let format = new WKT()
          let feature = format.readFeature(wkt, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:4326'
          })
          let areaStyle = new Style({
            //填充样式
            fill: new Fill({
              color: 'rgba(5, 97, 235,0.6)'
            }),
            //边框样式
            stroke: new Stroke({
              color: 'rgba(65, 97, 235,1)',
              width: 3
            }),
            text: new Text({
              text: item.name,
              font: '18px sans-serif',
              fill: new Fill({
                // 设置字体颜色
                color: '#000'
              })
              //   offsetY: 10 // 设置文字偏移量
            })
          })
          feature.setStyle(areaStyle)
          feature.setProperties(item)
          areaList.push(feature)
        })
      this.areasLayer = new VectorLayer({
        source: new Vector({
          features: [...areaList]
        })
      })
      this.map.addLayer(this.areasLayer)
    },
    /**
     * 地图添加标记面
     */
    addMarkArea() {
      unByKey(this.clickEventEditFeature)
      //   this.cancelAddPointFrom()
      this.showAddAreaFrom = true

      //新增标记面
      this.newAreaLayer = new VectorLayer({
        source: new Vector()
      })
      this.map.addLayer(this.newAreaLayer)

      const draw = new Draw({
        type: 'MultiPolygon',
        source: this.newAreaLayer.getSource()
      })
      this.draw = draw
      this.map.addInteraction(this.draw)

      this.draw.once('drawend', (event) => {
        const wkt = new WKT().writeGeometry(new GeoMultiPolygon(event.feature.getGeometry().getCoordinates()), {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:4326'
        })
        //传递位置信息数据
        this.$emit('getAreaWKT', wkt)

        unByKey(this.clickEvent)
        this.map.removeInteraction(this.draw)
        this.editArea(this.newAreaLayer)
      })
    },
    /**
     * 编辑面地理信息
     */
    editArea(layer) {
      let select = new Select({ layers: [layer] })
      let features = select.getFeatures()
      let modify = new Modify({
        features: features
      })
      select.setActive(true)
      modify.setActive(true)

      this.select = select
      this.modify = modify

      this.map.addInteraction(this.select)
      this.map.addInteraction(this.modify)

      features.on('add', (event) => {
        const feature = event.element
        feature.on('change', (evt) => {
          const wkt = new WKT().writeGeometry(new GeoMultiPolygon(evt.target.getGeometry().getCoordinates()), {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:4326'
          })
          //传递位置信息数据
          this.$emit('getAreaWKT', wkt)
        })
      })
    },
    /**
     * 保存添加标记面
     */
    saveAddAreaFrom() {
      //取消添加标记面操作
      this.cancelAddAreaFrom()
    },
    /**
     * 取消添加标记面操作
     */
    cancelAddAreaFrom() {
      this.showAddAreaFrom = false

      this.map.removeInteraction(this.select)
      this.map.removeInteraction(this.modify)
      this.map.removeInteraction(this.draw)

      this.cancelEditFeature()
      this.map.removeLayer(this.newAreaLayer)
    },
    //面操作 End====================================================

    /*
     *
     *          ┌─┐       ┌─┐
     *       ┌──┘ ┴───────┘ ┴──┐
     *       │                 │
     *       │       ───       │
     *       │  ─┬┘       └┬─  │
     *       │                 │
     *       │       ─┴─       │
     *       │                 │
     *       └───┐         ┌───┘
     *           │         │
     *           │         │
     *           │         │
     *           │         └──────────────┐
     *           │                        │
     *           │                        ├─┐
     *           │                        ┌─┘
     *           │                        │
     *           └─┐  ┐  ┌───────┬──┐  ┌──┘
     *             │ ─┤ ─┤       │ ─┤ ─┤
     *             └──┴──┘       └──┴──┘
     *                  神兽
     */

    // 编辑点面
    editFeature(obj) {
      unByKey(this.clickEvent)
      this.clickEventEditFeature = this.map.on(
        'click',
        (evt) => {
          this.map.forEachFeatureAtPixel(evt.pixel, (a) => {
            const type = a.getGeometry().getType() // Point\MultiPolygon
            //点
            if (type === 'Point') {
              console.log('Point', a)
              console.log('obj.id', obj.id)
              console.log('a.values_.objId', a.values_.objId)
              //点击点时 判断该监测点是否属于编辑的对象下的检测点
              if (obj.id === a.values_.objId) {
                //传递位置信息数据
                this.$emit('setPoint', a.values_)
              }
            } else if (type === 'MultiPolygon') {
              //面
              console.log('MultiPolygon', a)
              if (obj.id === a.values_.id) {
                this.$emit('setArea')
                this.editArea(this.areasLayer)
              }
            } else if (type === 'LineString') {
              console.log('修改线条')
            }
          })
        },
        { hitTolerance: 180 } //hitTolerance: 以css像素为单位的命中检测公差。在给定位置周围半径内的像素将被检查特征。
      )
    },

    // 取消编辑
    cancelEditFeature() {
      unByKey(this.clickEvent)
      unByKey(this.clickEventEditFeature)
    },

    // ==========================================================
    //线
    addMarkLine(type) {
      console.log('添加操作线')
      // this.showAddLineFrom = true

      // unByKey(this.clickEventEditFeature);
      // this.cancelAddAreaFrom();
      // this.showAddPointFrom = true;

      // 设置添加标记线图层
      this.newLineLayer = new VectorLayer({
        source: new Vector(),
        style: new Style({
          //线条宽度、颜色
          stroke: new Stroke({
            color: '#00008B',
            width: 4
          })
        })
      })
      this.map.addLayer(this.newLineLayer)

      this.draw = new Draw({
        type: 'LineString',
        source: this.newLineLayer.getSource()
      })
      this.map.addInteraction(this.draw)

      this.draw.on('drawend', (event) => {
        const wkt = new WKT().writeGeometry(new GeoLineString(event.feature.getGeometry().getCoordinates()), {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:4326'
        })
        console.log('wkt', wkt)
        console.log('type', type)
        if (type === 'managementScope') {
          const geom = event.feature.getGeometry()
          const coordinates = geom.getCoordinates()

          //长度 m
          let length = getLength(geom, { projection: 'EPSG:4326' })
          this.$emit('getManagementScopeLineWKT', {
            wkt,
            startPos: coordinates[0],
            endPos: coordinates[coordinates.length - 1],
            length: Math.floor(length * 100) / 100
          })
        } else if (type === 'anPoZhiBeiFuGaiDu') {
          const geom = event.feature.getGeometry()
          const coordinates = geom.getCoordinates()

          //长度 m
          let length = getLength(geom, { projection: 'EPSG:4326' })

          this.$emit('getAnPoZhiBeiFuGaiDuWKT', {
            wkt,
            pos1: coordinates[0],
            pos2: coordinates[coordinates.length - 1],
            length: Math.floor(length * 100) / 100
          })
        } else if (type === 'huBinDaiZhiBeiFuGaiDu') {
          const geom = event.feature.getGeometry()
          const coordinates = geom.getCoordinates()

          //长度 m
          let length = getLength(geom, { projection: 'EPSG:4326' })

          this.$emit('gethuBinDaiZhiBeiFuGaiDuWKT', {
            wkt,
            pos1: coordinates[0],
            pos2: coordinates[coordinates.length - 1],
            length: Math.floor(length * 100) / 100
          })
        } else if (type === 'anXianKaiFaLiYongLv') {
          const geom = event.feature.getGeometry()
          //长度 m
          let length = getLength(geom, { projection: 'EPSG:4326' })
          this.$emit('getanXianKaiFaLiYongLvWKT', {
            wkt,
            length: Math.floor(length * 100) / 100
          })
        } else if (type === 'ecologicalBank') {
          const geom = event.feature.getGeometry()
          //长度 m
          let length = getLength(geom, { projection: 'EPSG:4326' })
          //传递标记信息数据
          this.$emit('getEcologicalBankLineWKT', { wkt, length: Math.floor(length * 100) / 100 })
        } else {
          //传递标记信息数据
          this.$emit('getLineWKT', { wkt })
        }
        this.showAddLineFrom = false
        this.map.removeInteraction(this.draw)
      })
    }

    // cancelAddLineFrom() {
    //   this.showAddPointFrom = false
    //   unByKey(this.clickEvent)
    //   this.map.removeLayer(this.pointLayer)
    // }

    // end===========
  }
}
</script>
<style lang="less" scoped>
#mapIndicator {
  flex: 1;
  height: calc(100% - 2px);
  background-color: #ecf5ff;
}

// 地区搜索框
#search {
  z-index: 999;
  position: absolute;
  top: 24px;
  left: 50px;
}

.toolBox {
  z-index: 999;
  width: 80px;
  height: 38px;
  position: absolute;
  top: 24px;
  left: 370px;
  border-radius: 5px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}

.tools {
  z-index: 999;
  width: 80px;
  height: 68px;
  position: absolute;
  top: 68px;
  left: 370px;
  border-radius: 5px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}

.layerTools {
  z-index: 999;
  width: 80px;
  height: 68px;
  position: absolute;
  top: 68px;
  left: 460px;
  border-radius: 5px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}
</style>
