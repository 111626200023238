<!-- // 调查问卷研判页面 -->
<template>
  <main>
    <el-button type="text" @click="nextQuestionnaire">下一份</el-button>
    <el-button type="text" @click="fitQuestionnaire">评判问卷</el-button>
    <h3>问卷状态：{{ fitFlag }}</h3>
    <form id="questionnaire" style="padding: 10px" label-width="120px" disabled></form>
  </main>
</template>
<script>
  import { Questionnaire } from '@/api/target/riverTarget'

  export default {
    name: 'QuestionnaireDetile',
    props: {
      schemeId: {
        type: Number,
        default: null
      }
    },
    data() {
      return {
        flagCount: 1,
        questionnaireDetile: {},
        fitFlag: ''
      }
    },
    mounted() {
      this.nextQuestionnaire()
    },
    methods: {
      // 下一份问卷操作按钮
      nextQuestionnaire() {
        let params = {
          schemeId: this.schemeId,
          pageSize: 1,
          pageNum: this.flagCount
        }
        this.flagCount++
        this.getDetile(params)
      },
      /**
       * @description  获取问卷数据
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      getDetile(info) {
        Questionnaire.questionnaireDetileGet(info).then((res) => {
          this.total = res.data.data.total
          if (res.data.data.list.length > 0) {
            this.questionnaireDetile = res.data.data.list[0]
            this.questionnaireDataInner(this.questionnaireDetile)
            const i = this.questionnaireDetile.nodeList.find((i) => {
              return i.name == 'fit'
            })
            if (i && i.val == 'true') {
              this.fitFlag = '有效'
            } else {
              this.fitFlag = '无效'
            }
          } else {
            this.$message({
              type: 'info',
              message: '已经是最后一份问卷！'
            })
          }
        })
      },
      // 问卷判无效
      fitQuestionnaire() {
        this.$confirm('确定要判定此份调查问卷为无效问卷吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            const i = this.questionnaireDetile.nodeList.find((i) => {
              return i.name == 'id'
            })
            Questionnaire.nopass(parseInt(i.val))
              .then((res) => {
                this.$message('判定成功！')
                //   this.getQuestionnaireList()
              })
              .catch((err) => {
                console.log(err)
              })
          })
          .catch(() => {
            this.$message({
              message: '取消判定无效操作！',
              type: 'warning'
            })
          })
      },

      questionnaireDataInner(info) {
        const _this = this
        let result = info
        this.strHtml = ''
        this.strHtmlTotal = ''
        this.getBlockList(result)
        document.getElementById('questionnaire').innerHTML = this.strHtmlTotal
      },
      // 表单填报问题获取递归算法
      getBlockList(info) {
        if (info.nodeList) {
          this.getodelist(info.nodeList)
        }
        if (info.blockList) {
          for (let i = 0; i < info.blockList.length; i++) {
            this.getBlockList(info.blockList[i])
          }
        }
      },
      // 问题表单项渲染实现
      getodelist(info) {
        for (let i = 0; i < info.length; i++) {
          if (info[i].nodeList == undefined || info[i].nodeList == null) {
            if (info[i].type == 'radio') {
              this.strHtml += "<div style='padding:" + 6 + "px'>"
              this.strHtml += "<p style='padding-:" + 6 + "px'>" + info[i].label + '：</p>'
              for (let j = 0; j < info[i].propList.length; j++) {
                if (info[i].val === info[i].propList[j].code) {
                  this.strHtml +=
                    '<label><input type="radio" name="' +
                    info[i].name +
                    '" value="' +
                    info[i].propList[j].code +
                    '" checked/>' +
                    info[i].propList[j].label +
                    '</label>'
                } else {
                  this.strHtml +=
                    '<label><input type="radio" name="' +
                    info[i].name +
                    '" value="' +
                    info[i].propList[j].code +
                    '" />' +
                    info[i].propList[j].label +
                    '</label>'
                }
              }
              this.strHtml += '</div>'
              this.strHtmlTotal += this.strHtml
              this.strHtml = ''
            } else if (info[i].type == 'text') {
              this.strHtml += "<div style='padding:" + 6 + "px'>"
              this.strHtml += "<p style='padding-:" + 6 + "px'>" + info[i].label + '：</p>'
              this.strHtml += '<input type="text" name="' + info[i].name + '" value="' + info[i].val + '" />'
              this.strHtml += '</div>'
              this.strHtmlTotal += this.strHtml
              this.strHtml = ''
            }
          }
        }
      }
    }
  }
</script>
