// type:1河流 2湖泊 3all
export const targetList = [
  {
    name: '岸坡稳定性',
    flag: 'anPoWenDingXingFlag',
    score: 90,
    type: 1
  },
  {
    name: '岸坡环境整治程度',
    flag: 'anPoHuanJingZhengZhi',
    score: 90,
    type: 3
  },
  {
    name: '水体整洁程度',
    flag: 'shuiTiZhengJi',
    score: 90,
    type: 3
  },
  {
    name: '岸坡植被覆盖度',
    flag: 'anPoZhiBeiFuGaiDu',
    score: 90,
    type: 1
  },
  {
    name: '岸线开发利用率',
    flag: 'anXianKaiFaLiYongLv',
    score: 90,
    type: 3
  },
  {
    name: '河流流动性',
    flag: 'fluvialFluidityFlag',
    score: 90,
    type: 1
  },
  {
    name: '生态用水满足程度',
    flag: 'ecologicalWaterFlag',
    score: 90,
    type: 1
  },
  {
    name: '浮游植物生物指数',
    flag: 'phytoplankton',
    score: 90,
    type: 1
  },
  {
    name: '着生藻类生物指数',
    flag: 'borneAlgae',
    score: 90,
    type: 1
  },
  {
    name: '供水水量保证率',
    flag: 'waterSupply',
    score: 90,
    type: 1
  },
  {
    name: '管理(保护)范围划定率',
    flag: 'managementScope',
    score: 90,
    type: 1
  },


  {
    name: '口门畅通率',
    flag: 'kouMenChangTongLv',
    score: 90,
    type: 2
  },
  {
    name: '湖滨带植被覆盖度',
    flag: 'huBinDaiZhiBeiFuGaiDu',
    score: 90,
    type: 2
  },
  {
    name: '湖水交换能力',
    flag: 'waterChange',
    score: 90,
    type: 2
  },
  {
    name: '生态水位满足率',
    flag: 'ecologicalWaterLake',
    score: '80',
    type: 2
  },
  {
    name: '主要入湖河流水质达标率',
    flag: 'enterLake',
    score: '80',
    type: 2
  },
  {
    name: '水面开发利用率',
    flag: 'waterSurfaceDevelopment',
    score: '80',
    type: 2
  },
  {
    name: '蓝藻密度',
    flag: 'cyanobacteriaDensity',
    score: '80',
    type: 2
  },
  {
    name: '管理(保护)范围划定率',
    flag: 'managementScopeLake',
    score: 90,
    type: 2
  },
  {
    name: '营养状态指数',
    flag: 'nutrition',
    score: 90,
    type: 2
  },


  {
    name: '入河排污口规范化建设率/入湖排污口规范化建设率',
    flag: 'outlet',
    score: 90,
    type: 3
  },
  {
    name: '底泥污染状况',
    flag: 'sedimentPollution',
    score: 90,
    type: 3
  },
  {
    name: '底栖生物指数',
    flag: 'benthic',
    score: 90,
    type: 3
  },
  {
    name: '生态岸坡比例',
    flag: 'ecologicalBank',
    score: 90,
    type: 3
  },
  {
    name: '水功能区达标率',
    flag: 'waterFunctionalArea',
    score: 90,
    type: 3
  },
  {
    name: '防洪达标率',
    flag: 'prevenFlood',
    score: 90,
    type: 3
  },
  {
    name: '水质优劣程度',
    flag: 'waterQuality',
    score: 90,
    type: 3
  },
  {
    name: '公众满意度',
    flag: 'questionnaire',
    score: 90,
    type: 3
  },
  {
    name: '景观舒适度',
    flag: 'landscape',
    score: 90,
    type: 3
  }
]

/**
 * @description  判定当前选择指标内容
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export const getTargetFlag = (val, type) => {
  let i = targetList.find((item) => {
    return item.name.includes(val) && (item.type == type || item.type === 3)
  })
  return i ? i.flag : 'target'
}
