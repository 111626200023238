class mobileIndicator {
    //岸坡稳定性
    getAnPoWenDingXing() {
        return {
            name: '岸坡稳定性',
            des: '岸坡稳定性指河流岸坡的稳定程度和保护现状。通过滨岸冲帅与侵蚀、岸坡水土流程以及坍塌破坏等情况确定指标赋分值，岸坡稳定的河段区赋分为100分，基于河道现状调查，对应岸坡不稳定现场类型及其严重程度进行扣分。',
            showHeDuan: true,
            indexList: [
                {
                    id: '1',
                    name: '岸坡类型',
                    show: true,
                    type: 1,//1选择 2填空
                    value: 'anPoType',
                    des: 'anPoDesc',
                    filePath: 'anPoPngCode',
                    data: [{
                        id: "1",
                        value: '土质自然植被',
                        name: '土质自然植被',
                    }, {
                        id: "2",
                        value: '直立式桨砌护坡',
                        name: '直立式桨砌护坡',

                    }, {
                        id: "3",
                        value: '压顶混凝土',
                        name: '压顶混凝土',

                    }, {
                        id: "4",
                        value: '其他',
                        name: '其他',
                        type: '1', //指标项 type1表示其他  选择后下方弹出输入框 todo
                    }]
                },
                {
                    id: '2',
                    name: '岸坡周边',
                    show: true,
                    type: 1,//1选择 2填空
                    value: 'anPoZhouBianType',
                    des: 'anPoZhouBianDesc',
                    filePath: 'anPoZhouBianPngCode',
                    data: [{
                        id: "1",
                        name: '居民区',
                        value: '居民区',
                    }, {
                        id: "2",
                        name: '耕地(菜地)',
                        value: '耕地(菜地)',
                    }, {
                        id: "3",
                        name: '休闲步道',
                        value: '休闲步道',
                    }, {
                        id: "4",
                        name: '城镇',
                        value: '城镇',
                    }, {
                        id: "5",
                        name: '绿化带',
                        value: '绿化带',
                    }, {
                        id: "6",
                        name: '其他',
                        type: '1', //指标项 type1表示其他  选择后下方弹出输入框
                        value: '其他',
                    }]
                },
                {
                    id: '3',
                    name: '稳定性',
                    show: true,
                    type: 1,//1选择 2填空
                    value: 'wenDingXingFlag',
                    des: 'wenDingXingDesc',
                    showType: true, //表示是否控制其他选项显示
                    showTypeValue: false, //显示的条件：value为false
                    showList: ['4', '5', '6'],
                    filePath: 'wenDingXingPngCode',
                    data: [{
                        id: "1",
                        name: '稳定',
                        value: true
                    }, {
                        id: "2",
                        name: '不稳定',
                        value: false,
                    }]
                },
                {
                    id: '4',
                    name: '岸坡水土流失',
                    show: false,
                    value: 'anPoShuiTuLiuShiLevel',
                    des: 'anPoShuiTuLiuShiDesc',
                    type: 1,//1选择 2填空
                    filePath: 'anPoShuiTuLiuShiPngCode',
                    data: [{
                        id: "1",
                        name: '轻度',
                        value: 'shuiTuLiuShi_small',
                    }, {
                        id: "2",
                        name: '中等',
                        value: 'shuiTuLiuShi_middle',
                    }, {
                        id: "3",
                        name: '严重',
                        value: 'shuiTuLiuShi_weight',
                    }]
                },
                {
                    id: '5',
                    name: '滨岸冲刷、侵蚀',
                    show: false,
                    type: 1,//1选择 2填空
                    value: 'binAnChongShuaLevel',
                    des: 'binAnChongShuaDesc',
                    filePath: 'binAnChongShuaPngCode',
                    data: [{
                        id: "1",
                        name: '轻度',
                        value: 'chongShuaQinShi_small',
                    }, {
                        id: "2",
                        name: '中等',
                        value: 'chongShuaQinShi_middle',
                    }, {
                        id: "3",
                        name: '严重',
                        value: 'chongShuaQinShi_weight',
                    }]
                },
                {
                    id: '6',
                    name: '塌岸',
                    show: false,
                    type: 1,//1选择 2填空
                    value: 'anTaLevel',
                    des: 'anTaDesc',
                    filePath: 'anTaPngCode',
                    data: [{
                        id: "1",
                        name: '轻度',
                        value: 'taAn_small',
                    }, {
                        id: "2",
                        name: '中等',
                        value: 'taAn_middle',
                    }, {
                        id: "3",
                        name: '严重',
                        value: 'taAn_weight',
                    }]
                },
                {
                    id: '7',
                    name: '预评价',
                    show: true,
                    type: 1,//1选择 2填空
                    value: 'preEvaluate',
                    des: 'preEvaluateDesc',
                    filePath: 'preEvaluatePngCode',
                    data: [{
                        id: "1",
                        name: '非常健康',
                        value: '非常健康'
                    }, {
                        id: "2",
                        name: '健康',
                        value: '健康'
                    }, {
                        id: "3",
                        name: '不健康',
                        value: '不健康'
                    }]
                }
            ]
        }
    }

    //岸坡环境整治
    getAnPoHuanJingZhengZhi() {
        return {
            name: '岸坡环境整治程度',
            des: '岸坡环境整治程度是指对河流岸坡管理范围内乱垦、乱占、乱建、乱弃现象的清理和整治程度。乱垦是指未经批准的在河道岸坡开垦和种植农林作物；乱占是指未经批准占用、拆毁河道原有堤防、设施构筑物；乱建是指违法违规建设建筑物、构筑物等设施；乱弃是指未经批准倾倒、堆放或掩埋生活垃圾等各类废弃物以及砂石泥土等其他物料。',
            indexList: [
                {
                    id: '1',
                    name: '乱垦',
                    show: true,
                    type: 1,//1选择 2填空
                    value: 'luanKenLevel',
                    des: 'luanKenDesc',
                    filePath: 'luanKenPng',
                    data: [{
                        id: "1",
                        value: 'luanKen_small',
                        name: '面积<200㎡',
                    }, {
                        id: "2",
                        value: 'luanKen_middle',
                        name: '面积200~1000㎡',

                    }, {
                        id: "3",
                        value: 'luanKen_weight',
                        name: '面积>1000㎡',

                    }]
                },
                {
                    id: '2',
                    name: '乱占',
                    show: true,
                    type: 1,//1选择 2填空
                    value: 'luanZhanLevel',
                    des: 'luanZhanDesc',
                    filePath: 'luanZhanPng',
                    data: [{
                        id: "1",
                        name: '长度<5m',
                        value: 'luanZhan_small',
                    }, {
                        id: "2",
                        name: '长度5~50m',
                        value: 'luanZhan_middle',
                    }, {
                        id: "3",
                        name: '长度>50m',
                        value: 'luanZhan_weight',
                    }]
                },
                {
                    id: '3',
                    name: '乱建',
                    show: true,
                    type: 1,//1选择 2填空
                    value: 'luanJianLevel',
                    des: 'luanJianDesc',
                    filePath: 'luanJianPng',
                    data: [{
                        id: "1",
                        name: '面积<5㎡',
                        value: 'luanJian_small'
                    }, {
                        id: "2",
                        name: '面积5~20㎡',
                        value: 'luanJian_middle',
                    }, {
                        id: "3",
                        name: '面积>20㎡',
                        value: 'luanJian_weight',
                    }]
                },
                {
                    id: '4',
                    name: '乱弃',
                    show: true,
                    type: 1,//1选择 2填空
                    value: 'luanQiLevel',
                    des: 'luanQiDesc',
                    filePath: 'luanQiPng',
                    data: [{
                        id: "1",
                        name: '面积<5㎡',
                        value: 'luanQi_small',
                    }, {
                        id: "2",
                        name: '面积5~100㎡',
                        value: 'luanQi_middle',
                    }, {
                        id: "3",
                        name: '面积>100㎡',
                        value: 'luanQi_weight',
                    }]
                },
            ]
        }
    }

    //水体整洁程度
    getShuiTiZhengJieChengDu() {
        return {
            name: '水体整洁程度',
            des: '水体整洁程度表征河流水域感官及环境整洁状况。水体无异味、无漂浮废弃物现象的河流水体整洁程度指标赋分值为 100 分，基于河道现状调查，对应各项评价因素及其严重程度进行扣分。',
            indexList: [
                {
                    id: '1',
                    name: '嗅和味',
                    show: true,
                    type: 1,//1选择 2填空
                    value: 'xiuHeWeiLevel',
                    des: 'xiuHeWeiDesc',
                    filePath: 'xiuHeWeiPng',
                    data: [{
                        id: "1",
                        value: 'xiuHeWei_small',
                        name: '轻度',
                    }, {
                        id: "2",
                        value: 'xiuHeWei_middle',
                        name: '明显',
                    }, {
                        id: "3",
                        value: 'xiuHeWei_weight',
                        name: '强烈',

                    }]
                },
                {
                    id: '2',
                    name: '漂浮废弃物(㎡)',
                    show: true,
                    type: 1,//1选择 2填空
                    value: 'piaoFuWuLevel',
                    des: 'piaoFuWuDesc',
                    filePath: 'piaoFuWuPng',
                    data: [{
                        id: "1",
                        name: '<10',
                        value: 'piaoFuWu_small',
                    }, {
                        id: "2",
                        name: '10~50',
                        value: 'piaoFuWu_middle',
                    }, {
                        id: "3",
                        name: '>50',
                        value: 'piaoFuWu_weight',
                    }]
                },
                {
                    id: '3',
                    name: '透明度(m)',
                    show: true,
                    type: 1,//1选择 2填空
                    value: 'touMingDuLevel',
                    des: 'touMingDuDesc',
                    filePath: 'touMingDuPng',
                    data: [{
                        id: "1",
                        name: '<0.5',
                        value: 'touMingDu_small'
                    }, {
                        id: "2",
                        name: '0.15~0.3',
                        value: 'touMingDu_middle',
                    }, {
                        id: "3",
                        name: '>0.3',
                        value: 'touMingDu_weight',
                    }]
                },
            ]
        }
    }

    //景观舒适度
    getJingGuanShuShiDu() {
        return {
            name: '景观舒适度',
            des: '景观舒适度反映河道景观观赏性、亲水性以及水文化开发建设等方面的综合评价。',
            indexList: [
                {
                    id: '1',
                    name: '观赏性',
                    show: true,
                    type: 1,//1选择 2填空
                    value: 'gsxLevel',
                    des: 'gsxDesc',
                    filePath: 'gsxPngCode',
                    data: [{
                        id: "1",
                        value: '舒适，河道景观和谐，有良好观赏性',
                        name: '舒适，河道景观和谐，有良好观赏性',
                    }, {
                        id: "2",
                        value: '较舒适，河道景观较和谐，有较好观赏性',
                        name: '较舒适，河道景观较和谐，有较好观赏性',
                    }, {
                        id: "3",
                        value: '舒适程度较低，河道景观一般，观赏性一般',
                        name: '舒适程度较低，河道景观一般，观赏性一般',

                    }, {
                        id: "4",
                        value: '舒适度低，河道景观观赏性较差',
                        name: '舒适度低，河道景观观赏性较差',
                    }, {
                        id: "5",
                        value: '不舒适，河道观赏性差',
                        name: '不舒适，河道观赏性差',

                    }]
                },
                {
                    id: '2',
                    name: '亲水便利性',
                    show: true,
                    type: 1,//1选择 2填空
                    value: 'qmdLevel',
                    des: 'qmdDesc',
                    filePath: 'qmdPngCode',
                    data: [{
                        id: "1",
                        name: '亲水设施便利',
                        value: '亲水设施便利',
                    }, {
                        id: "2",
                        name: '亲水设施配套较好',
                        value: '亲水设施配套较好',
                    }, {
                        id: "3",
                        name: '亲水设施较少',
                        value: '亲水设施较少',
                    }, {
                        id: "4",
                        name: '亲水设施少',
                        value: '亲水设施少',
                    }, {
                        id: "5",
                        name: '亲水设施配套性差',
                        value: '亲水设施配套性差',
                    }]
                },
                {
                    id: '3',
                    name: '水文化建设',
                    show: true,
                    type: 1,//1选择 2填空
                    value: 'swhLevel',
                    des: 'swhDesc',
                    filePath: 'swhPngCode',
                    data: [{
                        id: "1",
                        name: '历史文化价值开发、保存完好',
                        value: '历史文化价值开发、保存完好'
                    }, {
                        id: "2",
                        name: '历史文化价值开发、保存较好',
                        value: '历史文化价值开发、保存较好',
                    }, {
                        id: "3",
                        name: '历史文化价值开发、保存一般',
                        value: '历史文化价值开发、保存一般',
                    }, {
                        id: "4",
                        name: '历史文化价值开发、保存较差',
                        value: '历史文化价值开发、保存较差',
                    }, {
                        id: "5",
                        name: '历史文化价值开发、保存差',
                        value: '历史文化价值开发、保存差',
                    }]
                },
            ]
        }
    }

    //口门畅通率
    getKouMenChangTongLv() {
        return {
            name: '口门畅通率',
            des: '口门畅通率指主要出入河流与湖泊的连通程度。',
            indexList: [
                {
                    id: '1',
                    name: '是否畅通',
                    show: true,
                    type: 1,//1选择 2填空
                    value: 'openFlag',
                    des: 'kouMenDesc',
                    filePath: 'kouMenPngCode',
                    data: [{
                        id: "1",
                        value: true,
                        name: '是',
                    }, {
                        id: "2",
                        value: false,
                        name: '否',
                    }]
                }
            ]
        }
    }
}


export default mobileIndicator