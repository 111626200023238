<template>
  <div>
    <el-dialog
      title="入河(湖)排污口规范化建设率"
      :visible.sync="flag"
      width="518px"
      :before-close="close"
      :modal="false"
      :close-on-click-modal="false"
    >
      <div slot="title" class="dialog-header-title">
        <span class="title-name">
          入河(湖)排污口规范化建设率
          <i class="el-icon-question" @click="openTip('rhpwkgfhjsl')"></i>
        </span>
        <span class="title-score">
          最终得分:
          <span v-if="finalScore">{{ finalScore.toFixed(1) }}</span>
          <span v-else>--</span>
        </span>
      </div>
      <Standrad
        :anchor="anchor"
        :show-flag="indicatorDefinition"
        @changFlag="() => (indicatorDefinition = !indicatorDefinition)"
      />
      <el-table
        :data="detailList"
        style="width: 100%; font-size: 12px; text-align: center"
        header-cell-class-name="table-header-cls"
      >
        <el-table-column prop="blowdownOutletName" label="名称" align="center" header-align="center" width="90">
          <template slot-scope="scope">
            <el-input v-model="scope.row.blowdownOutletName" type="text"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="standardFlag" label="达标" align="center" header-align="center" width="84">
          <template slot-scope="scope">
            <el-select v-model="scope.row.standardFlag" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="blowdownOutletDesc" label="说明" align="center" header-align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.blowdownOutletDesc" type="text"></el-input>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="90" align="center">
          <template slot-scope="scope">
            <el-button v-if="!scope.row.id" type="text" @click.native.prevent="deleteRow(scope.$index, detailList)"
              >移除</el-button
            >
            <el-button v-if="scope.row.id" type="text" style="color: red" @click.native.prevent="deleteDe(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="add-box" @click="addNew">
        <span><el-icon class="el-icon-plus" style="margin-right: 8px"></el-icon>添加</span>
      </div>
      <div class="file-container">
        <el-row type="flex">
          <el-col :span="8" class="row-lable-name">附件:</el-col>
          <el-col :span="16">
            <MyUploadFile ref="uploadfile" :query-ext="queryExt" :attachs="attachs" />
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button class="btn-info" @click="close">取 消</el-button>
        <el-button class="btn-submit" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { Outlet } from '@/api/target/riverTarget.js'

  export default {
    // 入河排污口规范化建设率
    name: 'Outlet',
    components: {},
    props: {
      schemeId: {
        type: Number,
        default: 1
      },
      targetData: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        flag: true,

        detailList: [],
        options: [
          {
            label: '是',
            value: true
          },
          {
            label: '否',
            value: false
          }
        ],
        finalScore: null,
        queryExt: {},
        attachs: '',
        id: null,

        indicatorDefinition: false,
        anchor: ''
      }
    },
    computed: {},
    watch: {
      detailList: {
        immediate: true,
        handler(val, oldval) {
          this.countFinalScore()
        },
        deep: true
      },
      targetData: {
        immediate: true,
        handler(val, oldval) {
          if (JSON.stringify(val) !== '{}') {
            this.loadData(val)
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description 指标描述展示
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      openTip(val) {
        this.anchor = val
        this.indicatorDefinition = true
      },
      /**
       * @description 数据初始化加载
       * @author hu_ty
       * @since
       * @param {object} data 有数据的指标信息内容
       *
       */
      loadData(data) {
        // console.log('<== loadData ==>', data)
        this.detailList = data.detailList
        this.finalScore = data.scoreVal
        this.attachs = data.attachs
        this.queryExt = data.queryExt
        this.id = data.id
      },
      /**
       * @description  关闭组件并改变父级 targetFlag 值
       * @author hu_ty
       * @since 22-06-24
       * @param {*}
       *
       */
      close() {
        this.$emit('changeFlag')
      },
      /**
       * @description 入河排污口规范化建设率表格动态新增
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      addNew() {
        let newLine = {
          blowdownOutletName: '',
          standardFlag: true,
          blowdownOutletDesc: '',
          key: Date.now()
        }
        this.detailList.push(newLine) // 移到第一行
      },
      /**
       * @description  删除行
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      deleteRow(index, rows) {
        rows.splice(index, 1)
      },
      deleteDe(val) {
        console.log('<==  ==>', val)
        Outlet.delInfo(val.parentId, val.id).then((res) => {
          Outlet.find(this.id).then((res) => {
            this.loadData(res.data.data)
          })
        })
      },
      /**
       * @description  最终得分计算
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      countFinalScore() {
        let score
        let per
        let length = this.detailList?.length
        if (length > 0) {
          let count = 0
          this.detailList.forEach((item) => {
            if (item.standardFlag) {
              count = count + 1
            }
          })
          per = (count / length) * 100
        } else {
          per = 100
        }
        switch (true) {
          case per == 100:
            score = 100
            break
          case per >= 90:
            score = 80 + ((per - 90) / 10) * 10
            break
          case per >= 80:
            score = 60 + ((per - 80) / 10) * 20
            break
          case per >= 60:
            score = 40 + ((per - 60) / 20) * 20
            break
          case per >= 0:
            score = (per / 60) * 40
            break
          default:
        }
        this.finalScore = score
      },
      /**
       * @description 数据保存
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      submit() {
        this.attachs = this.$refs.uploadfile.getAttachs()

        let param = {
          attachs: this.attachs,
          schemeId: this.schemeId,
          id: this.id,
          detailList: this.detailList,
          scoreVal: this.finalScore
        }
        Outlet.saveInfo(param).then((res) => {
          console.log('<==  ==>', res)
          this.close()
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .file-container {
    margin-top: 16px;
  }
  /deep/ .el-table__row .cell > .el-input > input {
    border: 0;
  }
  /deep/ .el-table__row .el-input__inner {
    padding: 0;
    text-align: center;
  }
</style>
