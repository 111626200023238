<template>
  <div>
    <el-dialog
      title="主要入湖河流水质达标率"
      :visible.sync="flag"
      width="468px"
      :before-close="close"
      :modal="false"
      :close-on-click-modal="false"
    >
      <div slot="title" class="dialog-header-title">
        <span class="title-name">
          主要入湖河流水质达标率
          <i class="el-icon-question" @click="openTip('zyrhhlazdbl')"></i>
        </span>
        <span class="title-score">
          最终得分:
          <span v-if="finalScore">{{ finalScore.toFixed(1) }}</span>
          <span v-else>--</span>
        </span>
      </div>
      <Standrad
        :anchor="anchor"
        :show-flag="indicatorDefinition"
        @changFlag="() => (indicatorDefinition = !indicatorDefinition)"
      />
      <div class="body-content">
        <el-row type="flex" align="middle">
          <el-col :span="8" class="row-lable-name"><div>达标次数:</div> </el-col>
          <el-col :span="8">
            <el-input v-model="rp" type="number" style="width: 80%" @blur="count"></el-input>&nbsp;次
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="8" class="row-lable-name"><div>评价总次数:</div> </el-col>
          <el-col :span="8">
            <el-input v-model="np" type="number" style="width: 80%" @blur="count"></el-input>&nbsp;次
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="8" class="row-lable-name">计算分析说明:</el-col>
          <el-col :span="16">
            <el-input
              v-model="analysisDesc"
              type="textarea"
              placeholder="请输入计算说明(数据来源、得出此数据的简要说明)"
              :autosize="{ minRows: 2, maxRows: 2 }"
            ></el-input>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="8" class="row-lable-name">附件:</el-col>
          <el-col :span="16">
            <MyUploadFile ref="uploadfile" :query-ext="queryExt" :attachs="attachs" />
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button class="btn-info" @click="close">取 消</el-button>
        <el-button class="btn-submit" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { EnterLake } from '@/api/target/lakeTarget.js'
  export default {
    // 主要入湖河流水质达标率
    name: 'EnterLake',
    components: {},
    props: {
      schemeId: {
        type: Number,
        default: 1
      },
      targetData: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        flag: true,

        rp: 0,
        np: 0,
        analysisDesc: '',
        finalScore: 0,

        id: null,
        attachs: '',
        queryExt: {},

        indicatorDefinition: false,
        anchor: ''
      }
    },
    computed: {},
    watch: {
      targetData: {
        immediate: true,
        handler(val, oldval) {
          if (JSON.stringify(val) !== '{}') {
            this.loadData(val)
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description 指标描述展示
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      openTip(val) {
        this.anchor = val
        this.indicatorDefinition = true
      },
      /**
       * @description 数据初始化加载
       * @author hu_ty
       * @since
       * @param {object} data 有数据的指标信息内容
       *
       */
      loadData(data) {
        this.np = data.np
        this.rp = data.rp
        this.analysisDesc = data.analysisDesc
        this.finalScore = data.scoreVal
        this.attachs = data.attachs
        this.queryExt = data.queryExt
        this.id = data.id
      },
      /**
       * @description
       * @author hu_ty
       * @since 22-07-01
       * @param {*}
       *
       */
      count() {
        let score
        if (this.rp && this.np) {
          let per = (parseInt(this.rp) / parseInt(this.np)) * 100
          switch (true) {
            case per >= 100:
              score = 100
              break
            case per >= 90:
              score = 90 + ((per - 90) / 10) * 10
              break
            case per >= 80:
              score = 80 + ((per - 80) / 10) * 10
              break
            case per >= 70:
              score = 60 + ((per - 70) / 10) * 20
              break
            case per >= 50:
              score = 40 + ((per - 50) / 20) * 20
              break
            case per >= 0:
              score = (per / 50) * 40
              break
            default:
          }
          this.finalScore = score
        }
      },
      /**
       * @description  关闭组件并改变父级 targetFlag 值
       * @author hu_ty
       * @since 22-06-24
       * @param {*}
       *
       */
      close() {
        this.$emit('changeFlag')
      },
      /**
       * @description 数据保存
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      submit() {
        this.attachs = this.$refs.uploadfile.getAttachs()

        let param = {
          id: this.id,
          schemeId: this.schemeId,
          attachs: this.attachs,
          rp: this.rp,
          np: this.np,
          analysisDesc: this.analysisDesc,
          scoreVal: this.finalScore
        }
        EnterLake.saveInfo(param).then((res) => {
          this.close()
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .body-content {
    // margin: 0 48px;
    .el-row {
      margin-bottom: 16px;
    }
  }
</style>
