<template>
  <div>
    <el-dialog
      title="岸坡植被覆盖度"
      :visible.sync="flag"
      width="60%"
      :before-close="close"
      top="64px"
      :modal="false"
      :close-on-click-modal="false"
    >
      <el-dialog
        v-if="innerVisible"
        width="80%"
        title="岸坡植被覆盖度"
        :visible.sync="innerVisible"
        append-to-body
        top="2%"
      >
        <IndicatorMap
          :an-po-zhi-bei-fu-gai-du="anPoZhiBeiFuGaiDu"
          :type="'anPoZhiBeiFuGaiDu'"
          @saveAnPoZhiBeiFuGaiDuObj="saveAnPoZhiBeiFuGaiDuObj"
          @cancelAnPoZhiBeiFuGaiDuObj="cancelAnPoZhiBeiFuGaiDuObj"
        >
        </IndicatorMap>
      </el-dialog>

      <div slot="title" class="dialog-header-title">
        <span class="title-name">
          岸坡植被覆盖度
          <i class="el-icon-question" @click="openTip('apzbfgd')"></i>
        </span>
        <span class="title-score">
          最终得分:
          <span v-if="finalScore">{{ finalScore.toFixed(1) }}</span>
          <span v-else>--</span>
        </span>
      </div>

      <Standrad
        :anchor="anchor"
        :show-flag="indicatorDefinition"
        @changFlag="() => (indicatorDefinition = !indicatorDefinition)"
      />

      <div class="pollution-box">
        <!-- <el-row> 线下采样点： </el-row> -->
        <el-row>
          <el-table
            :data="detailList"
            style="width: 100%; font-size: 12px; text-align: center"
            header-cell-class-name="table-header-cls"
          >
            <el-table-column prop="name" label="河段名称" align="center" header-align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.name" type="text"></el-input>
              </template>
            </el-table-column>

            <el-table-column prop="percentage" label="预估占比(%)" align="center" header-align="center" width="100">
              <template slot-scope="scope">
                <el-input v-model.number="scope.row.percentage" type="number"></el-input>
              </template>
            </el-table-column>

            <el-table-column prop="width" label="岸带宽度(m)" align="center" header-align="center" width="100">
              <template slot-scope="scope">
                <el-input v-model.number="scope.row.width" type="number"></el-input>
              </template>
            </el-table-column>

            <el-table-column prop="length" label="长度(m)" align="center" header-align="center" width="100">
              <template slot-scope="scope">
                <el-input v-model="scope.row.length" type="text"></el-input>
              </template>
            </el-table-column>

            <el-table-column prop="coverLevel" label="覆盖度" align="center" header-align="center" width="80">
              <template slot-scope="scope">
                <el-input v-model="scope.row.coverLevel" type="text"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="coverDesc" label="现场描述" align="center" header-align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.coverDesc" type="text"></el-input>
              </template>
            </el-table-column>

            <el-table-column prop="coverPngCodeList" label="现场图片" align="center" header-align="center" width="100">
              <template slot-scope="scope">
                <div v-if="scope.row.coverPngCodeList && scope.row.coverPngCodeList.length > 0">
                  <el-image
                    style="width: 90px; height: 90px"
                    fit="scale-down"
                    :src="scope.row.coverPngCodeList[0]"
                    :preview-src-list="scope.row.coverPngCodeList"
                  >
                  </el-image>
                </div>
              </template>
            </el-table-column>

            <!-- <el-table-column prop="pos1" label="起点" align="center" header-align="center" width="100">
            </el-table-column>

            <el-table-column prop="pos2" label="结束点" align="center" header-align="center" width="100">
            </el-table-column> -->

            <el-table-column fixed="right" label="操作" width="200" align="center">
              <template slot-scope="scope">
                <el-button v-if="!scope.row.id" type="text" @click.native.prevent="deleteRow(scope.$index, detailList)"
                  >移除</el-button
                >
                <el-button
                  v-if="scope.row.id"
                  type="text"
                  style="color: red"
                  @click.native.prevent="trueDeleteRow(scope.row, detailList)"
                  >删除</el-button
                >
                <el-button type="text" @click.native.prevent="draw(scope.row)">绘制</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>

        <el-row>
          <div class="add-box" @click="addNew">
            <span> <el-icon class="el-icon-plus" style="margin-right: 8px"></el-icon>添加 </span>
          </div>
        </el-row>

        <el-row type="flex">
          <el-col :span="8" class="row-lable-name">附件:</el-col>
          <el-col :span="16">
            <MyUploadFile ref="uploadfile" :query-ext="queryExt" :attachs="attachs" />
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button class="btn-info" @click="close">取 消</el-button>
        <el-button class="btn-submit" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { AnPoZhiBeiFuGaiDu } from '@/api/target/riverTarget.js'
  import IndicatorMap from '../indicatorMap.vue'
  import { File } from '@/api/file/index.js'

  export default {
    name: 'AnPoZhiBeiFuGaiDu',
    components: { IndicatorMap },
    props: {
      schemeId: {
        type: Number,
        default: 1
      },
      targetData: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        flag: true,
        id: null,

        innerVisible: false,
        queryExt: {},
        attachs: '',

        detailList: [],

        indicatorDefinition: false,
        anchor: '',
        anPoZhiBeiFuGaiDu: {}
      }
    },
    computed: {},
    watch: {
      targetData: {
        immediate: true,
        handler(val, oldval) {
          if (val != {}) {
            this.loadData(val)
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description 指标描述展示
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      openTip(val) {
        this.anchor = val

        this.indicatorDefinition = true
      },
      /**
       * @description 数据初始化加载
       * @author hu_ty
       * @since
       * @param {object} data 有数据的指标信息内容
       *
       */
      loadData(data) {
        console.log('<== loadData ==>', data)
        this.formmater(data.detailList)
        this.finalScore = data.scoreVal
        this.attachs = data.attachs
        this.queryExt = data.queryExt
        this.id = data.id
      },
      // 获取图片列表
      async formmater(data) {
        this.detailList = []
        data.forEach(async (item) => {
          if (item.coverPngCode && !item.coverPngCode.includes('tmp')) {
            item.coverPngCodeList = []
            let arr = item.coverPngCode.split(',')
            let promise = []
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.coverPngCodeList.push(res.data.data)
              })
              promise.push(a)
            })
            await Promise.all(promise)
          }

          this.detailList.push(item)
        })
      },
      /**
       * @description  关闭组件并改变父级 targetFlag 值
       * @author hu_ty
       * @since 22-06-24
       * @param {*}
       */
      close() {
        this.$emit('changeFlag')
      },
      // addNew() {
      //   this.innerVisible = true
      // },
      draw(data) {
        console.log('data', data)
        this.anPoZhiBeiFuGaiDu = data
        this.innerVisible = true
      },
      //上传现场照片
      uploadImg(row) {
        console.log(row)
      },
      addNew() {
        this.anPoZhiBeiFuGaiDu = {}
        this.detailList.push(this.anPoZhiBeiFuGaiDu)
        // this.innerVisible = true
      },

      deleteRow(index, rows) {
        rows.splice(index, 1)
      },
      trueDeleteRow(row) {
        console.log(row)
        this.$confirm('确定删除该数据？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            AnPoZhiBeiFuGaiDu.deleteDetailById(row.id).then((res) => {
              AnPoZhiBeiFuGaiDu.find(row.parentId).then((res) => {
                this.loadData(res.data.data)
              })
            })
          })
          .catch(() => {
            console.log('取消删除')
          })
      },
      saveAnPoZhiBeiFuGaiDuObj(data) {
        console.log('saveAnPoZhiBeiFuGaiDuObj', data)
        // this.detailList.push(data)
        this.innerVisible = false
      },
      cancelAnPoZhiBeiFuGaiDuObj() {
        this.innerVisible = false
      },
      submit() {
        this.attachs = this.$refs.uploadfile.getAttachs()
        let param = {
          attachs: this.attachs,
          detailList: this.detailList,
          schemeId: this.schemeId
        }
        console.log('param', param)

        AnPoZhiBeiFuGaiDu.saveInfo(param).then((res) => {
          console.log('<== AnPoZhiBeiFuGaiDu ==>', res)
          this.close()
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .pollution-box {
    .el-row {
      margin-bottom: 16px;
    }
  }

  /deep/ input {
    // border: 0;
    color: rgb(0, 0, 0) 80%;
    text-align: center;
  }
</style>
