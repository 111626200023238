<template>
  <div>
    <el-dialog
      title="岸坡环境整治程度"
      :visible.sync="flag"
      width="40%"
      height="90%"
      :before-close="close"
      :modal="false"
      :close-on-click-modal="false"
    >
      <el-dialog
        v-if="innerVisible"
        width="80%"
        title="岸坡环境整治程度"
        :visible.sync="innerVisible"
        append-to-body
        top="2%"
      >
        <IndicatorMap
          :type="'anPoHuanJingZhengZhi'"
          @saveAddObj="saveAddObj"
          @cancelAddObj="cancelAddObj"
        ></IndicatorMap>
      </el-dialog>

      <div slot="title" class="dialog-header-title">
        <span class="title-name">
          岸坡环境整治程度
          <i class="el-icon-question" @click="openTip('aphjzzcd')"></i>
        </span>
        <span class="title-score">
          最终得分:
          <span v-if="finalScore">{{ finalScore.toFixed(1) }}</span>
          <span v-else>--</span>
        </span>
      </div>

      <Standrad
        :anchor="anchor"
        :show-flag="indicatorDefinition"
        @changFlag="() => (indicatorDefinition = !indicatorDefinition)"
      />

      <div v-for="(data, index) in detailList" v-cloak :key="index" style="margin-bottom: 50px">
        <div class="container">
          <!-- 乱垦-->
          <div v-if="data.luanKenLevel" class="item-content">
            <el-row>
              <el-col class="row-lable-name" :span="8">乱垦：</el-col>
              <el-col :span="10">
                <el-select ref="select" v-model="data.luanKenLevel">
                  <el-option v-for="item in luankengOptions" :key="item.name" :label="item.name" :value="item.scoreStr">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-row v-if="data.luanKenPngList && data.luanKenPngList.length > 0">
              <el-col :span="10" :offset="8">
                <el-image
                  style="width: 90px; height: 90px"
                  fit="scale-down"
                  :src="data.luanKenPngList[0]"
                  :preview-src-list="data.luanKenPngList"
                >
                </el-image>
              </el-col>
            </el-row>
            <el-row v-if="data.luanKenDesc">
              <el-col class="row-lable-name" :span="8">描述：</el-col>
              <el-col :span="10">
                <el-input v-model="data.luanKenDesc" type="textarea" :rows="2"> </el-input>
              </el-col>
            </el-row>
            <el-row>
              <el-col class="row-lable-name" :span="8">扣分值：</el-col>
              <el-col :span="10">{{ getIndicatorScore(data.luanKenLevel) }}</el-col>
            </el-row>
            <el-row>
              <el-col class="row-lable-name" :span="8">参与此次评价：</el-col>
              <div>
                <el-radio v-model="data.luanKenJoinFlag" :label="true">是</el-radio>
                <el-radio v-model="data.luanKenJoinFlag" :label="false">否</el-radio>
              </div>
            </el-row>
          </div>
          <!-- 乱占 -->
          <div v-if="data.luanZhanLevel" class="item-content">
            <el-row>
              <el-col class="row-lable-name" :span="8">乱占：</el-col>
              <el-col :span="10">
                <el-select ref="select" v-model="data.luanZhanLevel">
                  <el-option v-for="item in luanzhanOptions" :key="item.name" :label="item.name" :value="item.scoreStr">
                  </el-option> </el-select
              ></el-col>
            </el-row>
            <el-row v-if="data.luanZhanPngList && data.luanZhanPngList.length > 0" class="png">
              <el-col :span="10" :offset="8">
                <el-image
                  style="width: 90px; height: 90px"
                  fit="scale-down"
                  :src="data.luanZhanPngList[0]"
                  :preview-src-list="data.luanZhanPngList"
                >
                </el-image>
              </el-col>
            </el-row>
            <el-row v-if="data.luanZhanDesc">
              <el-col class="row-lable-name" :span="8">描述：</el-col>
              <el-col :span="10">
                <el-input v-model="data.luanZhanDesc" type="textarea" :rows="2"> </el-input>
              </el-col>
            </el-row>
            <el-row>
              <el-col class="row-lable-name" :span="8">扣分值：</el-col>
              <el-col :span="10">{{ getIndicatorScore(data.luanZhanLevel) }}</el-col>
            </el-row>
            <el-row>
              <el-col class="row-lable-name" :span="8">参与此次评价：</el-col>
              <div>
                <el-radio v-model="data.luanZhanJoinFlag" :label="true">是</el-radio>
                <el-radio v-model="data.luanZhanJoinFlag" :label="false">否</el-radio>
              </div>
            </el-row>
          </div>
          <!-- 乱建 -->
          <div v-if="data.luanJianLevel" class="item-content">
            <el-row>
              <el-col class="row-lable-name" :span="8">乱建：</el-col>
              <el-col :span="10">
                <el-select ref="select" v-model="data.luanJianLevel">
                  <el-option v-for="item in luanjianOptions" :key="item.name" :label="item.name" :value="item.scoreStr">
                  </el-option> </el-select
              ></el-col>
            </el-row>

            <el-row v-if="data.luanJianPngCodeList && data.luanJianPngCodeList.length > 0" class="png">
              <el-col :span="10" :offset="8">
                <el-image
                  style="width: 90px; height: 90px"
                  fit="scale-down"
                  :src="data.luanJianPngCodeList[0]"
                  :preview-src-list="data.luanJianPngCodeList"
                >
                </el-image>
              </el-col>
            </el-row>

            <el-row v-if="data.luanJianDesc">
              <el-col class="row-lable-name" :span="8">描述：</el-col>
              <el-col :span="10">
                <el-input v-model="data.luanJianDesc" type="textarea" :rows="2"> </el-input>
              </el-col>
            </el-row>

            <el-row>
              <el-col class="row-lable-name" :span="8">扣分值：</el-col>
              <el-col :span="10"> {{ getIndicatorScore(data.luanJianLevel) }}</el-col>
            </el-row>
            <el-row>
              <el-col class="row-lable-name" :span="8">参与此次评价：</el-col>
              <div>
                <el-radio v-model="data.luanJianJoinFlag" :label="true">是</el-radio>
                <el-radio v-model="data.luanJianJoinFlag" :label="false">否</el-radio>
              </div>
            </el-row>
          </div>
          <!-- 乱弃 -->
          <div v-if="data.luanQiLevel" class="item-content">
            <el-row>
              <el-col class="row-lable-name" :span="8">乱弃：</el-col>
              <el-col :span="10">
                <el-select ref="select" v-model="data.luanQiLevel">
                  <el-option v-for="item in luanqiOptions" :key="item.name" :label="item.name" :value="item.scoreStr">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>

            <el-row v-if="data.luanQiPngList && data.luanQiPngList.length > 0" class="png">
              <el-col :span="10" :offset="8">
                <el-image
                  style="width: 90px; height: 90px"
                  fit="scale-down"
                  :src="data.luanQiPngList[0]"
                  :preview-src-list="data.luanQiPngList"
                >
                </el-image>
              </el-col>
            </el-row>
            <el-row v-if="data.luanQiDesc">
              <el-col class="row-lable-name" :span="8">描述：</el-col>
              <el-col :span="10">
                <el-input v-model="data.luanQiDesc" type="textarea" :rows="2"> </el-input>
              </el-col>
            </el-row>

            <el-row>
              <el-col class="row-lable-name" :span="8">扣分值：</el-col>
              <el-col :span="10">{{ getIndicatorScore(data.luanQiLevel) }}</el-col>
            </el-row>

            <el-row>
              <el-col class="row-lable-name" :span="8">参与此次评价：</el-col>
              <div>
                <el-radio v-model="data.luanQiJoinFlag" :label="true">是</el-radio>
                <el-radio v-model="data.luanQiJoinFlag" :label="false">否</el-radio>
              </div>
            </el-row>
          </div>
        </div>
      </div>

      <el-row>
        <div class="add-box" @click="addNew">
          <span><el-icon class="el-icon-plus" style="margin-right: 8px"></el-icon>添加新数据</span>
        </div>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { AnPoHuanJingZhengZhiChengDu } from '@/api/target/riverTarget.js'
  import { File } from '@/api/file/index.js'
  import IndicatorMap from '../indicatorMap.vue'
  import { getIndicator } from '../indicatorScore'
  export default {
    // 岸坡稳定性
    name: 'AnPoHuanJing',
    components: { IndicatorMap },
    props: {
      schemeId: {
        type: Number,
        default: 1
      },
      targetData: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        innerVisible: false,

        flag: true,
        detailList: [],
        finalScore: null,
        id: null,

        indicatorDefinition: false,
        anchor: '',

        luankengOptions: [
          {
            name: '面积<200㎡',
            scoreStr: 'luanKen_small',
            score: -1
          },
          {
            name: '面积200~1000㎡',
            scoreStr: 'luanKen_middle',
            score: -3
          },
          {
            name: '面积>1000㎡',
            scoreStr: 'luanKen_weight',
            score: -5
          }
        ],
        luanzhanOptions: [
          {
            name: '长度<5m',
            scoreStr: 'luanZhan_small',
            score: -1
          },
          {
            name: '长度5~50m',
            scoreStr: 'luanZhan_middle',
            score: -3
          },
          {
            name: '长度>50m',
            scoreStr: 'luanZhan_weight',
            score: -5
          }
        ],
        luanjianOptions: [
          {
            name: '面积<5㎡',
            scoreStr: 'luanJian_small',
            score: -1
          },
          {
            name: '面积5~20㎡',
            scoreStr: 'luanJian_middle',
            score: -3
          },
          {
            name: '面积>20㎡',
            scoreStr: 'luanJian_weight',
            score: -5
          }
        ],
        luanqiOptions: [
          {
            name: '面积<5㎡',
            scoreStr: 'luanQi_small',
            score: -1
          },
          {
            name: '面积5~100㎡',
            scoreStr: 'luanQi_middle',
            score: -3
          },
          {
            name: '面积>100㎡',
            scoreStr: 'luanQi_weight',
            score: -5
          }
        ]
      }
    },
    computed: {},
    watch: {
      targetData: {
        immediate: true,
        handler(val, oldval) {
          if (JSON.stringify(val) !== '{}') {
            this.loadData(val)
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      getIndicatorName(str) {
        const res = getIndicator(str)
        return res.name
      },
      getIndicatorScore(str) {
        const res = getIndicator(str)
        return res.score
      },
      /**
       * @description 指标描述展示
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      openTip(val) {
        this.anchor = val
        this.indicatorDefinition = true
      },
      // /数据初始化加载
      loadData(data) {
        console.log('<== loadData ==>', data)
        this.formmater(data.detailList)
        this.finalScore = data.scoreVal
        this.id = data.id
      },
      // 获取图片列表
      async formmater(data) {
        this.detailList = []
        data.forEach(async (item) => {
          // 乱垦
          if (item.luanKenPng && !item.luanKenPng.includes('tmp')) {
            item.luanKenPngList = []
            let arr = item.luanKenPng.split(',')
            let promise = []
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.luanKenPngList.push(res.data.data)
              })
              promise.push(a)
            })
            await Promise.all(promise)
          }
          // 乱占
          if (item.luanZhanPng && !item.luanZhanPng.includes('tmp')) {
            item.luanZhanPngList = []
            let arr = item.luanZhanPng.split(',')
            let promise = []
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.luanZhanPngList.push(res.data.data)
              })
              promise.push(a)
            })
            await Promise.all(promise)
          }
          // 乱建
          if (item.luanJianPng && !item.luanJianPng.includes('tmp')) {
            item.luanJianPngCodeList = []
            let arr = item.luanJianPng.split(',')
            let promise = []
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.luanJianPngCodeList.push(res.data.data)
              })
              promise.push(a)
            })
            await Promise.all(promise)
          }
          // 乱弃
          if (item.luanQiPng && !item.luanQiPng.includes('tmp')) {
            item.luanQiPngList = []
            let arr = item.luanQiPng.split(',')
            let promise = []
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.luanQiPngList.push(res.data.data)
              })
              promise.push(a)
            })
            await Promise.all(promise)
          }
          this.detailList.push(item)
        })
      },
      //关闭组件并改变父级 targetFlag 值
      close() {
        this.$emit('changeFlag')
      },

      addNew() {
        this.innerVisible = true
      },
      // 保存监测点
      saveAddObj(data) {
        let param = {
          schemeId: this.schemeId,
          detailList: [data]
        }
        console.log('param', param)
        AnPoHuanJingZhengZhiChengDu.saveInfo(param).then((res) => {
          AnPoHuanJingZhengZhiChengDu.find(res.data.data).then((res) => {
            this.loadData(res.data.data)
          })
        })

        this.innerVisible = false
      },
      // 取消保存监测点
      cancelAddObj() {
        this.innerVisible = false
      },

      //数据保存
      submit() {
        let param = {
          id: this.id,
          detailList: this.detailList
        }
        console.log(param)
        AnPoHuanJingZhengZhiChengDu.saveInfo(param).then((res) => {
          this.close()
          this.$emit('clearPopup')
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-height: 500px;
    overflow-y: scroll;

    border-bottom: 1px solid rgba(186, 186, 186, 0.821);
  }

  .item-content {
    width: 80%;
    height: 200px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    border-bottom: 0.5px solid rgba(186, 186, 186, 0.221);
    padding: 20px 0;
  }

  .row-lable-name {
    font-weight: 600;
    font-size: 14px;
  }
</style>
