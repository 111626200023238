<template>
  <div class="create-pro-page">
    <Map
      ref="mapIndicator"
      @getMobileIndicatorWKT="getMobileIndicatorWKT"
      @getPrevenFloodWKT="getPrevenFloodWKT"
      @getEcologicalBankLineWKT="getEcologicalBankLineWKT"
      @getManagementScopeLineWKT="getManagementScopeLineWKT"
      @getAnPoZhiBeiFuGaiDuWKT="getAnPoZhiBeiFuGaiDuWKT"
      @getanXianKaiFaLiYongLvWKT="getanXianKaiFaLiYongLvWKT"
      @gethuBinDaiZhiBeiFuGaiDuWKT="gethuBinDaiZhiBeiFuGaiDuWKT"
      @getWaterQualityWKT="getWaterQualityWKT"
    ></Map>

    <!-- 岸坡稳定性、岸坡环境整治程度、水体整洁程度、景观舒适度 -->
    <div v-if="showAddMobileIndicator" class="siteForm">
      <div class="siteFormTitle">{{ FromObj.name }}</div>

      <!-- 基础信息 -->
      <div class="site-info-form">
        <el-form ref="FromObj" :model="FromObj" size="small" label-width="120px">
          <el-row :gutter="20">
            <el-form-item label="经度：" prop="lon">
              <el-input
                v-model="FromObj.lon"
                disabled
                placeholder="点击地图生成经纬度"
                clearable
                :style="{ width: '80%' }"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="纬度：" prop="lat">
              <el-input
                v-model="FromObj.lat"
                disabled
                placeholder="点击地图生成经纬度"
                clearable
                :style="{ width: '80%' }"
              >
              </el-input>
            </el-form-item>

            <el-form-item v-if="this.formData.showHeDuan" label="起止位置：" prop="desDesc">
              <el-input v-model="FromObj.desDesc" placeholder="请输入河段起始位置" clearable :style="{ width: '80%' }">
              </el-input>
              <el-input
                v-model="FromObj.startDesc"
                placeholder="请输入河段终止位置"
                clearable
                :style="{ width: '80%', marginTop: '10px' }"
              >
              </el-input>
            </el-form-item>
          </el-row>

          <el-row v-for="item in formData.indexList" :key="item.id" :gutter="20">
            <el-form-item v-if="item.show" :label="item.name">
              <!-- 选择 -->
              <template v-if="item.type === 1">
                <el-select
                  v-model="FromObj[item.value]"
                  clearable
                  placeholder="请选择"
                  @change="changeValue(item, FromObj[item.value])"
                >
                  <el-option v-for="i in item.data" :key="i.name" :label="i.name" :value="i.value"></el-option>
                </el-select>
              </template>
              <!-- 填空 -->
              <el-input
                v-if="item.type === 2"
                v-model="FromObj[item.value]"
                placeholder="请输入"
                clearable
                :style="{ width: '80%' }"
              >
              </el-input>
            </el-form-item>

            <el-form-item
              v-if="
                item.show &&
                FromObj[item.value] !== undefined &&
                FromObj[item.value] !== '' &&
                FromObj[item.value] !== null
              "
              label="现场描述"
            >
              <el-input
                v-model="FromObj[item.des]"
                style="width: 80%"
                type="textarea"
                :rows="2"
                placeholder="请输入现场描述"
              >
              </el-input>
            </el-form-item>

            <el-form-item
              v-if="
                item.show &&
                FromObj[item.value] !== undefined &&
                FromObj[item.value] !== '' &&
                FromObj[item.value] !== null
              "
            >
              <!-- :query-ext="queryExt" -->
              <MyUploadImage
                ref="uploadImages"
                :attachs="FromObj[item.filePath]"
                :image-type="item.filePath"
                @getAttachs="getAttachs"
              />
            </el-form-item>
          </el-row>
        </el-form>
      </div>

      <div style="float: right; margin-right: 20px; padding-top: 20px">
        <el-button @click="cancelAddMobileIndicator">取消</el-button>
        <el-button size="primary" @click="saveAddMobileIndicator">保存</el-button>
      </div>
    </div>

    <!-- 防洪-->
    <div v-if="showPrevenFlood" class="siteForm">
      <div class="siteFormTitle">防洪达标率</div>

      <!-- 基础信息 -->
      <div class="site-info-form">
        <el-form ref="prevenFlood" :model="prevenFloodObj" size="small" label-width="120px">
          <el-row :gutter="20">
            <el-form-item label="名称：" prop="name">
              <el-input v-model="prevenFloodObj.name" placeholder="请输入名称" clearable :style="{ width: '80%' }">
              </el-input>
            </el-form-item>
            <el-form-item label="经度：" prop="lon">
              <el-input
                v-model="prevenFloodObj.lon"
                placeholder="点击地图生成经纬度"
                clearable
                :style="{ width: '80%' }"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="纬度：" prop="lat">
              <el-input
                v-model="prevenFloodObj.lat"
                placeholder="点击地图生成经纬度"
                clearable
                :style="{ width: '80%' }"
              >
              </el-input>
            </el-form-item>

            <el-form-item label="防洪标准：" prop="standard">
              <el-input
                v-model="prevenFloodObj.standard"
                placeholder="请输入防洪标准"
                clearable
                :style="{ width: '80%' }"
              >
              </el-input>
            </el-form-item>

            <el-form-item label="设计洪水：" prop="designHeight">
              <el-input
                v-model="prevenFloodObj.designHeight"
                type="number"
                placeholder="请输入设计洪水"
                clearable
                :style="{ width: '80%' }"
              >
              </el-input>
            </el-form-item>

            <el-form-item label="堤防高程：" prop="dikeHeight">
              <el-input
                v-model="prevenFloodObj.dikeHeight"
                type="number"
                placeholder="请输入堤防高程"
                clearable
                :style="{ width: '80%' }"
              >
              </el-input>
            </el-form-item>
          </el-row>
        </el-form>
      </div>

      <div style="float: right; margin-right: 20px; padding-top: 20px">
        <el-button @click="cancelPrevenFloodObj">取消</el-button>
        <el-button size="primary" @click="savePrevenFloodObj">保存</el-button>
      </div>
    </div>

    <!-- 生态岸坡比例 -->
    <div v-if="showEcologicalBank" class="siteForm">
      <div class="siteFormTitle">生态岸坡比例</div>

      <!-- 基础信息 -->
      <div class="site-info-form">
        <el-form ref="EcologicalBankObj" :model="EcologicalBankObj" size="small" label-width="120px">
          <el-row :gutter="20">
            <el-form-item label="岸线长度：" prop="length">
              <el-input v-model="EcologicalBankObj.length" type="number" clearable :style="{ width: '80%' }"> </el-input
              >m
            </el-form-item>
            <el-form-item label="岸线坐标数据：" prop="name">
              <el-input v-model="EcologicalBankObj.geom" disabled clearable :style="{ width: '80%' }" type="textarea">
              </el-input>
            </el-form-item>
          </el-row>
        </el-form>
      </div>

      <div style="float: right; margin-right: 20px; padding-top: 20px">
        <el-button @click="cancelAddPoint2">取消</el-button>
        <el-button size="primary" @click="saveInfo2">保存</el-button>
      </div>
    </div>

    <!-- 管理（保护）范围划定率 -->
    <div v-if="showManagementScope" class="siteForm">
      <div class="siteFormTitle">管理（保护）范围划定率</div>

      <div class="site-info-form">
        <el-form ref="prevenFlood" :model="managementScopeObj" size="small" label-width="120px">
          <el-row :gutter="20">
            <el-form-item label="起点：" prop="startPos">
              <el-input
                v-model="managementScopeObj.startPos"
                disabled
                placeholder="请输入起点："
                clearable
                :style="{ width: '80%' }"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="终点" prop="endPos">
              <el-input
                v-model="managementScopeObj.endPos"
                disabled
                placeholder="请输入终点："
                clearable
                :style="{ width: '80%' }"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="长度" prop="lat">
              <el-input
                v-model="managementScopeObj.number"
                type="number"
                placeholder="请输入长度："
                clearable
                :style="{ width: '80%' }"
              ></el-input>
            </el-form-item>
          </el-row>
        </el-form>
      </div>

      <div style="float: right; margin-right: 20px; padding-top: 20px">
        <el-button @click="cancelAddPoint3">取消</el-button>
        <el-button size="primary" @click="saveInfo3">保存</el-button>
      </div>
    </div>

    <!-- 岸坡植被覆盖度 -->
    <div v-if="showAnPoZhiBeiFuGaiDu" class="siteForm">
      <div class="siteFormTitle">岸坡植被覆盖度</div>

      <!-- 基础信息 -->
      <div class="site-info-form">
        <el-form ref="anPoZhiBeiFuGaiDu" :model="anPoZhiBeiFuGaiDuObj" size="small" label-width="120px">
          <el-row :gutter="20">
            <el-form-item label="河段名称：" prop="pos1">
              <el-input v-model="anPoZhiBeiFuGaiDuObj.name" clearable :style="{ width: '80%' }"> </el-input>
            </el-form-item>

            <el-form-item label="起始pos1：" prop="pos1">
              <el-input v-model="anPoZhiBeiFuGaiDuObj.pos1" disabled clearable :style="{ width: '80%' }"> </el-input>
            </el-form-item>
            <el-form-item label="结束pos2：" prop="pos2">
              <el-input v-model="anPoZhiBeiFuGaiDuObj.pos2" disabled clearable :style="{ width: '80%' }"> </el-input>
            </el-form-item>
            <el-form-item label="长度：" prop="length">
              <el-input v-model="anPoZhiBeiFuGaiDuObj.length" clearable :style="{ width: '80%' }"> </el-input>m
            </el-form-item>
            <el-form-item label="岸线坐标数据：" prop="name">
              <el-input
                v-model="anPoZhiBeiFuGaiDuObj.line"
                disabled
                clearable
                :style="{ width: '80%' }"
                type="textarea"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <MyUploadImage ref="uploadImages" :query-ext="queryExt" :attachs="attachs" />
            </el-form-item>
          </el-row>
        </el-form>
      </div>

      <div style="float: right; margin-right: 20px; padding-top: 20px">
        <el-button @click="cancelAddPoint4">取消</el-button>
        <el-button size="primary" @click="saveInfo4">保存</el-button>
      </div>
    </div>

    <!-- 岸线开发利用率 -->
    <div v-if="showanXianKaiFaLiYongLv" class="siteForm">
      <div class="siteFormTitle">岸线开发利用率——添加线段</div>

      <!-- 基础信息 -->
      <div class="site-info-form">
        <el-form ref="anPoZhiBeiFuGaiDu" :model="anXianKaiFaLiYongLvObj" size="small" label-width="120px">
          <el-row :gutter="20">
            <el-form-item label="岸线长度：" prop="length">
              <el-input v-model="anXianKaiFaLiYongLvObj.length" type="number" clearable :style="{ width: '80%' }">
              </el-input
              >m
            </el-form-item>

            <el-form-item label="已开发利用长度：" prop="usedLength">
              <el-input
                v-model.number="anXianKaiFaLiYongLvObj.usedLength"
                type="number"
                clearable
                :style="{ width: '80%' }"
              >
              </el-input
              >m
            </el-form-item>

            <el-form-item label="岸线坐标数据：" prop="name">
              <el-input
                v-model="anXianKaiFaLiYongLvObj.geom"
                disabled
                clearable
                :style="{ width: '80%' }"
                type="textarea"
              >
              </el-input>
            </el-form-item>
          </el-row>
        </el-form>
      </div>

      <div style="float: right; margin-right: 20px; padding-top: 20px">
        <el-button @click="cancelAddPoint5">取消</el-button>
        <el-button size="primary" @click="saveInfo5">保存</el-button>
      </div>
    </div>

    <!-- 湖滨带植被覆盖度 -->
    <div v-if="showHuBinDaiZhiBeiFuGaiDu" class="siteForm">
      <div class="siteFormTitle">湖滨带植被覆盖度</div>

      <!-- 基础信息 -->
      <div class="site-info-form">
        <el-form ref="anPoZhiBeiFuGaiDu" :model="HuBinDaiZhiBeiFuGaiDuObj" size="small" label-width="120px">
          <el-row :gutter="20">
            <el-form-item label="河段名称：" prop="pos1">
              <el-input v-model="HuBinDaiZhiBeiFuGaiDuObj.name" clearable :style="{ width: '80%' }"> </el-input>
            </el-form-item>

            <el-form-item label="起始pos1：" prop="pos1">
              <el-input v-model="HuBinDaiZhiBeiFuGaiDuObj.pos1" disabled clearable :style="{ width: '80%' }">
              </el-input>
            </el-form-item>
            <el-form-item label="结束pos2：" prop="pos2">
              <el-input v-model="HuBinDaiZhiBeiFuGaiDuObj.pos2" disabled clearable :style="{ width: '80%' }">
              </el-input>
            </el-form-item>
            <el-form-item label="长度：" prop="length">
              <el-input v-model.number="HuBinDaiZhiBeiFuGaiDuObj.length" clearable :style="{ width: '80%' }"> </el-input
              >m
            </el-form-item>
            <el-form-item label="岸线坐标数据：" prop="name">
              <el-input
                v-model="HuBinDaiZhiBeiFuGaiDuObj.line"
                disabled
                clearable
                :style="{ width: '80%' }"
                type="textarea"
              >
              </el-input>
            </el-form-item>
          </el-row>
        </el-form>
      </div>

      <div style="float: right; margin-right: 20px; padding-top: 20px">
        <el-button @click="cancelAddPoint6">取消</el-button>
        <el-button size="primary" @click="saveInfo6">保存</el-button>
      </div>
    </div>

    <!-- 水质优劣-->
    <div v-if="showWaterQuality" class="siteForm">
      <div class="siteFormTitle">水质优劣</div>

      <!-- 基础信息 -->
      <div class="site-info-form">
        <el-form ref="prevenFlood" :model="WaterQualityObj" size="small" label-width="120px">
          <el-row :gutter="20">
            <el-form-item label="名称：" prop="name">
              <el-input v-model="WaterQualityObj.name" placeholder="请输入名称" clearable :style="{ width: '80%' }">
              </el-input>
            </el-form-item>
            <el-form-item label="经度：" prop="lon">
              <el-input
                v-model="WaterQualityObj.lon"
                placeholder="点击地图生成经纬度"
                clearable
                :style="{ width: '80%' }"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="纬度：" prop="lat">
              <el-input
                v-model="WaterQualityObj.lat"
                placeholder="点击地图生成经纬度"
                clearable
                :style="{ width: '80%' }"
              >
              </el-input>
            </el-form-item>
          </el-row>
        </el-form>
      </div>

      <div style="float: right; margin-right: 20px; padding-top: 20px">
        <el-button @click="cancelWaterQualityObj">取消</el-button>
        <el-button size="primary" @click="saveWaterQualityObj">保存</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import Map from '@/components/map/mapIndicator'
import MobileIndicator from './mobileIndicator'

export default {
  name: 'IndicatorMap',
  components: {
    Map
  },
  props: {
    type: {
      type: String,
      default: ''
    },

    EcologicalBank: {
      type: Object,
      default: () => {}
    },
    anPoZhiBeiFuGaiDu: {
      type: Object,
      default: () => {}
    },
    anXianKaiFaLiYongLv: {
      type: Object,
      default: () => {}
    },
    HuBinDaiZhiBeiFuGaiDu: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      //岸坡稳定性、岸坡环境整治
      showAddMobileIndicator: false,
      FromObj: {},

      //防洪达标率
      showPrevenFlood: false,
      //防洪达标率
      prevenFloodObj: {
        name: '',
        standard: '',
        designHeight: '',
        dikeHeight: '',
        geom: ''
      },

      //岸坡植被覆盖度 线段
      showAnPoZhiBeiFuGaiDu: false,
      anPoZhiBeiFuGaiDuObj: this.anPoZhiBeiFuGaiDu,
      queryExt: {},
      attachs: '',

      //生态岸坡比例
      showEcologicalBank: false,
      EcologicalBankObj: this.EcologicalBank,

      //管理（保护）范围划定率
      showManagementScope: false,
      managementScopeObj: {
        startPos: null,
        endPos: null,
        number: null
      },

      //岸线开发利用率
      showanXianKaiFaLiYongLv: false,
      anXianKaiFaLiYongLvObj: this.anXianKaiFaLiYongLv,

      //湖滨带植被覆盖度
      showHuBinDaiZhiBeiFuGaiDu: false,
      HuBinDaiZhiBeiFuGaiDuObj: this.HuBinDaiZhiBeiFuGaiDu,

      //水质优劣
      showWaterQuality: false,
      WaterQualityObj: {
        name: ''
      }
    }
  },
  computed: {},
  watch: {},
  created() {},

  mounted() {
    // 岸坡稳定性
    if (this.type === 'AnPoWenDingXing') {
      const mobileIndicator = new MobileIndicator()
      this.formData = mobileIndicator.getAnPoWenDingXing()
      this.FromObj = {
        desDesc: '',
        startDesc: '',

        anPoType: '',
        anPoPngCode: '',
        anPoDesc: '',

        anPoZhouBianType: '',
        anPoZhouBianPngCode: '',
        anPoZhouBianDesc: '',

        wenDingXingFlag: '',
        wenDingXingPngCode: '',
        wenDingXingDesc: '',

        anPoShuiTuLiuShiDesc: '',
        anPoShuiTuLiuShiLevel: '',
        anPoShuiTuLiuShiPngCode: '',

        binAnChongShuaDesc: '',
        binAnChongShuaLevel: '',
        binAnChongShuaPngCode: '',

        anTaDesc: '',
        anTaLevel: '',
        anTaPngCode: '',

        preEvaluate: '',
        preEvaluateDesc: '',
        preEvaluatePngCode: '',

        delayFlag: false
      }
      this.toShowAddMobileIndicator()
    }
    // 岸坡环境整治
    if (this.type === 'anPoHuanJingZhengZhi') {
      const mobileIndicator = new MobileIndicator()
      this.formData = mobileIndicator.getAnPoHuanJingZhengZhi()
      this.FromObj = {
        lat: '',
        lon: '',
        luanKenDesc: '',
        luanKenLevel: '',
        luanKenPng: '',

        luanZhanDesc: '',
        luanZhanLevel: '',
        luanZhanPng: '',

        luanJianDesc: '',
        luanJianLevel: '',
        luanJianPng: '',

        luanQiDesc: '',
        luanQiLevel: '',
        luanQiPng: '',

        delayFlag: false
      }
      this.toShowAddMobileIndicator()
    }
    // 水体整洁程度
    if (this.type === 'ShuiTiZhengJi') {
      const mobileIndicator = new MobileIndicator()
      this.formData = mobileIndicator.getShuiTiZhengJieChengDu()
      this.FromObj = {
        lat: '',
        lon: '',

        xiuHeWeiDesc: '',
        xiuHeWeiLevel: '',
        xiuHeWeiPng: '',
        piaoFuWuDesc: '',
        piaoFuWuLevel: '',
        piaoFuWuPng: '',
        touMingDuDesc: '',
        touMingDuLevel: '',
        touMingDuPng: '',

        delayFlag: false
      }
      this.toShowAddMobileIndicator()
    }
    // 景观舒适度
    if (this.type === 'Landscape') {
      const mobileIndicator = new MobileIndicator()
      this.formData = mobileIndicator.getJingGuanShuShiDu()
      this.FromObj = {
        lat: '',
        lon: '',

        gsxDesc: '',
        gsxLevel: '',
        gsxPngCode: '',
        qmdDesc: '',
        qmdLevel: '',
        qmdPngCode: '',
        swhDesc: '',
        swhLevel: '',
        swhPngCode: '',

        delayFlag: false
      }
      this.toShowAddMobileIndicator()
    }
    // 口门畅通
    if (this.type === 'kouMenChangTongLv') {
      const mobileIndicator = new MobileIndicator()
      this.formData = mobileIndicator.getKouMenChangTongLv()
      this.FromObj = {
        lat: '',
        lon: '',
        openFlag: '',
        kouMenPngCode: '',
        kouMenDesc: '',
        delayFlag: false
      }
      this.toShowAddMobileIndicator()
    }

    //防洪达标率 监测点
    if (this.type === 'prevenFlood') {
      this.prevenFlood()
    }
    //岸坡植被覆盖度 线段
    if (this.type === 'anPoZhiBeiFuGaiDu') {
      console.log('anPoZhiBeiFuGaiDuObj', this.anPoZhiBeiFuGaiDuObj)
      this.getanPoZhiBeiFuGaiDu()
    }
    //岸线开发利用率  线段
    if (this.type === 'anXianKaiFaLiYongLv') {
      this.getanXianKaiFaLiYongLv()
    }
    //生态岸坡比例 线段
    if (this.type === 'ecologicalBank') {
      this.ecologicalBank()
    }
    //管理（保护）范围划定率  线段
    if (this.type === 'managementScope') {
      this.managementScope()
    }
    //湖滨带植被覆盖度  线段
    if (this.type === 'huBinDaiZhiBeiFuGaiDu') {
      this.gethuBinDaiZhiBeiFuGaiDu()
    }
    //水质优劣程度
    if (this.type === 'waterQuality') {
      this.showWaterQuality = true
      this.$refs.mapIndicator.addMarkPoint('saveWaterQualityObj')
    }
  },

  methods: {
    //=============================================岸坡稳定性 岸坡环境整治=============================================
    toShowAddMobileIndicator() {
      this.showAddMobileIndicator = true
      this.$refs.mapIndicator.addMarkPoint('AddMobileIndicator')
    },

    getMobileIndicatorWKT(data) {
      this.$set(this.FromObj, 'lon', data.coordinate[0])
      this.$set(this.FromObj, 'lat', data.coordinate[1])
    },
    cancelAddMobileIndicator() {
      this.$refs.mapIndicator.cancelAddPointFrom()
      this.$emit('cancelAddObj')
    },
    saveAddMobileIndicator() {
      console.log(this.FromObj)
      if (this.type === 'AnPoWenDingXing' && this.FromObj.wenDingXingFlag == '') {
        this.FromObj.wenDingXingFlag = false
      }
      this.$emit('saveAddObj', this.FromObj)
      this.$message({
        message: '添加成功',
        center: true,
        type: 'success',
        duration: 3000
      })
    },

    //获取照片
    getAttachs(e) {
      console.log('getAttachs', e)
      this.$set(this.FromObj, e.imageType, e.attach)
    },

    //#region
    //=============================================防洪达标率=============================================
    //  添加监测点
    prevenFlood() {
      this.showPrevenFlood = true
      this.$refs.mapIndicator.addMarkPoint('prevenFlood')
    },
    //获取标记点经纬度
    getPrevenFloodWKT(data) {
      this.prevenFloodObj.geom = data.wkt
      this.$set(this.prevenFloodObj, 'lon', data.coordinate[0])
      this.$set(this.prevenFloodObj, 'lat', data.coordinate[1])
    },
    //保存
    savePrevenFloodObj() {
      this.$emit('savePrevenFloodObj', this.prevenFloodObj)
      this.$message({
        message: '添加成功',
        center: true,
        type: 'success',
        duration: 3000
      })
    },
    //取消添加监测点地图操作
    cancelPrevenFloodObj() {
      this.$refs.mapIndicator.cancelAddPointFrom()
      this.$emit('cancelPrevenFloodObj')
    },

    //=============================================岸坡植被覆盖度=============================================
    getanPoZhiBeiFuGaiDu() {
      this.showAnPoZhiBeiFuGaiDu = true
      this.$refs.mapIndicator.addMarkLine('anPoZhiBeiFuGaiDu')
    },
    //获取地理信息
    getAnPoZhiBeiFuGaiDuWKT(data) {
      this.$set(this.anPoZhiBeiFuGaiDuObj, 'line', data.wkt)
      this.$set(this.anPoZhiBeiFuGaiDuObj, 'pos1', data.pos1.toString())
      this.$set(this.anPoZhiBeiFuGaiDuObj, 'pos2', data.pos2.toString())
      this.$set(this.anPoZhiBeiFuGaiDuObj, 'length', data.length)
    },
    //保存
    saveInfo4() {
      this.attachs = this.$refs.uploadImages.getAttachs()
      console.log('this.attachs', this.attachs)
      if (
        this.anPoZhiBeiFuGaiDuObj.coverPngCode !== undefined &&
        this.anPoZhiBeiFuGaiDuObj.coverPngCode !== null &&
        this.anPoZhiBeiFuGaiDuObj.coverPngCode !== ''
      ) {
        this.anPoZhiBeiFuGaiDuObj.coverPngCode = this.anPoZhiBeiFuGaiDuObj.coverPngCode + ',' + this.attachs
      } else {
        this.anPoZhiBeiFuGaiDuObj.coverPngCode = this.attachs
      }
      console.log('this.anPoZhiBeiFuGaiDuObj', this.anPoZhiBeiFuGaiDuObj)

      this.$emit('saveAnPoZhiBeiFuGaiDuObj', this.anPoZhiBeiFuGaiDuObj)
      this.$message({
        message: '添加成功',
        center: true,
        type: 'success',
        duration: 3000
      })
    },
    //取消添加监测点地图操作
    cancelAddPoint4() {
      this.$emit('cancelAnPoZhiBeiFuGaiDuObj')
    },

    //=============================================岸线开发利用率=============================================
    getanXianKaiFaLiYongLv() {
      this.showanXianKaiFaLiYongLv = true
      this.$refs.mapIndicator.addMarkLine('anXianKaiFaLiYongLv')
    },
    getanXianKaiFaLiYongLvWKT(data) {
      this.anXianKaiFaLiYongLvObj.geom = data.wkt
      this.$set(this.anXianKaiFaLiYongLvObj, 'length', data.length)
    },
    saveInfo5() {
      this.$emit('saveanXianKaiFaLiYongLv', this.anXianKaiFaLiYongLvObj)
      this.$message({
        message: '添加成功',
        center: true,
        type: 'success',
        duration: 3000
      })
    },
    cancelAddPoint5() {
      this.$emit('cancelanXianKaiFaLiYongLv')
    },

    //=============================================生态岸坡比例=============================================
    // 添加线段
    ecologicalBank() {
      this.showEcologicalBank = true
      this.$refs.mapIndicator.addMarkLine('ecologicalBank')
    },
    //获取标记点经纬度
    getEcologicalBankLineWKT(data) {
      this.$set(this.EcologicalBankObj, 'geom', data.wkt)
      this.$set(this.EcologicalBankObj, 'length', data.length)
    },
    saveInfo2() {
      this.$emit('saveEcologicalBank', this.EcologicalBankObj)
      this.$message({
        message: '添加成功',
        center: true,
        type: 'success',
        duration: 3000
      })
    },
    cancelAddPoint2() {
      this.$emit('cancelEcologicalBank')
    },

    //=============================================管理（保护）范围划定率=============================================
    managementScope() {
      this.showManagementScope = true
      this.$refs.mapIndicator.addMarkLine('managementScope')
    },
    getManagementScopeLineWKT(data) {
      this.$set(this.managementScopeObj, 'geom', data.wkt)
      this.$set(this.managementScopeObj, 'startPos', data.startPos.toString())
      this.$set(this.managementScopeObj, 'endPos', data.endPos.toString())
      this.$set(this.managementScopeObj, 'number', data.length)
    },
    saveInfo3() {
      this.$emit('saveManagementScopeObj', this.managementScopeObj)
      this.$message({
        message: '添加成功',
        center: true,
        type: 'success',
        duration: 3000
      })
    },
    cancelAddPoint3() {
      this.$emit('cancelManagementScopeObj')
    },

    //============================================= 湖滨带植被覆盖度=============================================
    gethuBinDaiZhiBeiFuGaiDu() {
      this.showHuBinDaiZhiBeiFuGaiDu = true
      this.$refs.mapIndicator.addMarkLine('huBinDaiZhiBeiFuGaiDu')
    },
    //获取地理信息
    gethuBinDaiZhiBeiFuGaiDuWKT(data) {
      this.$set(this.HuBinDaiZhiBeiFuGaiDuObj, 'line', data.wkt)
      this.$set(this.HuBinDaiZhiBeiFuGaiDuObj, 'pos1', data.pos1.toString())
      this.$set(this.HuBinDaiZhiBeiFuGaiDuObj, 'pos2', data.pos2.toString())
      this.$set(this.HuBinDaiZhiBeiFuGaiDuObj, 'length', data.length)
    },
    //保存
    saveInfo6() {
      this.$emit('saveHuBinDaiZhiBeiFuGaiDu', this.HuBinDaiZhiBeiFuGaiDuObj)
      this.$message({
        message: '添加成功',
        center: true,
        type: 'success',
        duration: 3000
      })
    },
    //取消添加监测点地图操作
    cancelAddPoint6() {
      this.$emit('cancelHuBinDaiZhiBeiFuGaiDu')
    },

    //============================================= 水质优劣=============================================
    saveWaterQualityObj() {
      this.$emit('saveWaterQualityObj', this.WaterQualityObj)
      this.$message({
        message: '添加成功',
        center: true,
        type: 'success',
        duration: 3000
      })
    },
    getWaterQualityWKT(data) {
      this.WaterQualityObj.geom = data.wkt
      this.$set(this.WaterQualityObj, 'lon', data.coordinate[0])
      this.$set(this.WaterQualityObj, 'lat', data.coordinate[1])
    },
    cancelWaterQualityObj() {
      this.$refs.mapIndicator.cancelAddPointFrom()
      this.$emit('cancelWaterQualityObj')
    },
    //#endregion

    //============================================= changeValue =============================================
    changeValue(e, j) {
      if (e.showType) {
        //点击了隐藏指标项
        if (e.showTypeValue === j) {
          console.log('hidden', e.showList)
          console.log(this.formData)
          this.formData.indexList.forEach((i) => {
            if (e.showList.includes(i.id)) {
              i.show = true
            }
          })
        } else {
          this.formData.indexList.forEach((i) => {
            if (e.showList.includes(i.id)) {
              i.show = false
            }
          })
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.create-pro-page {
  width: 100%;
  height: 700px;
  position: relative;
  color: #000;

  /deep/ .el-drawer.rtl {
    height: 89%;
    top: 86px;
    bottom: 0;
    right: 24px;

    .el-drawer__header {
      align-items: center;
      color: #72767b;
      display: flex;
      margin-bottom: 0px;
      padding: 24px 24px;
      font-size: large;
      font-weight: 600;
      border-bottom: 1px solid #eee;
    }

    .el-drawer__body {
      padding: 24px;
    }
  }
}

.left-info-box {
  position: absolute;
  left: 24px;
  top: 24px;
  width: 400px;
  height: calc(100% - 48px);
  display: flex;
  flex-direction: column;

  .pro-info {
    height: 12%;
    background-color: #fff;
    margin-bottom: 24px;
    overflow: hidden;

    .por-info-box {
      // height: 50px;
      // line-height: 50px;
      padding: 12px 24px;
    }
  }

  .basic-info {
    background-color: #fff;
    margin-bottom: 24px;

    .basic-info-box {
      padding: 24px;
    }
  }

  .evaluation-obj {
    flex: 1;
    background-color: #fff;
    overflow: hidden;

    .evaluation-obj-box {
      padding: 24px;
    }
  }
}

.title-box {
  height: 24px;
  line-height: 24px;
  display: flex;
  padding: 8px 0;
  justify-content: space-between;
  border-bottom: 0.5px solid #eee;

  .title-button-box {
    span {
      margin-right: 16px;
    }
  }
}

.info-label {
  margin-left: 120px;
}

.rigth-info-box {
  position: absolute;
  right: 24px;
  top: 24px;
  width: 500px;
  max-height: calc(100% - 48px);
  overflow: hidden;
  background-color: #fff;
}

.siteForm {
  width: 400px;
  height: 80%;
  overflow-y: scroll;

  background-color: white;
  position: absolute;
  top: 90px;
  right: 10px;
  z-index: 999;

  .siteFormTitle {
    font-size: 16px;
    font-weight: 600;
    margin-left: 24px;
    height: 34px;
    line-height: 34px;
    padding: 8px 0;
    border-bottom: 0.5px solid #eee;
  }
}

::v-deep ::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
  /* firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}
</style>
