<template>
  <div>
    <el-dialog
      title="供水水量保证率"
      :visible.sync="flag"
      width="30%"
      :before-close="close"
      :modal="false"
      :close-on-click-modal="false"
    >
      <div slot="title" class="dialog-header-title">
        <span class="title-name">
          供水水量保证率
          <i class="el-icon-question" @click="openTip('gsslbzl')"></i>
        </span>
        <span class="title-score">
          最终得分:
          <span v-if="finalScore">{{ finalScore.toFixed(1) }}</span>
          <span v-else>--</span>
        </span>
      </div>
      <Standrad
        :anchor="anchor"
        :show-flag="indicatorDefinition"
        @changFlag="() => (indicatorDefinition = !indicatorDefinition)"
      />
      <div class="body-content">
        <el-row type="flex" align="middle">
          <el-col :span="8" class="row-lable-name"><div>保证水位:</div> </el-col>
          <el-col :span="8">
            <el-input v-model="bzWaterLevel" type="number" style="width: 80%"></el-input>&nbsp;m
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="8" class="row-lable-name">计算分析说明:</el-col>
          <el-col :span="16">
            <el-input
              v-model="bzAnalysisDesc"
              type="textarea"
              placeholder="请输入计算说明(数据来源、得出此数据的简要说明)"
              :autosize="{ minRows: 2, maxRows: 2 }"
            ></el-input>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="8" class="row-lable-name"><div>达到保证水位:</div> </el-col>
          <el-col :span="8">
            <el-input v-model="dbWaterLevelDays" type="number" style="width: 80%" @blur="count"></el-input>&nbsp;天
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="8" class="row-lable-name">计算分析说明:</el-col>
          <el-col :span="16">
            <el-input
              v-model="dbAnalysisDesc"
              type="textarea"
              placeholder="请输入计算说明(数据来源、得出此数据的简要说明)"
              :autosize="{ minRows: 2, maxRows: 2 }"
            ></el-input>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="8" class="row-lable-name">附件:</el-col>
          <el-col :span="16">
            <MyUploadFile ref="uploadfile" :query-ext="queryExt" :attachs="attachs" />
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button class="btn-info" @click="close">取 消</el-button>
        <el-button class="btn-submit" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { WaterSupply } from '@/api/target/riverTarget.js'
  export default {
    // 供水水量保证率
    name: 'WaterSupply',
    components: {},
    props: {
      schemeId: {
        type: Number,
        default: 1
      },
      targetData: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        flag: true,

        bzWaterLevel: 0,
        bzAnalysisDesc: '',
        dbWaterLevelDays: 0,
        dbAnalysisDesc: '',
        finalScore: null,

        queryExt: {},
        attachs: '',
        id: null,

        indicatorDefinition: false,
        anchor: ''
      }
    },
    computed: {},
    watch: {
      targetData: {
        immediate: true,
        handler(val, oldval) {
          if (JSON.stringify(val) !== '{}') {
            this.loadData(val)
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description 指标描述展示
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      openTip(val) {
        this.anchor = val
        this.indicatorDefinition = true
      },
      /**
       * @description 数据初始化加载
       * @author hu_ty
       * @since
       * @param {object} data 有数据的指标信息内容
       *
       */
      loadData(data) {
        // console.log('<== loadData ==>', data)
        this.bzWaterLevel = data.bzWaterLevel
        this.bzAnalysisDesc = data.bzAnalysisDesc
        this.dbWaterLevelDays = data.dbWaterLevelDays
        this.dbAnalysisDesc = data.dbAnalysisDesc
        this.finalScore = data.scoreVal
        this.attachs = data.attachs
        this.queryExt = data.queryExt
        this.id = data.id
      },
      /**
       * @description  关闭组件并改变父级 targetFlag 值
       * @author hu_ty
       * @since 22-06-24
       * @param {*}
       *
       */
      close() {
        this.$emit('changeFlag')
      },
      /**
       * @description 计算供水水量保证率指标
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      count() {
        let score = (this.dbWaterLevelDays / 365) * 100
        switch (score >= 0) {
          case score > 100:
            this.finalScore = 100
            break
          case score >= 95:
            this.finalScore = 90 + ((score - 95) / 5) * 10
            break
          case score >= 90:
            this.finalScore = 80 + ((score - 90) / 5) * 10
            break
          case score >= 80:
            this.finalScore = 60 + ((score - 80) / 10) * 20
            break
          case score >= 60:
            this.finalScore = 40 + ((score - 60) / 20) * 20
            break
          case score >= 0:
            this.finalScore = (score / 60) * 40
            break
          default:
            this.finalScore = -1
        }
      },
      /**
       * @description
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      submit() {
        this.attachs = this.$refs.uploadfile.getAttachs()

        let param = {
          id: this.id,
          schemeId: this.schemeId,
          bzWaterLevel: this.bzWaterLevel,
          bzAnalysisDesc: this.bzAnalysisDesc,
          dbWaterLevelDays: this.dbWaterLevelDays,
          dbAnalysisDesc: this.dbAnalysisDesc,
          scoreVal: this.finalScore,
          attachs: this.attachs
        }
        WaterSupply.saveInfo(param).then((res) => {
          this.close()
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .body-content {
    .el-row {
      margin-bottom: 16px;
    }
  }
</style>
