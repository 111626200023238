<template>
  <div>
    <el-dialog
      title="管理（保护）范围划定率"
      :visible.sync="flag"
      width="568px"
      :before-close="close"
      :modal="false"
      top="80px"
      :close-on-click-modal="false"
    >
      <el-dialog
        v-if="innerVisible"
        width="80%"
        title="管理（保护）范围划定率"
        :visible.sync="innerVisible"
        append-to-body
        top="2%"
      >
        <IndicatorMap
          :type="'managementScope'"
          @saveManagementScopeObj="saveManagementScopeObj"
          @cancelManagementScopeObj="cancelManagementScopeObj"
        ></IndicatorMap>
      </el-dialog>

      <div slot="title" class="dialog-header-title">
        <span class="title-name">
          管理（保护）范围划定率
          <i class="el-icon-question" @click="openTip('hpglbhfwhdl')"></i>
        </span>
        <span class="title-score">
          最终得分:
          <span v-if="finalScore">{{ finalScore.toFixed(1) }}</span>
          <span v-else>--</span>
        </span>
      </div>
      <Standrad
        :anchor="anchor"
        :show-flag="indicatorDefinition"
        @changFlag="() => (indicatorDefinition = !indicatorDefinition)"
      />
      <el-row type="flex" align="middle">
        <el-col :span="8" class="row-lable-name"><div>已划定保护范围湖泊面积:</div> </el-col>
        <el-col :span="10">
          <el-input v-model="protectNumber" :style="{ width: '80%' }" type="number" @blur="countFinalScore"></el-input
          >&nbsp;m<sup>2</sup>
        </el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="8" class="row-lable-name">计算分析说明:</el-col>
        <el-col :span="16">
          <el-input
            v-model="protectAnalysisDesc"
            type="textarea"
            placeholder="请输入计算说明(数据来源、得出此数据的简要说明)"
            :autosize="{ minRows: 2, maxRows: 2 }"
          ></el-input>
        </el-col>
      </el-row>

      <el-row type="flex" align="middle">
        <el-col :span="8" class="row-lable-name"><div>湖泊正常蓄水位面积:</div> </el-col>
        <el-col :span="10">
          <el-input v-model="totalNumber" :style="{ width: '80%' }" type="number" @blur="countFinalScore"></el-input
          >&nbsp;m<sup>2</sup>
        </el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="8" class="row-lable-name">计算分析说明:</el-col>
        <el-col :span="16">
          <el-input
            v-model="totalAnalysisDesc"
            type="textarea"
            placeholder="请输入计算说明(数据来源、得出此数据的简要说明)"
            :autosize="{ minRows: 2, maxRows: 2 }"
          ></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-table
          :data="detailList"
          style="width: 100%; font-size: 12px; text-align: center"
          header-cell-class-name="table-header-cls"
        >
          <el-table-column prop="startPos" label="起点" align="center" header-align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.startPos" type="text" disabled></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="endPos" label="终点" align="center" header-align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.endPos" type="text" disabled></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="number" label="长度" align="center" header-align="center" width="90">
            <template slot-scope="scope">
              <el-input v-model="scope.row.number" type="number"></el-input>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" align="center" width="90">
            <template slot-scope="scope">
              <el-button v-if="!scope.row.id"   type="text" @click.native.prevent="deleteRow(scope.$index, detailList)">移除</el-button>

              <el-button
                v-if="scope.row.id"
                type="text"
                style="color: red"
                @click.native.prevent="trueDeleteRow(scope.row, detailList)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row>
        <div class="add-box" @click="addNew">
          <span><el-icon class="el-icon-plus" style="margin-right: 8px"></el-icon>前往地图添加</span>
        </div>
      </el-row>

      <el-row type="flex">
        <el-col :span="8" class="row-lable-name">附件:</el-col>
        <el-col :span="16">
          <MyUploadFile ref="uploadfile" :query-ext="queryExt" :attachs="attachs" />
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button class="btn-info" @click="close">取 消</el-button>
        <el-button class="btn-submit" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { ManagementScope } from '@/api/target/riverTarget'
  import IndicatorMap from '../indicatorMap.vue'
  export default {
    // 管理（保护）范围划定率
    name: 'ManagementScope',
    components: { IndicatorMap },
    props: {
      schemeId: {
        type: Number,
        default: 1
      },
      targetData: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        flag: true,
        detailList: [],
        protectNumber: null,
        protectAnalysisDesc: '',
        totalNumber: null,
        totalAnalysisDesc: '',
        finalScore: null,

        queryExt: {},
        attachs: '',
        id: null,

        innerVisible: false,

        indicatorDefinition: false,
        anchor: ''
      }
    },
    computed: {},
    watch: {
      targetData: {
        immediate: true,
        handler(val, oldval) {
          if (JSON.stringify(val) !== '{}') {
            this.loadData(val)
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description 指标描述展示
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      openTip(val) {
        this.anchor = val
        this.indicatorDefinition = true
      },
      /**
       * @description 数据初始化加载
       * @author hu_ty
       * @since
       * @param {object} data 有数据的指标信息内容
       *
       */
      loadData(data) {
        console.log('<== ManagementScope loadData ==>', data)

        this.detailList = data.detailList

        this.protectNumber = data.protectNumber
        this.protectAnalysisDesc = data.protectAnalysisDesc
        this.totalNumber = data.totalNumber
        this.totalAnalysisDesc = data.totalAnalysisDesc

        this.finalScore = data.scoreVal
        this.attachs = data.attachs
        this.queryExt = data.queryExt
        this.id = data.id
      },
      /**
       * @description  关闭组件并改变父级 targetFlag 值
       * @author hu_ty
       * @since 22-06-24
       * @param {*}
       *
       */
      close() {
        this.$emit('changeFlag')
      },
      /**
       * @todo
       * @description 管理（保护）范围新增
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      addNew() {
        this.innerVisible = true
      },
      saveManagementScopeObj(data) {
        console.log('saveManagementScopeObj', data)
        this.detailList.push(data)
        this.innerVisible = false
      },
      cancelManagementScopeObj(data) {
        this.innerVisible = false
      },
      /**
       * @description  删除行
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      deleteRow(index, rows) {
        rows.splice(index, 1)
      },

        trueDeleteRow(row) {
      console.log(row)
      this.$confirm('确定删除该数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          ManagementScope.deleteDetailById(row.id).then((res) => {
            ManagementScope.find(row.parentId).then((res) => {
              this.loadData(res.data.data)
            })
          })
        })
        .catch(() => {
          console.log('取消删除')
        })
    },
      /**
       * @description  最终得分计算
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      countFinalScore() {
        if (this.protectNumber && this.totalNumber) {
          let score
          let per = (this.protectNumber / this.totalNumber) * 100
          switch (true) {
            case per >= 100:
              score = 100
              break
            case per >= 90:
              score = 80 + ((per - 90) / 10) * 10
              break
            case per >= 75:
              score = 60 + ((per - 75) / 15) * 20
              break
            case per >= 60:
              score = 40 + ((per - 60) / 15) * 20
              break
            case per >= 0:
              score = (per / 60) * 40
              break
            default:
          }
          this.finalScore = score
        }
      },
      /**
       * @description  数据地图
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      submit() {
        this.attachs = this.$refs.uploadfile.getAttachs()

        let param = {
          attachs: this.attachs,
          protectNumber: Number(this.protectNumber),
          protectAnalysisDesc: this.protectAnalysisDesc,
          totalNumber: Number(this.totalNumber),
          totalAnalysisDesc: this.totalAnalysisDesc,
          id: this.id,
          schemeId: this.schemeId,
          detailList: this.detailList
        }

        console.log('param', param)
        ManagementScope.saveInfo(param).then((res) => {
          this.close()
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .el-row {
    margin-bottom: 16px;
  }
  /deep/ .el-table__row .cell > .el-input > input {
    border: 0;
  }
  /deep/ .el-table__row .el-input__inner {
    padding: 0;
    text-align: center;
  }
</style>
