<template>
  <div class="indicator-page">
    <div class="left-pro-info">
      <!-- <div class="top-title-box"> -->
      <div class="pro-container">
        <div>
          <el-select ref="select" v-model="proInfo.id" class="select" filterable @change="loadScheme(proInfo.id)">
            <el-option v-for="item in proInfoList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </div>
        <img class="angle" src="../../../assets/icon/project/angle-icon.svg" alt="" srcset="" />
      </div>
      <img style="margin: 0 16px" src="../../../assets/icon/project/split-line.svg" alt="" srcset="" />
      <!-- <div class="title-box">
          <MyTitle title="项目列表" />
        </div>
        <div class="pro-select-box">
          <div>名称:</div>
          <el-select v-model="proInfo.id" placeholder="请选择" filterable clearable @change="loadScheme(proInfo.id)">
            <el-option v-for="item in proInfoList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </div> -->
      <!-- </div> -->
      <div class="content-box">
        <div class="title-box">
          <MyTitle title="对象列表" />
        </div>
        <div class="obj-table">
          <el-table
            ref="schemeTable"
            :data="schemeTableData"
            highlight-current-row
            :cell-style="cellStyle"
            header-cell-class-name="table-header-cls"
            width="100%"
            height="70vh"
            @current-change="handleCurrentChange"
          >
            <el-table-column prop="name" label="对象名称"></el-table-column>
            <el-table-column prop="rlType" label="河湖类型" :formatter="rltypeIcon" width="80"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <Map ref="mapIndicatorEva"></Map>

    <div class="right-target-table">
      <div class="target-container">
        指标列表
        <img class="angle" src="../../../assets/icon/project/angle-icon.svg" alt="" srcset="" />
      </div>
      <img style="margin: 0 16px" src="../../../assets/icon/project/split-line.svg" alt="" srcset="" />
      <div class="top-title-box">
        <!-- <div class="title-box">
          <MyTitle title="指标列表" />
        </div> -->
        <div class="target-list">
          <el-table
            ref="targetList"
            :data="targetList"
            highlight-current-row
            height="80vh"
            header-cell-class-name="table-header-cls"
            @current-change="handleTargetChange"
          >
            <el-table-column prop="name" class-name="target-name" label="指标名称"></el-table-column>

            <el-table-column class-name="target-name" label="移动端采集">
              <template v-slot="scope">
                <div>{{ scope.row.mobile ? '是' : '否' }}</div>
              </template>
            </el-table-column>

            <el-table-column prop="scoreVal" label="得分" width="50">
              <template v-slot="scope">
                <div v-if="scope.row.scoreVal">
                  {{ scope.row.scoreVal == 100 ? scope.row.scoreVal : scope.row.scoreVal.toFixed(1) }}
                </div>
                <div v-else>0</div>
              </template>
            </el-table-column>

            <el-table-column label="操作" width="100">
              <template v-slot="scope">
                <div v-if="scope.row.showPos" style="cursor: pointer" @click.stop="showScorePoint(scope.row)">
                  查看采样点
                </div>
              </template></el-table-column
            >
          </el-table>
        </div>
      </div>
    </div>

    <FluvialFluidity
      v-if="targetFlag === 'fluvialFluidityFlag'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
    />
    <EcologicalWater
      v-if="targetFlag === 'ecologicalWaterFlag'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
    />
    <Phytoplankton
      v-if="targetFlag === 'phytoplankton'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
    />
    <BorneAlgae
      v-if="targetFlag === 'borneAlgae'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
    />
    <WaterSupply
      v-if="targetFlag === 'waterSupply'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
    />
    <Outlet v-if="targetFlag === 'outlet'" :target-data="targetData" :scheme-id="schemeId" @changeFlag="changeFlag" />
    <SedimentPollution
      v-if="targetFlag === 'sedimentPollution'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
    />
    <Benthic v-if="targetFlag === 'benthic'" :target-data="targetData" :scheme-id="schemeId" @changeFlag="changeFlag" />
    <EcoBank
      v-if="targetFlag === 'ecologicalBank'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
    />
    <WaterFunctionalArea
      v-if="targetFlag === 'waterFunctionalArea'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
    />
    <ManagementScope
      v-if="targetFlag === 'managementScope'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
    />
    <PreventFlood
      v-if="targetFlag === 'prevenFlood'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
    />
    <WaterQuality
      v-if="targetFlag === 'waterQuality'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
    />

    <WaterChange
      v-if="targetFlag === 'waterChange'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
    />
    <LakeEcologicalWater
      v-if="targetFlag === 'ecologicalWaterLake'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
    />
    <EnterLake
      v-if="targetFlag === 'enterLake'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
    />
    <WaterSurfaceDevelopment
      v-if="targetFlag === 'waterSurfaceDevelopment'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
    />
    <CyanobacteriaDensity
      v-if="targetFlag === 'cyanobacteriaDensity'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
    />
    <ManagementScopeLake
      v-if="targetFlag === 'managementScopeLake'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
    />
    <Nutrition
      v-if="targetFlag === 'nutrition'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
    />
    <Questionnaire
      v-if="targetFlag === 'questionnaire'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
    />

    <Landscape
      v-if="targetFlag === 'landscape'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
    />

    <AnPoWenDingXing
      v-if="targetFlag === 'anPoWenDingXingFlag'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
      @clearPopup="clearPopup"
    />

    <AnPoHuanJing
      v-if="targetFlag === 'anPoHuanJingZhengZhi'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
      @clearPopup="clearPopup"
    />

    <ShuiTiZhengJi
      v-if="targetFlag === 'shuiTiZhengJi'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
      @clearPopup="clearPopup"
    />

    <AnPoZhiBeiFuGaiDu
      v-if="targetFlag === 'anPoZhiBeiFuGaiDu'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
    />

    <AnXianKaiFaLiYongLv
      v-if="targetFlag === 'anXianKaiFaLiYongLv'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
    />

    <HuBinDaiZhiBeiFuGaiDu
      v-if="targetFlag === 'huBinDaiZhiBeiFuGaiDu'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
    />
    <KouMenChangTongLv
      v-if="targetFlag === 'kouMenChangTongLv'"
      :target-data="targetData"
      :scheme-id="schemeId"
      @changeFlag="changeFlag"
    />
    <!-- <el-dialog :title="targetName" :visible.sync="targetFlag" :width="10">
      <span slot="footer" class="dialog-footer">
        <el-button @click="targetFlag = false">取 消</el-button>
        <el-button type="primary" @click="targetFlag = false">确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
  import MyTitle from '@/components/title/index.vue'
  import Map from '@/components/map/mapBase'
  import FluvialFluidity from './riverTarget/fluvialFluidity.vue'
  import EcologicalWater from './riverTarget/ecologicalWater.vue'
  import Phytoplankton from './riverTarget/phytoplankton.vue'
  import BorneAlgae from './riverTarget/borneAlgae.vue'
  import WaterSupply from './riverTarget/waterSupply.vue'
  import Outlet from './riverTarget/outlet.vue'
  import SedimentPollution from './riverTarget/sedimentPollution.vue'
  import Benthic from './riverTarget/benthic.vue'
  import EcoBank from './riverTarget/ecologicalBank.vue'
  import WaterFunctionalArea from './riverTarget/waterFunctionalArea.vue'
  import ManagementScope from './riverTarget/managementScope.vue'
  import PreventFlood from './riverTarget/prevenFlood.vue'
  import WaterQuality from './riverTarget/waterQuality.vue'
  import WaterChange from './lakeTarget/waterChange.vue'
  import LakeEcologicalWater from './lakeTarget/ecologicalWater.vue'
  import EnterLake from './lakeTarget/enterLakeRiver.vue'
  import WaterSurfaceDevelopment from './lakeTarget/waterSurfaceDevelopment.vue'
  import CyanobacteriaDensity from './lakeTarget/cyanobacteriaDen.vue'
  import ManagementScopeLake from './lakeTarget/managementScope.vue'
  import Nutrition from './lakeTarget/nutrition.vue'
  import Questionnaire from './riverTarget/publicSatisfaction.vue'
  import Landscape from './riverTarget/landscape.vue'

  import AnPoWenDingXing from './riverTarget/anPoWenDingXing.vue'
  import AnPoHuanJing from './riverTarget/anPoHuanJingZhengZhi.vue'
  import ShuiTiZhengJi from './riverTarget/shuiTiZhengJi.vue'
  import AnPoZhiBeiFuGaiDu from './riverTarget/anPoZhiBeiFuGaiDu.vue'
  import AnXianKaiFaLiYongLv from './riverTarget/anXianKaiFaLiYongLv.vue'
  import HuBinDaiZhiBeiFuGaiDu from './lakeTarget/huBinDaiZhiBeiFuGaiDu.vue'
  import KouMenChangTongLv from './lakeTarget/kouMenChangTongLv.vue'

  import { getTargetFlag, targetList } from './targetFlag'

  import { Scheme, Site, Rlobject } from '@/api/scheme/index.js'
  import { Project } from '@/api/pro/index.js'

  export default {
    // 指标评估
    name: 'IndicatorEvaluate',
    components: {
      MyTitle,
      Map,
      FluvialFluidity,
      EcologicalWater,
      Phytoplankton,
      BorneAlgae,
      WaterSupply,
      Outlet,
      SedimentPollution,
      Benthic,
      EcoBank,
      WaterFunctionalArea,
      ManagementScope,
      PreventFlood,
      WaterQuality,
      WaterChange,
      LakeEcologicalWater,
      EnterLake,
      WaterSurfaceDevelopment,
      CyanobacteriaDensity,
      ManagementScopeLake,
      Nutrition,
      Questionnaire,
      Landscape,

      AnPoWenDingXing,
      AnPoHuanJing,
      ShuiTiZhengJi,
      AnPoZhiBeiFuGaiDu,
      AnXianKaiFaLiYongLv,
      HuBinDaiZhiBeiFuGaiDu,
      KouMenChangTongLv
    },
    props: {},
    data() {
      return {
        proInfo: {},
        proInfoList: [],
        // 对象信息列表，主要用于地图信息展示，选取单一方案时，只有当前对象信息；只选择项目时，存储当前所有scheme对象信息数据
        objList: [],
        schemeTableData: [],
        targetList: [],
        selectedTarget: {},
        targetName: '',
        targetFlag: '',
        targetData: {},
        schemeId: null,
        rlType: null
      }
    },
    computed: {},
    watch: {},
    created() {
      // this.getTargetList(2)
    },
    mounted() {
      this.loadPro()
    },
    methods: {
      /**
       * @description 加载项目列表数据
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      loadPro() {
        let param = {
          pageSize: 100
        }
        Project.getList(param).then((res) => {
          this.proInfoList = res.data.data.list
        })
      },
      /**
       * @description 选择项目之后获取项目下的方案列表
       * @author hu_ty
       * @since
       * @param {number} id 选择的项目id
       *
       */
      loadScheme(id) {
        this.targetList = []
        //清除弹框
        this.clearPopup()

        this.setCurrentScheme()
        let param = {
          pageSize: 100,
          proId: id
        }
        this.schemeTableData = []
        Scheme.getListByProId(param).then((res) => {
          this.schemeTableData = res.data.data.list
          this.initObjMap(this.schemeTableData)
        })
      },
      /**
       * @description 方案对象地理信息获取并在地图上面渲染
       * @author hu_ty
       * @since
       * @param {array} data 方案列表
       *
       */
      async initObjMap(data) {
        this.objList = []
        let promise = []
        data
          .filter((i) => i.objId)
          .forEach((item) => {
            promise.push(
              Rlobject.getInfo(item.objId).then((res) => {
                this.objList.push(res.data.data)
              })
            )
          })
        await Promise.all(promise)
        this.showObjArea(this.objList)
      },
      /**
       * @description  方案列表信息格式化
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      rltypeIcon(row) {
        return row.rlType === 1 ? '河流' : row.rlType === 2 ? '湖泊' : '水库'
      },
      cellStyle(data) {
        if (data.columnIndex == 1) {
          if (data.row.rlType == 1) {
            return 'color:rgba(65, 97, 235, 1)'
          } else if (data.row.rlType == 2) {
            return 'color:rgba(11, 219, 168, 1)'
          } else if (data.row.rlType == 3) {
            return 'color:rgba(255, 181, 64, 1);'
          }
        }
      },
      /**
       * @description  选择评估对象信息
       * @author hu_ty
       * @since 22-06-24
       * @param {*} val 选择的评估对象数据信息
       *
       */
      handleCurrentChange(val) {
        if (val && val != null) {
          this.currentRow = val
          this.targetList = []
          sessionStorage.setItem('currentObjId', val?.id)
          sessionStorage.setItem('currentObj', JSON.stringify(val))

          this.schemeId = val?.id
          this.rlType = val?.rlType

          //清除弹框
          this.clearPopup()

          // 获取指标信息列表
          this.getTargetList(this.rlType, sessionStorage.getItem('currentObjId'))
          // 获取方案站点信息列表
          this.getSiteList(sessionStorage.getItem('currentObjId'))
          // 获取方案对象信息数据
          this.getObjInfo(val?.objId)
        }
      },

      //清除弹框
      clearPopup() {
        this.$refs.mapIndicatorEva.map.getOverlays().clear()
        this.$refs.mapIndicatorEva.map.removeLayer(this.$refs.mapIndicatorEva.scorePointsLayer)
      },
      /**
       * @description 获取指标列表
       * @author hu_ty
       * @since
       * @param {number}val 方案对象id
       * @param {number}type 河湖对象类型
       *
       */
      getTargetList(type = 1, val) {
        const loading = this.$loading({
          lock: true,
          text: '数据加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        let id
        if (val && val != 'undefined') {
          id = val
        }
        Scheme.getTargetList(type, id)
          .then((res) => {
            res.data.data.forEach((i) => {
              if (
                i.targetEntity &&
                i.targetEntity.showPos &&
                i.targetEntity.detailList &&
                i.targetEntity.detailList.length > 0
              ) {
                i.showPos = true
              } else {
                i.showPos = false
              }
            })
            this.targetList = res.data.data
            // console.log(' this.targetList ', this.targetList)
            loading.close()
          })
          .catch((err) => {
            console.log(err)
            loading.close()
          })
      },
      /**
       * @description  获取对象站点信息
       * @author hu_ty
       * @since
       * @param {number} val 对象id
       *
       */
      getSiteList(val) {
        let id
        if (val && val != 'undefined') {
          id = val
        }
        let param = {
          schemeId: id,
          pageSize: 100
        }
        Site.getList(param).then((res) => {
          let siteInfo = res.data.data.list
          let arr = siteInfo.filter((site) => site.siteType === 'Normal')

          this.$refs.mapIndicatorEva.showMarkPoints(arr)
          sessionStorage.setItem('siteInfo', JSON.stringify(arr))
        })
      },
      /**
       * @description 获取对象信息（主要是空间地理信息）
       * @author hu_ty
       * @since
       * @param {number} id 对象id
       *
       */
      getObjInfo(id) {
        Rlobject.getInfo(id).then((res) => {
          this.objList = []
          this.objList.push(res.data.data)
          this.$refs.mapIndicatorEva.toNewView(res.data.data)
          this.showObjArea(this.objList)
        })
      },
      /**
       * @description  选择评估指标信息
       * @author hu_ty
       * @since 22-06-24
       * @param {*} val 选择的评估指标数据信息
       *
       */
      handleTargetChange(val) {
        this.selectedTarget = val
        if (val) {
          this.targetData = this.selectedTarget.targetEntity ? this.selectedTarget.targetEntity : {}
          this.targetFlag = getTargetFlag(this.selectedTarget.name, this.rlType)
        }
      },
      /**
       * @description  取消选择方案
       * @author hu_ty
       * @since 22-06-24
       * @param {*} val 选择的评估指标行
       *
       */
      setCurrentScheme() {
        this.$refs.schemeTable.setCurrentRow()
      },
      /**
       * @description  取消选择评估指标
       * @author hu_ty
       * @since 22-06-24
       * @param {*} val 选择的评估指标行
       *
       */
      setCurrent() {
        this.$refs.targetList.setCurrentRow()
      },
      /**
       * @description  关闭当前填报指标(提供子组件调用)
       * @author hu_ty
       * @since 22-06-24
       * @param {*} val 选择的评估指标行
       *
       */
      changeFlag() {
        this.targetFlag = ''
        this.setCurrent()
        this.getTargetList(this.rlType, sessionStorage.getItem('currentObjId'))
      },

      /**
       * @description 显示所有对象的面/单一对象面
       *
       * @param {Array} data 对象信息列表，包含地理空间信息数据
       *
       */
      showObjArea(data) {
        this.$refs.mapIndicatorEva.showMarkArea(data)
      },
      /**
       * 显示所有对象的监测点
       */
      showObjPSites() {
        //  this.$refs.mapCreatePro.showMarkPoints(siteList)
      },

      //展示移动端采样点 todo
      showScorePoint(data) {
        this.$refs.mapIndicatorEva.showScorePoints(data.name, data.targetEntity.detailList)
      }
    }
  }
</script>
<style lang="less" scoped>
  .indicator-page {
    width: 100%;
    height: 100%;
    position: relative;
    // display: flex;
    .left-pro-info {
      position: absolute;
      top: 34px;
      left: 20px;
      z-index: 11;
      width: 400px;
      height: calc(100% - 60px);
      overflow: hidden;
      background-color: #fff;
      // border: 0.5px solid #eee;
      .content-box {
        margin: 0 16px;
      }
    }
    #map {
      // flex: 1;
      width: 100%;
      height: 100%;
      background-color: #ecf5ff;
    }
    .right-target-table {
      position: absolute;
      top: 34px;
      right: 20px;
      z-index: 11;
      width: 400px;
      height: calc(100% - 60px);
      overflow: hidden;
      background-color: #fff;
      border: 1px solid #eee;
      .top-title-box {
        margin: 0 16px;
      }
    }
  }
  .title-box {
    margin: 8px 0;
    height: 40px;
    line-height: 24px;
    align-items: center;
    display: flex;
    padding: 0 16px;
    justify-content: space-between;
    background: rgba(64, 158, 255, 0.1);
    backdrop-filter: blur(33px);
    .title-button-box {
      span {
        margin-right: 16px;
      }
    }
  }

  .pro-container {
    position: relative;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 16px 16px 0;
    // .pro-title {
    //   color: rgba(0, 0, 0, 1);
    //   font-family: 'PingFang SC';
    //   font-weight: 500;
    //   font-size: 20px;
    // }
    .select {
      border: 0;
    }
    /deep/.el-input--suffix .el-input__inner {
      border: 0;
      font-weight: 700;
      font-size: 20px;
      width: 300px;
      color: rgb(0, 0, 0);
    }
    .angle {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .target-container {
    height: 32px;
    padding: 8px 0;
    color: rgba(0, 0, 0, 1);
    font-family: PingFang SC;
    font-weight: 700;
    font-size: 20px;
    position: relative;
    padding: 2px;
    margin: 16px 16px 0;

    .angle {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  /*滚动条bai整体*/
  /deep/ ::-webkit-scrollbar {
    width: 5px; /*滚动条宽度*/
  }
  /deep/ ::-webkit-scrollbar-thumb {
    background-color: #999; /*滑动条表面*/
    border: solid 1px #999; /*滑动条边框*/
    border-radius: 4px; /*滑动条圆角宽度*/
  }

  /deep/ .target-name {
    cursor: pointer;
  }
</style>
