<template>
  <div>
    <el-dialog
      title="底泥污染状况"
      :visible.sync="flag"
      width="516px"
      :before-close="close"
      :modal="false"
      :close-on-click-modal="false"
    >
      <div slot="title" class="dialog-header-title">
        <span class="title-name">
          底泥污染状况
          <i class="el-icon-question" @click="openTip('hpdnwrzk')"></i>
        </span>
        <span class="title-score">
          最终得分:
          <span v-if="finalScore">{{ finalScore.toFixed(1) }}</span>
          <span v-else>--</span>
        </span>
      </div>
      <Standrad
        :anchor="anchor"
        :show-flag="indicatorDefinition"
        @changFlag="() => (indicatorDefinition = !indicatorDefinition)"
      />
      <div class="pollution-box">
        <div class="title-box">
          <div class="name">
            <div class="point"></div>
            污染指数法
          </div>
          <div>
            综合得分: <span class="score">{{ wrzsScore }}</span> 分
          </div>
        </div>
        <el-row type="flex" align="middle">
          <el-col :span="8" class="row-lable-name"><div>数值:</div> </el-col>
          <el-col :span="10">
            <el-input v-model="wrzsValue" :style="{ width: '80%' }" type="number" @blur="count"></el-input>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="8" class="row-lable-name">计算分析说明:</el-col>
          <el-col :span="16">
            <el-input
              v-model="wrzsAnalysisDesc"
              type="textarea"
              placeholder="请输入计算说明(数据来源、得出此数据的简要说明)"
              :autosize="{ minRows: 2, maxRows: 2 }"
            ></el-input>
          </el-col>
        </el-row>
      </div>
      <div class="heavy-metal">
        <div class="title-box">
          <div class="name">
            <div class="point"></div>
            重金属生态风险指数法
          </div>
          <div>
            综合得分: <span class="score">{{ zjsScore && zjsScore.toFixed(1) }}</span> 分
          </div>
        </div>
        <el-row type="flex" align="middle">
          <el-col :span="8" class="row-lable-name"><div>数值:</div> </el-col>
          <el-col :span="10">
            <el-input v-model="zjsValue" :style="{ width: '80%' }" type="number" @blur="count"></el-input>&nbsp;
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="8" class="row-lable-name">计算分析说明:</el-col>
          <el-col :span="16">
            <el-input
              v-model="zjsAnalysisDesc"
              type="textarea"
              placeholder="请输入计算说明(数据来源、得出此数据的简要说明)"
              :autosize="{ minRows: 2, maxRows: 2 }"
            ></el-input>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="8" class="row-lable-name">附件:</el-col>
          <el-col :span="16">
            <MyUploadFile ref="uploadfile" :query-ext="queryExt" :attachs="attachs" />
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button class="btn-info" @click="close">取 消</el-button>
        <el-button class="btn-submit" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { SedimentPollution } from '@/api/target/riverTarget.js'
  export default {
    // 底泥污染状况
    name: 'SedimentPollution',
    components: {},
    props: {
      schemeId: {
        type: Number,
        default: 1
      },
      targetData: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        flag: true,

        wrzsScore: 0,
        wrzsValue: null,
        wrzsAnalysisDesc: '',

        zjsValue: null,
        zjsScore: 0,
        zjsAnalysisDesc: '',
        queryExt: {},
        attachs: '',
        id: null,

        indicatorDefinition: false,
        anchor: ''
      }
    },
    computed: {
      finalScore() {
        return this.wrzsScore - this.zjsScore > 0 ? this.zjsScore : this.wrzsScore
      }
    },
    watch: {
      targetData: {
        immediate: true,
        handler(val, oldval) {
          if (val != {}) {
            this.loadData(val)
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description 指标描述展示
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      openTip(val) {
        this.anchor = val
        this.indicatorDefinition = true
      },
      /**
       * @description 数据初始化加载
       * @author hu_ty
       * @since
       * @param {object} data 有数据的指标信息内容
       *
       */
      loadData(data) {
        // console.log('<== loadData ==>', data)
        this.wrzsAnalysisDesc = data.wrzsAnalysisDesc
        this.wrzsScore = data.wrzsScore
        this.wrzsValue = data.wrzsValue
        this.zjsAnalysisDesc = data.zjsAnalysisDesc
        this.zjsScore = data.zjsScore
        this.zjsValue = data.zjsValue
        this.attachs = data.attachs
        this.queryExt = data.queryExt
        this.id = data.id
      },
      /**
       * @description  关闭组件并改变父级 targetFlag 值
       * @author hu_ty
       * @since 22-06-24
       * @param {*}
       *
       */
      close() {
        this.$emit('changeFlag')
      },
      /**
       * @description 污染指数和重金属生态风险指数分值计算
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      count() {
        if (this.wrzsValue) {
          switch (true) {
            case this.wrzsValue < 0.5:
              this.wrzsScore = 100 - (this.wrzsValue / 0.5) * 10
              break
            case this.wrzsValue < 1:
              this.wrzsScore = 90 - ((this.wrzsValue - 0.5) / 0.5) * 10
              break
            case this.wrzsValue < 1.5:
              this.wrzsScore = 80 - ((this.wrzsValue - 1) / 0.5) * 20
              break
            case this.wrzsValue < 3:
              this.wrzsScore = 60 - ((this.wrzsValue - 1.5) / 1.5) * 20
              break
            case this.wrzsValue < 4.5:
              this.wrzsScore = 40 - ((this.wrzsValue - 3) / 1.5) * 40
              break
            case this.wrzsValue >= 4.5:
              this.wrzsScore = 0
              break
            default:
          }
        }
        if (this.zjsValue) {
          switch (true) {
            case this.zjsValue < 75:
              this.zjsScore = 100 - (this.zjsValue / 75) * 10
              break
            case this.zjsValue < 150:
              this.zjsScore = 90 - ((this.zjsValue - 75) / 75) * 10
              break
            case this.zjsValue < 300:
              this.zjsScore = 80 - ((this.zjsValue - 150) / 150) * 20
              break
            case this.zjsValue < 600:
              this.zjsScore = 60 - ((this.zjsValue - 300) / 300) * 20
              break
            case this.zjsValue < 900:
              this.zjsScore = 40 - ((this.zjsValue - 600) / 300) * 40
              break
            case this.zjsValue >= 900:
              this.zjsScore = 0
              break
            default:
          }
        }
      },
      submit() {
        this.attachs = this.$refs.uploadfile.getAttachs()

        let param = {
          schemeId: this.schemeId,
          wrzsValue: this.wrzsValue,
          wrzsAnalysisDesc: this.wrzsAnalysisDesc,
          zjsValue: this.zjsValue,
          zjsScore: 0,
          zjsAnalysisDesc: this.zjsAnalysisDesc,
          attachs: this.attachs,
          id: this.id
        }
        SedimentPollution.saveInfo(param).then((res) => {
          console.log('<==  ==>', res)
          this.close()
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .heavy-metal,
  .pollution-box {
    // margin: 0 48px;
    .title-box {
      display: flex;
      justify-content: space-between;
      background: linear-gradient(90deg, rgba(64, 158, 255, 0.1) 0%, rgba(64, 158, 255, 0) 91.85%);
      backdrop-filter: blur(33px);
      margin: 0;
      .name {
        font-family: 'PingFang SC';
        font-weight: 600;
        font-size: 16px;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        .point {
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background: radial-gradient(circle, rgba(64, 158, 255, 1) 30%, rgba(64, 158, 255, 0.5) 10%);
          margin-right: 6px;
        }
      }
      .score {
        text-align: right;
        color: rgba(64, 158, 255, 1);
        font-family: 'PingFang SC';
        font-weight: 500;
        font-size: 18px;
      }
      .title-score {
        color: rgba(0, 0, 0, 0.6);
        font-family: 'PingFang SC';
        font-size: 12px;
      }
    }
    .el-row {
      margin: 16px;
    }
  }
  .pollution-info {
    margin-bottom: 24px;
  }
</style>
