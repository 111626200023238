<template>
  <div>
    <el-dialog
      title="水面开发利用率"
      :visible.sync="flag"
      width="60%"
      :before-close="close"
      :modal="false"
      :close-on-click-modal="false"
      top="100px"
    >
      <div slot="title" class="dialog-header-title">
        <span class="title-name">
          水面开发利用率
          <i class="el-icon-question" @click="openTip('smkflyl')"></i>
        </span>
        <span class="title-score">
          最终得分:
          <span v-if="finalScore">{{ finalScore.toFixed(1) }}</span>
          <span v-else>--</span>
        </span>
      </div>
      <Standrad
        :anchor="anchor"
        :show-flag="indicatorDefinition"
        @changFlag="() => (indicatorDefinition = !indicatorDefinition)"
      />
      <el-table
        :data="detailList"
        style="width: 100%; font-size: 12px; text-align: center"
        header-cell-class-name="table-header-cls"
        height="400px"
      >
        <el-table-column prop="lonlat" label="现场地理位置" align="center" header-align="center">
          <template slot-scope="scope">
            {{ scope.row.lon + ',' + scope.row.lat }}
          </template>
        </el-table-column>

        <el-table-column prop="develop" label="开发利用类型" align="center" header-align="center" width="120">
          <template slot-scope="scope">
            <!-- {{ scope.row.develop }} -->
            <el-input v-model="scope.row.develop" type="text"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="develop" label="开发利用类型现场描述" align="center" header-align="center" width="180">
          <template slot-scope="scope">
            <!-- {{ scope.row.developDesc }} -->
            <el-input v-model="scope.row.developDesc" type="text"></el-input>
          </template>
        </el-table-column>

        <el-table-column prop="pics" label="现场图片" align="center" header-align="center" width="100">
          <template slot-scope="scope">
            <div v-if="scope.row.areaPngCodeList && scope.row.areaPngCodeList.length > 0">
              <el-image
                style="width: 90px; height: 90px"
                fit="scale-down"
                :src="scope.row.areaPngCodeList[0]"
                :preview-src-list="scope.row.areaPngCodeList"
              >
              </el-image>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="area" label="现场预估面积(㎡)" align="center" header-align="center" width="150">
          <template slot-scope="scope">
            <!-- {{ scope.row.area }} -->
            <el-input v-model="scope.row.area" type="text"></el-input>
          </template>
        </el-table-column>

        <el-table-column prop="develop" label="预估面积现场描述" align="center" header-align="center" width="150">
          <template slot-scope="scope">
            <!-- {{ scope.row.areaDesc }} -->
            <el-input v-model="scope.row.areaDesc" type="text"></el-input>
          </template>
        </el-table-column>

        <el-table-column prop="area" label="水面开发实际确认面积(㎡)" align="center" header-align="center">
          <template slot-scope="scope">
            <el-input v-model.number="scope.row.confirmArea" type="number" :style="{ width: '80%' }"></el-input>
          </template>
        </el-table-column>
      </el-table>

      <!-- <div class="add-box" @click="addNew" style="margin-top:20px">
        <span>
          <el-icon class="el-icon-plus" style="margin-right: 8px"></el-icon>添加
        </span>
      </div> -->

      <div class="file-container">
        <el-row type="flex" align="middle">
          <el-col :span="8" class="row-lable-name">正常蓄水面积:</el-col>
          <el-col :span="8">
            <el-input v-model.number="storageArea" type="number" :style="{ width: '40%' }"></el-input>&nbsp;m
            <sup>2</sup>
          </el-col>
        </el-row>

        <el-row type="flex" align="middle">
          <el-col :span="8" class="row-lable-name">计算分析说明:</el-col>
          <el-col :span="12">
            <el-input
              v-model="analysisDesc"
              type="textarea"
              placeholder="请输入计算说明(数据来源、得出此数据的简要说明)"
              :autosize="{ minRows: 2, maxRows: 2 }"
            ></el-input>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="8" class="row-lable-name">附件:</el-col>
          <el-col :span="16">
            <MyUploadFile ref="uploadfile" :query-ext="queryExt" :attachs="attachs" />
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button class="btn-info" @click="close">取 消</el-button>
        <el-button class="btn-submit" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { WaterSurfaceDev } from '@/api/target/lakeTarget.js'
  import { File } from '@/api/file/index.js'
  export default {
    // 水面开发利用率
    name: 'WaterSurfaceDevelopment',
    components: {},
    props: {
      schemeId: {
        type: Number,
        default: 1
      },
      targetData: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        flag: true,
        detailList: [],
        finalScore: null,

        deveArea: null,
        area: null,

        id: null,
        storageArea: null,
        analysisDesc: '',
        attachs: '',
        queryExt: {},

        indicatorDefinition: false,
        anchor: ''
      }
    },
    computed: {},
    watch: {
      targetData: {
        immediate: true,
        handler(val, oldval) {
          if (JSON.stringify(val) !== '{}') {
            this.loadData(val)
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description 指标描述展示
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      openTip(val) {
        this.anchor = val
        this.indicatorDefinition = true
      },
      /**
       * @description 数据初始化加载
       * @author hu_ty
       * @since
       * @param {object} data 有数据的指标信息内容
       *
       */
      loadData(data) {
        console.log('data123', data)
        this.finalScore = data.scoreVal
        this.id = data.id

        this.storageArea = data.storageArea
        this.analysisDesc = data.analysisDesc
        this.attachs = data.attachs
        this.queryExt = data.queryExt

        this.formmater(data.detailList)
      },

      // 获取图片列表
      async formmater(data) {
        this.detailList = []
        data.forEach(async (item) => {
          item.areaPngCodeList = []

          if (item.areaPngCode && !item.areaPngCode.includes('tmp')) {
            let arr = item.areaPngCode.split(',')
            let promise = []
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.areaPngCodeList.push(res.data.data)
              })
              promise.push(a)
            })
            await Promise.all(promise)
          }

          if (item.developPngCode && !item.developPngCode.includes('tmp')) {
            let arr = item.developPngCode.split(',')
            let promise = []
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.areaPngCodeList.push(res.data.data)
              })
              promise.push(a)
            })
            await Promise.all(promise)
          }

          this.detailList.push(item)
          console.log(' this.detailList', this.detailList)
        })
      },

      // addNew() {
      //   this.detailList.push({})
      //   // this.innerVisible = true
      // },

      close() {
        this.$emit('changeFlag')
      },
      countFinalScore() {},
      /**
       * @description 数据保存
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      submit() {
        this.attachs = this.$refs.uploadfile.getAttachs()

        let param = {
          id: this.id,
          schemeId: this.schemeId,
          attachs: this.attachs,
          storageArea: this.storageArea,
          analysisDesc: this.analysisDesc,
          detailList: this.detailList
        }

        console.log('param', param)
        WaterSurfaceDev.saveInfo(param).then((res) => {
          this.close()
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .file-container {
    margin-top: 16px;
  }

  .el-row {
    margin-bottom: 16px;
  }

  /deep/.el-dialog__body {
    max-height: 700px;
    overflow: auto;
  }
</style>
