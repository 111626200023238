<template>
  <div>
    <el-dialog
      title="水体整洁程度"
      :visible.sync="flag"
      width="40%"
      height="90%"
      :before-close="close"
      :modal="false"
      :close-on-click-modal="false"
    >
      <el-dialog
        v-if="innerVisible"
        width="80%"
        title="水体整洁程度"
        :visible.sync="innerVisible"
        append-to-body
        top="2%"
      >
        <IndicatorMap :type="'ShuiTiZhengJi'" @saveAddObj="saveAddObj" @cancelAddObj="cancelAddObj"></IndicatorMap>
      </el-dialog>

      <div slot="title" class="dialog-header-title">
        <span class="title-name">
          水体整洁程度
          <i class="el-icon-question" @click="openTip('stzjcd')"></i>
        </span>
        <span class="title-score">
          最终得分:
          <span v-if="finalScore">{{ finalScore.toFixed(1) }}</span>
          <span v-else>--</span>
        </span>
      </div>
      <Standrad
        :anchor="anchor"
        :show-flag="indicatorDefinition"
        @changFlag="() => (indicatorDefinition = !indicatorDefinition)"
      />

      <div v-for="(data, index) in detailList" v-cloak :key="index" style="margin-bottom: 50px">
        <div class="container">
          <!-- 嗅和味-->
          <div v-if="data.xiuHeWeiLevel" class="item-content">
            <el-row>
              <el-col class="row-lable-name" :span="8">嗅和味：</el-col>
              <el-col :span="10">
                <el-select ref="select" v-model="data.xiuHeWeiLevel">
                  <el-option v-for="item in xiuheweiOptions" :key="item.name" :label="item.name" :value="item.scoreStr">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>

            <el-row v-if="data.xiuHeWeiPngList && data.xiuHeWeiPngList.length > 0">
              <el-col :span="10" :offset="8">
                <el-image
                  style="width: 90px; height: 90px"
                  fit="scale-down"
                  :src="data.xiuHeWeiPngList[0]"
                  :preview-src-list="data.xiuHeWeiPngList"
                >
                </el-image>
              </el-col>
            </el-row>

            <el-row v-if="data.xiuHeWeiDesc">
              <el-col class="row-lable-name" :span="8">描述：</el-col>
              <el-col :span="10">
                <el-input v-model="data.xiuHeWeiDesc" type="textarea" :rows="2" disabled> </el-input>
              </el-col>
            </el-row>

            <el-row>
              <el-col class="row-lable-name" :span="8">扣分值：</el-col>
              <el-col :span="10">{{ getIndicatorScore(data.xiuHeWeiLevel) }}</el-col>
            </el-row>

            <el-row>
              <el-col class="row-lable-name" :span="8">参与此次评价：</el-col>
              <div>
                <el-radio v-model="data.xiuHeWeiJoinFlag" :label="true">是</el-radio>
                <el-radio v-model="data.xiuHeWeiJoinFlag" :label="false">否</el-radio>
              </div>
            </el-row>
          </div>
          <!-- 漂浮废弃物 -->
          <div v-if="data.piaoFuWuLevel" class="item-content">
            <el-row>
              <el-col class="row-lable-name" :span="8">漂浮废弃物：</el-col>
              <el-col :span="10">
                <el-select ref="select" v-model="data.piaoFuWuLevel">
                  <el-option v-for="item in piaofuwuOptions" :key="item.name" :label="item.name" :value="item.scoreStr">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>

            <el-row v-if="data.piaoFuWuPngList && data.piaoFuWuPngList.length > 0" class="png">
              <el-col :span="10" :offset="8">
                <el-image
                  style="width: 90px; height: 90px"
                  fit="scale-down"
                  :src="data.piaoFuWuPngList[0]"
                  :preview-src-list="data.piaoFuWuPngList"
                >
                </el-image>
              </el-col>
            </el-row>

            <el-row v-if="data.piaoFuWuDesc">
              <el-col class="row-lable-name" :span="8">描述：</el-col>
              <el-col :span="10">
                <el-input v-model="data.piaoFuWuDesc" type="textarea" :rows="2" disabled> </el-input>
              </el-col>
            </el-row>

            <el-row>
              <el-col class="row-lable-name" :span="8">扣分值：</el-col>
              <el-col :span="10">{{ getIndicatorScore(data.piaoFuWuLevel) }}</el-col>
            </el-row>

            <el-row>
              <el-col class="row-lable-name" :span="8">参与此次评价：</el-col>
              <div>
                <el-radio v-model="data.piaoFuWuJoinFlag" :label="true">是</el-radio>
                <el-radio v-model="data.piaoFuWuJoinFlag" :label="false">否</el-radio>
              </div>
            </el-row>
          </div>
          <!-- 透明度 -->
          <div v-if="data.touMingDuLevel" class="item-content">
            <el-row>
              <el-col class="row-lable-name" :span="8">透明度：</el-col>
              <el-col :span="10">
                <el-select ref="select" v-model="data.touMingDuLevel">
                  <el-option
                    v-for="item in toumingduOptions"
                    :key="item.name"
                    :label="item.name"
                    :value="item.scoreStr"
                  >
                  </el-option> </el-select
              ></el-col>
            </el-row>

            <el-row v-if="data.touMingDuPngList && data.touMingDuPngList.length > 0" class="png">
              <el-col :span="10" :offset="8">
                <el-image
                  style="width: 90px; height: 90px"
                  fit="scale-down"
                  :src="data.touMingDuPngList[0]"
                  :preview-src-list="data.touMingDuPngList"
                >
                </el-image>
              </el-col>
            </el-row>

            <el-row v-if="data.touMingDuDesc">
              <el-col class="row-lable-name" :span="8">描述：</el-col>
              <el-col :span="10">
                <el-input v-model="data.touMingDuDesc" type="textarea" :rows="2" disabled> </el-input>
              </el-col>
            </el-row>

            <el-row>
              <el-col class="row-lable-name" :span="8">扣分值：</el-col>
              <el-col :span="10">{{ getIndicatorScore(data.touMingDuLevel) }}</el-col>
            </el-row>

            <el-row>
              <el-col class="row-lable-name" :span="8">参与此次评价：</el-col>
              <div>
                <el-radio v-model="data.touMingDuJoinFlag" :label="true">是</el-radio>
                <el-radio v-model="data.touMingDuJoinFlag" :label="false">否</el-radio>
              </div>
            </el-row>
          </div>
        </div>
      </div>

      <el-row>
        <div class="add-box" @click="addNew">
          <span><el-icon class="el-icon-plus" style="margin-right: 8px"></el-icon>添加新数据</span>
        </div>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { ShuiTiZhengJieChengDu } from '@/api/target/riverTarget.js'
  import { File } from '@/api/file/index.js'
  import IndicatorMap from '../indicatorMap.vue'
  import { getIndicator } from '../indicatorScore'
  export default {
    // 水体整洁程度
    name: 'ShuiTiZhengJi',
    components: { IndicatorMap },
    props: {
      schemeId: {
        type: Number,
        default: 1
      },
      targetData: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        innerVisible: false,

        flag: true,
        detailList: [],
        finalScore: null,
        id: null,

        indicatorDefinition: false,
        anchor: '',
        toumingduOptions: [
          {
            name: '<0.5m',
            scoreStr: 'touMingDu_small',
            score: -1
          },
          {
            name: '0.15~0.3m',
            scoreStr: 'touMingDu_middle',
            score: -3
          },
          {
            name: '>0.3m',
            scoreStr: 'touMingDu_weight',
            score: -5
          }
        ],
        xiuheweiOptions: [
          {
            name: '轻度',
            scoreStr: 'xiuHeWei_small',
            score: -1
          },

          {
            name: '明显',
            scoreStr: 'xiuHeWei_middle',
            score: -3
          },

          {
            name: '强烈',
            scoreStr: 'xiuHeWei_weight',
            score: -5
          }
        ],
        piaofuwuOptions: [
          {
            name: '<10㎡',
            scoreStr: 'piaoFuWu_small',
            score: -1
          },
          {
            name: '10~50㎡',
            scoreStr: 'piaoFuWu_middle',
            score: -3
          },
          {
            name: '>50㎡',
            scoreStr: 'piaoFuWu_weight',
            score: -5
          }
        ]
      }
    },
    computed: {},
    watch: {
      targetData: {
        immediate: true,
        handler(val, oldval) {
          if (JSON.stringify(val) !== '{}') {
            this.loadData(val)
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      getIndicatorName(str) {
        const res = getIndicator(str)
        return res.name
      },
      getIndicatorScore(str) {
        const res = getIndicator(str)
        return res.score
      },
      /**
       * @description 指标描述展示
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      openTip(val) {
        this.anchor = val
        this.indicatorDefinition = true
      },
      // /数据初始化加载
      loadData(data) {
        console.log('<== loadData ==>', data)
        this.formmater(data.detailList)
        this.finalScore = data.scoreVal
        this.id = data.id
      },
      // 获取图片列表
      async formmater(data) {
        this.detailList = []
        data.forEach(async (item) => {
          // 嗅和味
          if (item.xiuHeWeiPng && !item.xiuHeWeiPng.includes('tmp')) {
            item.xiuHeWeiPngList = []
            let arr = item.xiuHeWeiPng.split(',')
            let promise = []
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.xiuHeWeiPngList.push(res.data.data)
              })
              promise.push(a)
            })
            await Promise.all(promise)
          }
          // 漂浮废弃物
          if (item.piaoFuWuPng && !item.piaoFuWuPng.includes('tmp')) {
            item.piaoFuWuPngList = []
            let arr = item.piaoFuWuPng.split(',')
            let promise = []
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.piaoFuWuPngList.push(res.data.data)
              })
              promise.push(a)
            })
            await Promise.all(promise)
          }
          // 透明度
          if (item.touMingDuPng && !item.touMingDuPng.includes('tmp')) {
            item.touMingDuPngList = []
            let arr = item.touMingDuPng.split(',')
            let promise = []
            arr.forEach((code) => {
              let a = File.findUrl(code).then((res) => {
                item.touMingDuPngList.push(res.data.data)
              })
              promise.push(a)
            })
            await Promise.all(promise)
          }

          this.detailList.push(item)
        })
      },
      //关闭组件并改变父级 targetFlag 值
      close() {
        this.$emit('changeFlag')
      },

      addNew() {
        this.innerVisible = true
      },
      // 保存监测点
      saveAddObj(data) {
        let param = {
          schemeId: this.schemeId,
          detailList: [data]
        }
        console.log('param', param)
        ShuiTiZhengJieChengDu.saveInfo(param).then((res) => {
          console.log('ShuiTiZhengJieChengDu', res)
          ShuiTiZhengJieChengDu.find(res.data.data).then((res) => {
            this.loadData(res.data.data)
          })
        })
        this.innerVisible = false
      },
      // 取消保存监测点
      cancelAddObj() {
        this.innerVisible = false
      },

      //数据保存
      submit() {
        let param = {
          id: this.id,
          detailList: this.detailList
        }
        console.log('param', param)
        ShuiTiZhengJieChengDu.saveInfo(param).then((res) => {
          this.close()
          this.$emit('clearPopup')
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-height: 500px;
    overflow-y: scroll;

    border-bottom: 1px solid rgba(186, 186, 186, 0.821);
  }

  .item-content {
    width: 80%;
    height: 200px;

    display: flex;

    flex-direction: column;
    justify-content: space-around;

    border-bottom: 0.5px solid rgba(186, 186, 186, 0.221);
    padding: 20px 0;
  }

  .row-lable-name {
    font-weight: 600;
    font-size: 14px;
  }
</style>
