<template>
  <div>
    <el-dialog
      title="蓝藻密度"
      :visible.sync="flag"
      width="468px"
      :before-close="close"
      :modal="false"
      :close-on-click-modal="false"
    >
      <div slot="title" class="dialog-header-title">
        <span class="title-name">
          蓝藻密度
          <i class="el-icon-question" @click="openTip('lzmd')"></i>
        </span>
        <span class="title-score">
          最终得分:
          <span v-if="finalScore">{{ finalScore.toFixed(1) }}</span>
          <span v-else>--</span>
        </span>
      </div>
      <Standrad
        ref="standrad"
        :anchor="achor"
        :show-flag="indicatorDefinition"
        @changFlag="() => (indicatorDefinition = !indicatorDefinition)"
      />
      <div class="body-content">
        <div v-for="(domain, index) in data" :key="index">
          <el-row>
            <div>
              <el-button v-if="index != 0 && !domain.id" type="text" @click="deleteRow(index, data)"
                >删除{{ domain.id }}</el-button
              >
            </div>
          </el-row>
          <el-row type="flex" align="middle">
            <el-col :span="8" class="row-lable-name"><div>采样时间:</div> </el-col>
            <el-col :span="4">
              <el-date-picker
                v-model="domain.makeDate"
                type="month"
                placeholder="选择日期"
                format="yyyy-MM"
                value-format="yyyy-MM"
              >
              </el-date-picker>
            </el-col>
          </el-row>
          <el-row type="flex" align="middle">
            <el-col :span="8" class="row-lable-name"><div>蓝藻个数:</div> </el-col>
            <el-col :span="10">
              <el-input v-model="domain.algaeNum" type="number" style="width: 60%"></el-input>&nbsp;万cell/L
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="8" class="row-lable-name">计算分析说明:</el-col>
            <el-col :span="16">
              <el-input
                v-model="domain.analysisDesc"
                type="textarea"
                placeholder="请输入计算说明(数据来源、得出此数据的简要说明)"
                :autosize="{ minRows: 2, maxRows: 2 }"
              ></el-input>
            </el-col>
          </el-row>
        </div>
        <el-row>
          <div class="add-box" @click="addNew">
            <span><el-icon class="el-icon-plus" style="margin-right: 8px"></el-icon>添加</span>
          </div>
        </el-row>
        <el-row type="flex">
          <el-col :span="8" class="row-lable-name">附件:</el-col>
          <el-col :span="16">
            <MyUploadFile ref="uploadfile" :query-ext="queryExt" :attachs="attachs" />
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button class="btn-info" @click="close">取 消</el-button>
        <el-button class="btn-submit" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { Cyanobacteria } from '@/api/target/lakeTarget.js'

  export default {
    // 蓝藻密度
    name: 'CyanobacteriaDensity',
    components: {},
    props: {
      schemeId: {
        type: Number,
        default: 1
      },
      targetData: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        flag: true,

        data: [{ algaeNum: null, analysisDesc: '', makeDate: null }],

        rating: '',
        finalScore: 0,

        id: null,
        attachs: '',
        queryExt: {},

        indicatorDefinition: false,
        achor: ''
      }
    },
    computed: {},
    watch: {
      targetData: {
        immediate: true,
        handler(val, oldval) {
          if (JSON.stringify(val) !== '{}') {
            this.loadData(val)
          }
        }
      }
    },
    created() {},
    mounted() {},
    methods: {
      /**
       * @description 打开指标描述
       * @author hu_ty
       * @since
       * @param {*}
       * @returns {*}
       */
      openTip(val) {
        this.achor = val
        this.indicatorDefinition = true
        // this.$refs.standrad.goAchor(val)
      },
      /**
       * @description 数据初始化加载
       * @author hu_ty
       * @since
       * @param {object} data 有数据的指标信息内容
       *
       */
      loadData(data) {
        this.data = data.detailList
        this.finalScore = data.scoreVal
        this.attachs = data.attachs
        this.queryExt = data.queryExt
        this.id = data.id
      },
      /**
       * @description  新增计算过程数据
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      addNew() {
        let newData = {
          algaeNum: null,
          analysisDesc: '',
          makeDate: '',
          key: new Date()
        }
        this.data.push(newData)
      },
      /**
       * @description  删除数据
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      deleteRow(index, data) {
        data.splice(index, 1)
      },
      /**
       * @description
       * @author hu_ty
       * @since 22-07-01
       * @param {*}
       *
       */
      count() {
        let score
        if (this.algaeNum) {
          let per = this.algaeNum
          switch (true) {
            case per <= 300:
              score = 100 - (per / 300) * 10
              break
            case per <= 1700:
              score = 90 - ((per - 300) / 1400) * 10
              break
            case per <= 3500:
              score = 80 - ((per - 1700) / 1800) * 20
              break
            case per <= 5000:
              score = 60 - ((per - 3500) / 2500) * 20
              break
            case per > 5000:
              score = 40 - ((per - 5000) / 5000) * 40 > 0 ? 40 - ((per - 5000) / 5000) * 40 : 0
              break
            default:
          }
          this.finalScore = score
        }
      },
      /**
       * @description  关闭组件并改变父级 targetFlag 值
       * @author hu_ty
       * @since 22-06-24
       * @param {*}
       *
       */
      close() {
        this.$emit('changeFlag')
      },
      /**
       * @description 数据保存
       * @author hu_ty
       * @since
       * @param {*}
       *
       */
      submit() {
        this.attachs = this.$refs.uploadfile.getAttachs()

        let param = {
          id: this.id,
          schemeId: this.schemeId,
          attachs: this.attachs,
          detailList: this.data,
          scoreVal: this.finalScore
        }
        Cyanobacteria.saveInfo(param).then((res) => {
          this.close()
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .body-content {
    // margin: 0 48px;
    .el-row {
      margin-bottom: 16px;
    }
  }
</style>
